import { ReactNode } from 'react'
import { Autocomplete } from '@material-ui/lab'
import { ExpandMoreRounded } from '@material-ui/icons'
import { UseFormReturn, Controller } from 'react-hook-form'
import { Grid, TextField, Typography } from '@material-ui/core'
import { Entity } from 'types'
import { withPlanner, WithPlannerProps } from '../hoc'

type Props = {
  form: UseFormReturn<any>
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  required?: boolean
  processed?: boolean
  optionIcon?: ReactNode
  noOptionsText?: string
  defaultOptions: Entity[]
  onchange?: any
}

export const AutocompleteSelect: React.FC<Props & WithPlannerProps> = withPlanner((props) => {
  const { form, name, label, placeholder, defaultOptions, onchange = () => {}, optionIcon, disabled } = props

  const { control } = form

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          disabled={disabled}
          disableListWrap
          disabledItemsFocusable
          noOptionsText="Нет доступных вариантов"
          loadingText="Загрузка..."
          popupIcon={<ExpandMoreRounded />}
          getOptionLabel={(option) => option.title}
          id={`${name}-id`}
          onChange={(_event, value) => {
            field.onChange(value)
            onchange(value)
          }}
          getOptionSelected={(option, value) => option.id === value.id}
          filterOptions={(options, _state) => {
            const newOptions: Entity[] = []
            options.forEach((element) => {
              if (element.title.toLowerCase().includes(_state.inputValue.toLowerCase()) || _state.inputValue === '')
                newOptions.push(element)
            })
            return newOptions
          }}
          options={defaultOptions}
          renderOption={({ title, key }: Entity) => (
            <Grid container wrap="nowrap" alignItems="center" key={`${title}_${key}`}>
              {optionIcon && (
                <Grid item style={{ fontSize: 18 }}>
                  {optionIcon}
                </Grid>
              )}

              <Grid item>
                <Typography variant="body1" style={{ color: 'inherit', lineHeight: 1.2, paddingLeft: '0.6em' }}>
                  {title}
                </Typography>
              </Grid>
            </Grid>
          )}
          renderInput={(params) => (
            <TextField
              color="primary"
              variant="filled"
              placeholder={placeholder}
              label={label}
              error={!!error}
              helperText={error?.message}
              name={name}
              type="text"
              inputRef={ref}
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      )}
    />
  )
})
