import { ButtonProps, GridSize } from '@material-ui/core'

// #region Interfaces
export interface Font {
  primary: string
  secondary?: string
  other?: string
}

export enum ApiAnswerStatus {
  ERROR = -1,
  BREAK = 0,
  SUCCESS = 1,
  UNAUTHENTICATED = -401,
  NEED_FULL_REGISTER = -402,
}

export interface ApiAnswer<T> {
  status: ApiAnswerStatus
  message: Message
  meta?: PaginationMeta
  data?: T
}
export interface PaginationMeta {
  current_page: number
  from: number
  last_page: number
  per_page:number
  to:number
  total:number
}


export interface FormProps<T> {
  data: T
  processed: boolean
  snackbar?: SnackbarProps
}

export interface KladrType {
  data: any
  unrestricted_value: string
  value: string
}

export interface FiasSuggestions {
  suggestions: KladrType[]
}
// #endregion

// #region Types
export type messageVariants = 'alert' | 'success' | 'warning' | 'error' | 'info' | 'reset'
export type Message = {
  [variant in messageVariants]?: string[]
}

export type AlertAction = {
  title: string
  onAction?(): void
  breakpoints?: {
    xs?: boolean | GridSize | undefined
    sm?: boolean | GridSize | undefined
    md?: boolean | GridSize | undefined
  }
} & ButtonProps

export type AlertProps = {
  message?: Message
  rootModal?: string
  onClose?(): void
  closeButton?: boolean
  title?: string
  fullScreen?: boolean
  actions?: AlertAction[]
}

export type Doc = {
  [key: string]: {
    id: number
    key: string
    middle_primary_src: string | null
    primary_is_image: boolean
    primary_src: string | null
    small_primary_src: string | null
    thumb_primary_src: string | null
    title: string
  }
}

// export type AlertProps = {
//   name: { [name: string]: AlertStore }
// }

export type SnackbarProps = {
  message?: Message
  onClose?(): void
}

export type InputType =
  | 'button'
  | 'checkbox'
  | 'color'
  | 'date'
  | 'datetime-local'
  | 'email'
  | 'file'
  | 'hidden'
  | 'image'
  | 'month'
  | 'number'
  | 'password'
  | 'radio'
  | 'range'
  | 'reset'
  | 'search'
  | 'submit'
  | 'tel'
  | 'text'
  | 'time'
  | 'url'
  | 'week'
// #endregion
