import { ChangeEvent } from 'react'
import {
  SelectProps as SelectPropsType,
  TextField,
  InputLabelProps as InputLabelPropsType,
  OutlinedInputProps,
  FilledInputProps,
  InputBaseComponentProps,
  TextFieldProps,
} from '@material-ui/core'
import { InputType } from 'types'

type Props = {
  id?: string
  register: any
  helperText: any
  label: string
  placeholder?: string
  // type?: InputType
  variant?: 'standard' | 'filled' | 'outlined'
  color?: 'primary' | 'secondary'
  fullWidth?: boolean
  InputProps?: OutlinedInputProps | FilledInputProps
  SelectProps?: SelectPropsType
  InputLabelProps?: InputLabelPropsType
  disabled?: boolean
  select?: boolean
  inputProps?: InputBaseComponentProps
  onChange?(e: ChangeEvent<any>): void
}

export const GenericTextInput: React.FC<Props & TextFieldProps> = (props) => {
  const {
    children,
    type = 'text',
    disabled = false,
    InputLabelProps,
    register,
    fullWidth = true,
    variant = 'filled',
    color = 'primary',
    select = false,
  } = props

  return (
    <TextField
      {...register}
      InputLabelProps={{ shrink: true, ...InputLabelProps }}
      fullWidth={fullWidth}
      disabled={disabled}
      variant={variant}
      select={select}
      color={color}
      type={type}
      {...props}
    >
      {children}
    </TextField>
  )
}
