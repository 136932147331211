import moment from 'moment'
import { KeyboardDatePicker } from '@material-ui/pickers'
import { DateRange } from '@material-ui/icons'
import { Controller, UseFormReturn } from 'react-hook-form'

type Props = {
  form: UseFormReturn<any>
  name: string
  disabled?: boolean
  placeholder?: string
  label?: string
  min?: Date
  max?: Date
}

export const DateInput: React.FC<Props> = ({
  form,
  name,
  disabled = false,
  label = 'Дата регистрации',
  placeholder = `Пример: ${moment().format('DD.MM.YY')}`,
  min,
  max = new Date(),
}) => {
  const { control, formState, setError } = form
  const { errors } = formState

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value = null, onBlur, ref } }) => (
        <KeyboardDatePicker
          autoOk
          inputRef={ref}
          value={value}
          onChange={(v) => onChange(moment(v).format('YYYY-MM-DD'))}
          disabled={disabled}
          disableFuture
          label={label}
          orientation="portrait"
          animateYearScrolling
          placeholder={placeholder}
          inputVariant="filled"
          onBlur={onBlur}
          fullWidth
          maskChar=" "
          InputLabelProps={{ shrink: true }}
          // onError={(err) => {
          //   const msg = errors[name]?.message
          //   if (err && err.toString() !== msg) {
          //     setError(name, { message: err.toString() })
          //   }
          // }}
          keyboardIcon={<DateRange style={{ padding: 0, width: '0.8em' }} />}
          minDate={min}
          maxDate={max}
          format="DD.MM.YY"
          cancelLabel="Отмена"
          okLabel="Готово"
          invalidDateMessage="Введена не корректная дата"
          minDateMessage="Дата вне допустимого интервала"
          maxDateMessage="Дата вне допустимого интервала"
          helperText={errors[name]?.message}
        />
      )}
    />
  )
}
