/* eslint-disable no-bitwise */

import { Area } from 'react-easy-crop/types'

const NOT_JPEG = -2
const NOT_DEFINED = -1

export type imageOrientation = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8
type CallbackType = (orientation: imageOrientation | typeof NOT_DEFINED | typeof NOT_JPEG) => void

export const image = {
  getOrientation: (file: Blob, callback: CallbackType): void => {
    const reader = new FileReader()

    reader.onload = (): void => {
      const view = new DataView(reader.result as ArrayBuffer)

      if (view.getUint16(0, false) !== 0xffd8) {
        callback(NOT_JPEG)
      }

      const length = view.byteLength
      let offset = 2

      while (offset < length) {
        if (view.getUint16(offset + 2, false) <= 8) callback(-1)

        const marker = view.getUint16(offset, false)
        offset += 2

        if (marker === 0xffe1) {
          const checkExifAsciiString = view.getUint32((offset += 2), false) !== 0x45786966

          if (checkExifAsciiString) {
            callback(NOT_DEFINED)
          }

          const little = view.getUint16((offset += 6), false) === 0x4949
          offset += view.getUint32(offset + 4, little)
          const tags = view.getUint16(offset, little)
          offset += 2

          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + i * 12, little) === 0x0112) {
              callback(view.getUint16(offset + i * 12 + 8, little) as imageOrientation)
            }
          }
        } else if ((marker & 0xff00) !== 0xff00) {
          break
        } else {
          offset += view.getUint16(offset, false)
        }
      }

      callback(-1)
    }

    reader.readAsArrayBuffer(file)
  },

  setOrientation: (
    img: HTMLImageElement,
    orientation?: imageOrientation
  ): { canvas: HTMLCanvasElement; ctx: CanvasRenderingContext2D | null } => {
    const canvas = document.createElement('canvas')
    canvas.width = img.width
    canvas.height = img.height

    const ctx = canvas.getContext('2d')
    ctx?.save()
    const { width } = canvas
    const styleWidth = canvas.style.width
    const { height } = canvas
    const styleHeight = canvas.style.height
    if (orientation) {
      if (orientation > 4) {
        canvas.width = height
        canvas.style.width = styleHeight
        canvas.height = width
        canvas.style.height = styleWidth
      }
      switch (orientation) {
        case 2:
          ctx?.translate(width, 0)
          ctx?.scale(-1, 1)
          break
        case 3:
          ctx?.translate(width, height)
          ctx?.rotate(Math.PI)
          break
        case 4:
          ctx?.translate(0, height)
          ctx?.scale(1, -1)
          break
        case 5:
          ctx?.rotate(0.5 * Math.PI)
          ctx?.scale(1, -1)
          break
        case 6:
          ctx?.rotate(0.5 * Math.PI)
          ctx?.translate(0, -height)
          break
        case 7:
          ctx?.rotate(0.5 * Math.PI)
          ctx?.translate(width, -height)
          ctx?.scale(-1, 1)
          break
        case 8:
          ctx?.rotate(-0.5 * Math.PI)
          ctx?.translate(-width, 0)
          break
        default:
      }
    }
    ctx?.drawImage(img, 0, 0, canvas.width, canvas.height)
    ctx?.restore()

    return { canvas, ctx }
  },

  createImage: (url: string): Promise<HTMLImageElement> => {
    return new Promise((resolve, reject) => {
      const img = new Image()
      img.addEventListener('load', () => resolve(img))
      img.addEventListener('error', (error) => reject(error))
      img.src = url
    })
  },

  getRadianAngle: (degreeValue: number): number => (degreeValue * Math.PI) / 180,

  rotateSize: (width: number, height: number, rotation: number): { width: number; height: number } => {
    const rotRad = image.getRadianAngle(rotation)
    return {
      width: Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
      height: Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
    }
  },

  getCroppedImg: async (
    src: string,
    pixelCrop: Area,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ): Promise<Blob | null> => {
    const img = await image.createImage(src)
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')

    if (!ctx) return null

    const rotRad = image.getRadianAngle(rotation)

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = image.rotateSize(img.width, img.height, rotation)

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth
    canvas.height = bBoxHeight

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
    ctx.rotate(rotRad)
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
    ctx.translate(-img.width / 2, -img.height / 2)

    // draw rotated image
    ctx.drawImage(img, 0, 0)

    // croppedAreaPixels values are bounding box relative
    // extract the cropped image using these values
    const data = ctx.getImageData(pixelCrop.x, pixelCrop.y, pixelCrop.width, pixelCrop.height)

    // set canvas width to final desired crop size - this will clear existing context
    canvas.width = pixelCrop.width
    canvas.height = pixelCrop.height

    // paste generated rotate image at the top left corner
    ctx.putImageData(data, 0, 0)

    // As Base64 string
    // return canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve) => {
      canvas.toBlob((file) => {
        resolve(file)
      }, 'image/jpeg')
    })
  },
}
