import useReactRouter from 'use-react-router'
import { useEffect } from 'react'
import { Modal } from 'components'
import TrackEvent from 'components/GAnalitics'
import { useReduxSelector, useReduxDispatch } from 'hooks'
import { propsAction } from 'store/props'
import SignupForm from './signup-form'

export const signUpModalName = 'SignupModal'

export const SignUpModal: React.FC = () => {
  const { history } = useReactRouter()
  const dispatch = useReduxDispatch()
  const { modal } = useReduxSelector((state) => state.props)
  const { isAuth } = useReduxSelector((state) => state.auth)
  const { show = false } = modal[signUpModalName] || {}

  const handleClose = () => {
    dispatch(propsAction.modal({ modal: { [signUpModalName]: { show: false } } }))
  }

  useEffect(() => {
    if (show && isAuth) history.push('/profile')
  }, [show, isAuth])

  return (
    <Modal
      open={show}
      maxWidth="sm"
      title="Регистрация"
      name={signUpModalName}
      onCloseDialog={() => {
        history.push({ search: '' })
        handleClose()
      }}
      onOpen={() => TrackEvent({ eventCategory: 'Registration', eventAction: 'open_r_form' })}
    >
      <SignupForm rootModal={signUpModalName} />
    </Modal>
  )
}
