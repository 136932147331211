import { useEffect, useState } from 'react'
import { Box, CircularProgress, Collapse, Grid, Fade, makeStyles, Theme } from '@material-ui/core'
import { SubmitButton, Snackbar, Modal } from 'components'
import { validation, formLocale, TextInput, FileInput } from 'components/form-control'
import { propsAction } from 'store/props'
import { useReduxDispatch, useReduxSelector } from 'hooks'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { withPlanner, WithPlannerProps } from 'components/hoc'
import { promoUploadVideo } from 'api/promo'
import { dataURLtoFile } from 'utils'
import { ApiAnswerStatus, FormProps, UploadVideoForm } from 'types'
import { generateVideoThumbnails } from '@rajesh896/video-thumbnails-generator'

const useStyles = makeStyles(({ spacing, palette }: Theme) => ({
  thumb: {
    padding: spacing(1 / 2),
    cursor: 'pointer',
    boxSizing: 'border-box',
    border: `2px solid #fff`,
    '& img': { display: 'block' },
    backgroundColor: palette.background.paper,
    '&:hover': { borderColor: palette.primary.main },
    '&.active': {
      borderColor: palette.primary.main,
    },
  },
}))

export const uploadVideoModalName = 'UploadVideoModal'

export const UploadVideoModal: React.FC<WithPlannerProps> = withPlanner(({ planner }) => {
  const classes = useStyles()
  const dispatch = useReduxDispatch()
  const { modal } = useReduxSelector((state) => state.props)
  const { show = false, params } = modal[uploadVideoModalName] || {}
  const { fetchPromoUser } = params || {}

  const handleClose = () => dispatch(propsAction.modal({ modal: { [uploadVideoModalName]: { show: false } } }))

  const acceptType = ['video/*']
  // const formats = acceptType.join(', ').replace(/\w+?\//g, '')

  const schema = validation({
    pet_name: yup
      .string()
      .required()
      .min(2)
      .max(40)
      .matches(/^[а-яёА-ЯЁa-zA-Z\s-]*$/i, 'Только буквенные символы'),
    // history: yup
    //     .string()
    //     .required()
    //     .min(300)
    //     .max(1000),
    video: yup
      .mixed()
      .test('required', 'Выберите видео с питомцем', (val) => typeof val?.[0] !== 'undefined')
      .test(
        'fileSize',
        `Размер файла превышает ${Math.floor(100000000 / 1000000)} Мб`,
        (val) => val?.[0]?.size <= 100000000
      ),
    // .test('accept', `Выберите файл в формате ${formats}`, (val) => acceptType.includes(val?.[0]?.type)),
    poster: yup.mixed(),
  })

  const thumbNumber = 4
  const [video, setVideo] = useState<File>()
  const [loading, setLoading] = useState(false)
  const [loadedImages, setLoadedImages] = useState(0)
  const [thumbnails, setThumbnails] = useState<string[]>([])

  // const [tm, setTm] = useState('')

  const [form, setForm] = useState<FormProps<UploadVideoForm>>({
    data: {},
    processed: false,
    snackbar: {
      onClose: () => setForm({ ...form, snackbar: { ...form.snackbar, message: undefined } }),
    },
  })

  const hookForm = useForm<any>({ resolver: yupResolver(schema) })
  const { reset, setValue, getValues, setError } = hookForm

  const resetModal = () => {
    reset()
    setLoading(false)
    setVideo(undefined)
    setLoadedImages(0)
    setThumbnails([])
    setForm({
      data: {},
      processed: false,
      snackbar: {
        onClose: () => setForm({ ...form, snackbar: { ...form.snackbar, message: undefined } }),
      },
    })
  }

  const onSubmit = async (props: any) => {
    setForm({ ...form, processed: true })
    const { status, message } = await promoUploadVideo({ ...props, video })
    setForm({ ...form, processed: false })
    resetModal()
    if (status === ApiAnswerStatus.SUCCESS) fetchPromoUser()
    dispatch(propsAction.alert({ title: 'Добавить видео', message, rootModal: uploadVideoModalName }))
  }

  const submitFailed = () => {
    setForm({ ...form, processed: false, snackbar: { ...form.snackbar, message: formLocale.notValid } })
  }

  const onSelectFile = ({ target }: any) => {
    if (target?.files && target?.files?.length) {
      // console.log('select file', target.files[0])
      setLoading(true)
      if (target.files[0].size > 100000000) {
        setLoading(false)
        setError('video', { message: `Размер файла превышает ${Math.floor(100000000 / 1000000)} Мб` })
        return
      }

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        const res = reader?.result?.toString() ?? ''
        const f = dataURLtoFile(
          res,
          `contest_video.${target.files[0]?.name?.split('.')?.pop()?.toLowerCase() ?? 'mp4'}`
        )
        // console.log('dataURLtoFile res', f)
        setVideo(f as File)
      })
      reader.readAsDataURL(target.files[0])
    }
  }

  useEffect(() => {
    if (video) {
      if (video.type !== 'video/quicktime') {
        try {
          generateVideoThumbnails(video, thumbNumber - 1, 'image/jpeg')
            .then((thumbs) => {
              setLoading(false)
              setThumbnails(thumbs)
            })
            .catch(() => {
              setLoading(false)
              throw new Error('Ошибка генерации постеров видео')
            })
        } catch (e: any) {
          setLoading(false)
        }
      } else setLoading(false)
    }
  }, [video])

  useEffect(() => {
    if (!show) {
      resetModal()
    }
  }, [show])

  const setPoster = async (item: string) => {
    const f = dataURLtoFile(item, 'poster.jpeg')
    setValue('poster', f)
  }

  return (
    <Modal open={show} maxWidth="sm" title="Добавить видео" name={uploadVideoModalName} onCloseDialog={handleClose}>
      <Box mx="auto" maxWidth={{ xs: 'initial', sm: 390 }} pt={4} position="relative">
        <form onSubmit={hookForm.handleSubmit(onSubmit, submitFailed)} autoComplete="off">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextInput label="Кличка" placeholder="Пример: Лаки" name="pet_name" form={hookForm} />
            </Grid>

            {/*<Grid item xs={12}>*/}
            {/*  <TextInput label="Ваша история" multiline placeholder="Введите текст" name="history" form={hookForm} />*/}
            {/*</Grid>*/}

            <Grid item xs={12}>
              <FileInput
                name="video"
                label="Видео с питомцем"
                form={hookForm}
                acceptType={acceptType}
                onChange={onSelectFile}
                onClear={() => {
                  setLoading(false)
                  setVideo(undefined)
                  setLoadedImages(0)
                  setThumbnails([])
                }}
              />
            </Grid>
          </Grid>

          <Box my={2}>
            <Collapse in={thumbnails.length > 0 && !loading && loadedImages === thumbnails.length} timeout={500} appear>
              <Box>
                <Fade in={thumbnails.length > 0 && !loading && loadedImages === thumbnails.length} appear>
                  <Box
                    mb={2}
                    onClick={({ currentTarget }) => {
                      currentTarget.querySelectorAll('.active').forEach((i) => i.classList.remove('active'))
                    }}
                  >
                    <Box textAlign="left" mb={1} mt={-3} fontWeight={400}>
                      Выберите постер для видео
                    </Box>
                    <Grid container spacing={2}>
                      {thumbnails.map((item, idx) => {
                        if (idx === 1 && !getValues('poster')) {
                          setPoster(item)
                        }

                        return (
                          <Grid key={`${idx}_thumb_upload`} item xs={6}>
                            <Box
                              className={classes.thumb}
                              onClick={({ currentTarget }) => {
                                setPoster(item)
                                setTimeout(() => currentTarget.classList.add('active'), 10)
                              }}
                            >
                              <img
                                onLoad={() => setLoadedImages((p) => p + 1)}
                                draggable={false}
                                src={item}
                                loading="lazy"
                                width="100%"
                                height="auto"
                                alt=""
                              />
                            </Box>
                          </Grid>
                        )
                      })}
                    </Grid>
                  </Box>
                </Fade>
              </Box>
            </Collapse>

            {loading && thumbnails.length === 0 && <CircularProgress />}
          </Box>

          {/* {tm} */}

          <Snackbar {...form.snackbar} />

          <Box width="100%" maxWidth={260} mt={4} mx="auto">
            <SubmitButton
              fullWidth
              color="secondary"
              variant="contained"
              title="Добавить"
              disabled={form.processed || loading}
              processed={form.processed || loading}
            />
          </Box>
        </form>

        {form.processed && <Box position="absolute" style={{ inset: 0 }} bgcolor="rgba(255,255,255,0.3)" />}
      </Box>
    </Modal>
  )
})
