import { Box, Grid, Button, makeStyles, Theme } from '@material-ui/core'
import { useReduxSelector, useReduxDispatch } from 'hooks'
import { propsAction } from 'store/props'
import { Modal } from 'components'
import { str } from 'utils'

const useStyles = makeStyles(({ palette, spacing }: Theme) => ({
  info: {
    height: '100%',
    fontSize: 16,
    fontWeight: 400,
    '& b': { fontWeight: 500 },
    '& ul': {
      paddingLeft: 20,
      '& li': {
        fontSize: 16,
        lineHeight: 1.15,
        marginBottom: spacing(1),
        '&::marker': { color: palette.primary.main },
      },
    },
    '& p': {
      fontSize: 12,
      fontWeight: 200,
    },
  },
}))

export const receiptRulesModalName = 'receiptRulesModal'

export const ReceiptRulesModal: React.FC = () => {
  const classes = useStyles()
  const dispatch = useReduxDispatch()
  const { modal } = useReduxSelector((state) => state.props)
  const { show = false } = modal[receiptRulesModalName] || {}

  const handleClose = () => dispatch(propsAction.modal({ modal: { [receiptRulesModalName]: { show: false } } }))

  return (
    <Modal open={show} title="Требования к чеку" onCloseDialog={handleClose}>
      <Grid container justifyContent="center" alignItems="center">
        <Grid item xs={12} sm={6}>
          <Box mx="auto" width={{ xs: '60%', sm: '100%' }}>
            <img src="/imgs/receipt.png" height="auto" width="100%" alt="" />
          </Box>
        </Grid>

        <Grid item xs={12} sm={6}>
          <Grid container justifyContent="space-around" alignItems="center" className={classes.info}>
            <Grid item xs={12}>
              <Box textAlign="left" pt={{ xs: 0, sm: 4 }} color="secondary">
                {str.normalize(`
									<b>На чеке должно быть:</b>
									<ul>
									<li>Наименование магазина</li>
									<li>Номер чека</li>
									<li>Дата и время покупки</li>
									<li>Наличие не менее одного акционного продукта</li>
									<li>Итоговая сумма покупки</li>
									<li>QR-код, а в его отсутствие - номера ФН, ФД, ФП/ФПД</li>
									</ul>
									`)}
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Box textAlign="left" component="p">
                {str.normalize('*При отсутствии в чеке любого из перечисленных пунктов чек будет отклонен')}
              </Box>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Box mx="auto" width="100%" mt={4} maxWidth={{ xs: 230, sm: 200 }}>
            <Button onClick={handleClose} fullWidth variant="contained" color="secondary">
              Готово
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Modal>
  )
}
