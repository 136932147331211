import { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form'
import useReactRouter from 'use-react-router'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Box, Button, Grid } from '@material-ui/core'
import { parse } from 'query-string'
import { useDispatch } from 'react-redux'
import { signin } from 'api'
import TrackEvent from 'components/GAnalitics'
import { Snackbar, SocialAuth } from 'components'
import { PhoneInput, PasswordInput, formLocale, validation } from 'components/form-control'
import { ApiAnswerStatus, FormProps } from 'types'
import { authAction } from 'store/auth'
import { propsAction } from 'store/props'

type SignInProps = { username: string; password: string }

const schema = validation({
  username: yup
    .string()
    .matches(/^\d{10}$/i, { message: 'Указан некорректный номер телефона' })
    .required(),
  password: yup.string().required(), // .matches(/^\d+$/i, 'Только цифры').length(6),
})

const SigninForm: React.FC = () => {
  const dispatch = useDispatch()
  const { history, location } = useReactRouter()
  const { search } = location
  const {
    notValid,
    signin: { invalidCredentials },
  } = formLocale

  const [formProps, setFormProps] = useState<FormProps<SignInProps>>({
    data: { username: '', password: '' },
    processed: false,

    snackbar: {
      onClose: () => setFormProps({ ...formProps, snackbar: { ...formProps.snackbar, message: undefined } }),
    },
  })

  const parsed = parse(search)
  const hookForm = useForm<any>({ defaultValues: { username: parsed.login }, resolver: yupResolver(schema) })

  const onSubmit = async ({ username, password }: SignInProps) => {
    setFormProps({ ...formProps, snackbar: { ...formProps.snackbar, message: undefined }, processed: true })
    const { status, message } = await signin(username, password.replace(/\s/g, ''))

    if (status === ApiAnswerStatus.SUCCESS) {
      setFormProps({ ...formProps, processed: false, snackbar: { ...formProps.snackbar, message: undefined } })
      dispatch(authAction.auth(() => history.push('/profile')))
      TrackEvent({ eventCategory: 'Authorization', eventAction: 'send_a_form', eventLabel: 'success' })
    }
    if (status === ApiAnswerStatus.BREAK) {
      setFormProps({ ...formProps, processed: false, snackbar: { ...formProps.snackbar, message: invalidCredentials } })
      TrackEvent({
        eventCategory: 'Authorization',
        eventAction: 'send_a_form',
        eventLabel: 'unsuccess',
        eventContext: 'Неверный логин или пароль',
      })
    }
    if (status === ApiAnswerStatus.ERROR) {
      dispatch(propsAction.alert({ message }))
    }
  }

  const submitFailed = (err: any) => {
    TrackEvent({
      eventCategory: 'Registration',
      eventAction: 'send_r_form',
      eventLabel: 'unsuccess',
      eventContext: `Некоторые поля не заполнены или заполнены неверно: ${Object.entries(err)
        .map(([key]) => key)
        .join(', ')}`,
    })
    setFormProps({ ...formProps, processed: false, snackbar: { ...formProps.snackbar, message: notValid } })
  }

  useEffect(() => {
    hookForm.setFocus('username')
  }, [])

  return (
    <Box maxWidth={430} mx="auto">
      <Box pb={1} style={{ borderBottom: '1px solid #DAD0CB', marginBottom: 20, margin: '0 auto' }}>
        <SocialAuth eventCategory="Authorization" eventAction="over_social_r" label="Войти через соц. сеть" />
      </Box>

      <form onSubmit={hookForm.handleSubmit(onSubmit, submitFailed)}>
        <Box pt={8}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <PhoneInput name="username" form={hookForm} disabled={formProps.processed} />
            </Grid>

            <Grid item xs={12}>
              <Box position="relative">
                <PasswordInput name="password" form={hookForm} disabled={formProps.processed} />

                <Box textAlign="right" position="absolute" width="100%" top={50}>
                  <Button
                    variant="text"
                    style={{ fontWeight: 400, fontSize: 14 }}
                    onClick={() =>
                      history.push({
                        pathname: '/',
                        search: `?forgot${
                          hookForm.getValues('username') ? `&login=${hookForm.getValues('username')}` : ''
                        }`,
                      })
                    }
                  >
                    Забыли пароль?
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>

          <Snackbar {...formProps.snackbar} />

          <Box width="100%" maxWidth={200} mx="auto" mt={3}>
            <Button fullWidth variant="contained" color="secondary" type="submit" disabled={formProps.processed}>
              Войти
            </Button>
          </Box>

          <Box mt={2}>
            <Button variant="text" onClick={() => history.push({ search: 'signup' })}>
              Зарегистрироваться
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  )
}

export default SigninForm
