import React, { Fragment } from 'react'
import { Box } from '@material-ui/core'
import moment from 'moment'

type StopPageProps = { now?: string | null; stop?: string | null }

export const StopPage: React.FC<StopPageProps> = ({ now, stop }) => (
  <Fragment>
    {stop && moment(now).isAfter(moment(stop)) && (
      <Box textAlign="center" py={2} style={{ opacity: 0.5 }}>
        {/* {str.normalize(`Акция завершена ${moment(stop).format('DD-MM-YYYY HH:mm:ss')}`)} */}
      </Box>
    )}
  </Fragment>
)

StopPage.defaultProps = {
  now: undefined,
  stop: undefined,
}
