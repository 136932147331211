import createPalette from '@material-ui/core/styles/createPalette'

const palette = createPalette({
  common: { black: '#000', white: '#fff' },

  type: 'light',

  primary: {
    light: '#C9B1A6',
    main: '#AD7D66',
    contrastText: '#fff',
  },
  secondary: {
    main: '#fff',
    light: '#566169',
    contrastText: '#AD7D66',
  },

  error: { main: '#FA2626' },
  warning: { main: '#FFA740' },
  info: { main: '#D2E1E5' },
  success: { main: '#09D536' },

  background: { paper: '#E9F0F2', default: '#fff' },

  text: {
    primary: '#323E48',
    secondary: '#8C9297',
    disabled: 'rgba(78, 92, 104, 0.3)',
    hint: '#fff',
  },

  action: {
    active: '#D39E54',
    hover: '#8E583E',
    selected: '#D39E54',
    disabled: '#CACACA',
    disabledBackground: '#2A2A26',
    focus: '#D39E54',
  },

  divider: '#E3DDDC',

  tonalOffset: 0.2,
  contrastThreshold: 5,
})

export default palette
