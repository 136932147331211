import * as ls from 'local-storage'
import axios, { AxiosRequestConfig } from 'axios'
import { ApiAnswer, ApiAnswerStatus } from 'types'
import { TOKEN, reFetchPrivateAPIToken } from '../service'

const { REACT_APP_PROMO_API_URL = '/' } = process.env

const client = axios.create({
  baseURL: `${REACT_APP_PROMO_API_URL.replace(/^\/+/, '')}/`,
  responseType: 'json',
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
  },
})

export const apiFetch = async (config: AxiosRequestConfig): Promise<ApiAnswer<any>> => {
  try {
    const res = await client.request(config)
    return res.data
  } catch (error: any) {
    return {
      data: error?.response?.data,
      status: error?.response?.status,
      message: error?.response?.data?.message ?? error?.response?.message,
    }
  }
}

export const withPrivateAPIToken = async (config: AxiosRequestConfig): Promise<ApiAnswer<any>> => {
  const token = ls.get<{ access_token?: string; refresh_token?: string }>(TOKEN.PRIVATE)

  if (token && token.access_token) {
    const test = await apiFetch({
      ...config,
      headers: { 'X-Access-Token': `Bearer ${token.access_token}` },
    }).then(async (res) => {
      if (res?.status !== ApiAnswerStatus.UNAUTHENTICATED) return res

      return reFetchPrivateAPIToken(token.refresh_token || '').then(async (result) => {
        if (result.status !== ApiAnswerStatus.SUCCESS) {
          console.warn('Неудачный перезапрос access-токена')
          ls.remove(TOKEN.PRIVATE)
          return result
        }
        ls.set(TOKEN.PRIVATE, result.data)

        return apiFetch({
          ...config,
          headers: { 'X-Access-Token': `Bearer ${result.data.access_token}` },
        })
      })
    })
    return test
  }

  return apiFetch(config)
}
