import { Fragment, lazy, Suspense, useEffect, useState } from 'react'
import { usePrevious } from 'react-use'
import { useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { parse } from 'query-string'
import { useReduxSelector } from 'hooks'
import { socialFetchPrivateAPIToken } from '../api/service'
import { withPlanner, WithPlannerProps } from '../components/hoc'
import { PageContainer } from '../pages'
import { ApiAnswerStatus } from '../types'
import { get, constants } from '../api'
import { promoUserInfo, promoGet } from '../api/promo'
import { propsAction } from '../store/props'
import { authAction } from '../store/auth'
import ModalRoot from './modal-root'
import Routes from './routes'

const ErrorFallback = lazy(() => import('../pages/error-fallback'))

const App: React.FC<WithPlannerProps> = withPlanner(({ planner }) => {
  // const DEBUG = process.env.REACT_APP_DEBUG === 'true'

  const dispatch = useDispatch()
  const { isAuth } = useReduxSelector((state) => state.auth)
  const history = useHistory()
  const { location } = history
  const { pathname, search } = location
  const prevLocation = usePrevious(location)
  const { pathname: prevPathname } = prevLocation || {}
  const { SUCCESS } = ApiAnswerStatus

  const [reqError, setReqError] = useState<boolean>(false)

  const repeatRequest = (req: () => any) => {
    setReqError(true)
    if (planner?.clearTimeouts()) planner?.timeout(() => req(), 10000)
  }

  const fetchPromoUser = async () => {
    const { data: promoUser, status } = await promoUserInfo()
    if (status === ApiAnswerStatus.SUCCESS) {
      dispatch(propsAction.setProps({ promoUser }))
    }
  }

  const auth = async (isSocialAuth?: boolean) => {
    dispatch(authAction.auth(() => isSocialAuth && history.push('/profile')))
  }

  const socialAuth = async () => {
    const { access_token, provider } = parse(search)
    if (typeof access_token === 'string' && typeof provider === 'string') {
      const { status } = await socialFetchPrivateAPIToken(provider, access_token)
      if (status === SUCCESS) auth(true)
      else history.push({ search: '' })
    }
  }

  const requestProjectConstant = async () => {
    const { data, status } = await constants()
    if (status === SUCCESS) {
      // if (ls.get('loglevel:webpack-dev-server')) {
      //   Object.keys(data).map((key) => {
      //     const param = { [key]: ls.get(`_${key}`) }
      //     if (param?.[key] !== null) {
      //       Object.assign(data, param)
      //       // eslint-disable-next-line no-console
      //       if (DEBUG) console.warn(`Warning! Constant "${key}" are changed.`)
      //     }
      //     return param
      //   })
      // }
      dispatch(propsAction.setProps({ recaptchaSitekey: data?.CAPTCHA_SITEKEY, ...data }))
      setReqError(false)
    } else repeatRequest(requestProjectConstant)
  }

  const getPromoDocs = async () => {
    const { data, status } = await promoGet('doc')
    if (status === ApiAnswerStatus.SUCCESS && data?.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i]?.slug === 'rules') {
          dispatch(propsAction.setProps({ promoRulesSrc: data[i]?.primary_media?.src_link }))
          return true
        }
      }
    }
    return false
  }

  const getActivityInfo = async () => {
    const { data, status } = await get('/activity/info')

    let promoAviable = false
    let promo = null;
    if (status === SUCCESS && data && data?.length > 0) {
      data.map((d: any) => {
         if (d?.key === process.env.REACT_APP_PROMO_KEY) {
          promoAviable = true;
          promo = d;
         }
        return d
      })
    }
    dispatch(propsAction.setProps({ promoAviable, promo }))
  }

  const getBreedTypes = async () => {
    const { data: breedTypes, status } = await get('data/breed_species/get')
    if (status === SUCCESS) {
      dispatch(propsAction.setProps({ breedTypes }))
    }
  }

  const getRegisterFocus = async () => {
    const { data: registerFocus, status } = await get('data/register_focus/get')
    if (status === SUCCESS) {
      dispatch(propsAction.setProps({ registerFocus }))
    }
  }

  const getBreeds = async () => {
    const { data: breeds, status } = await get('data/breed/get')
    if (status === SUCCESS) {
      dispatch(propsAction.setProps({ breeds }))
    }
  }

  useEffect(() => {
    if (prevPathname !== pathname && pathname !== '/signout') {
      auth()
      requestProjectConstant()
    }
  }, [pathname])

  useEffect(() => {
    if (isAuth) {
      fetchPromoUser()
    }
  }, [isAuth])

  useEffect(() => {
    let mounted = true
    if (mounted) {
      socialAuth()
      getBreeds() // Породы
      getBreedTypes()
      getRegisterFocus()
      getPromoDocs()
      getActivityInfo()
    }
    return () => {
      mounted = false
    }
  }, [])

  if (reqError)
    return (
      <Suspense fallback="">
        <ErrorFallback />
      </Suspense>
    )

  return (
    <Fragment>
      <ModalRoot />

      <PageContainer>
        <Routes />
      </PageContainer>
    </Fragment>
  )
})

export default App
