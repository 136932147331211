import * as yup from 'yup'
import moment from 'moment'
import { validation } from 'components/form-control'

export const acceptType = ['image/jpeg', 'image/jpg', 'image/png']
const formats = acceptType.join(', ').replace(/\w+?\//g, '')

export const schema = validation({
  breed_species_id: yup
    .number()
    .typeError('Выберите питомца из списка')
    .required('Выберите питомца из списка')
    .positive()
    .integer(),

  breed_id: yup
    .object()
    .typeError('Выберите породу из списка')
    .required('Выберите породу из списка')
    .test('number', 'Выберите породу из списка', (i) => i?.id),

  pet_name: yup
    .string()
    .required()
    .min(2)
    .max(40)
    .matches(/^[а-яёА-ЯЁa-zA-Z\s-]*$/i, 'Только буквенные символы'),

  pet_birthday_at: yup
    .string()
    .required()
    .test({ test: (val, _) => val?.toLowerCase() !== 'invalid date', message: 'Введена некорректная дата' })
    .test({
      test: (val, _) => moment(val).isAfter('1980-01-01') && moment(val).isBefore(new Date()),
      message: 'Дата вне допустимого интервала',
    }),

  nursery_recorder_id: yup.number().integer(),

  breeder_phone: yup
    .string()
    .matches(/^\d{10}$/i, { message: 'Указан некорректный номер телефона', excludeEmptyString: true }),

  feed: yup
    .string()
    .required()
    .min(2)
    .max(40)
    .matches(/^[а-яёА-ЯЁa-zA-Z:()\s-]*$/i, { excludeEmptyString: true }),

  image: yup
    .mixed()
    .test('required', 'Выберите фото питомца', (val) => typeof val?.[0] !== 'undefined')
    .test(
      'fileSize',
      `Размер файла превышает ${Math.floor(15000000 / 1000000)} Мб`,
      (val) => val?.[0]?.size <= 15000000
    )
    .test('accept', `Выберите файл в формате ${formats}`, (val) => acceptType.includes(val?.[0]?.type)),
})
