import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Grid } from '@material-ui/core'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import { changePassword } from 'api'
import { useReduxDispatch } from 'hooks'
import { propsAction } from 'store/props'
import { authAction } from 'store/auth'
import { ApiAnswerStatus, FormProps } from 'types'
import { PasswordInput, SubmitButton, Snackbar, validation, formLocale } from 'components'

type ChangePasswordFormProps = { password: string; password_confirmation: string }

const schema = validation({
  password: yup.string().required(),
  password_confirmation: yup.string().required(),
})

const ChangePasswordForm: React.FC<{ rootModal: string }> = ({ rootModal }) => {
  const { SUCCESS } = ApiAnswerStatus
  const { notValid, serverError } = formLocale

  const [formProps, setFormProps] = useState<FormProps<ChangePasswordFormProps>>({
    data: { password: '', password_confirmation: '' },
    processed: false,
    snackbar: {
      onClose: () => setFormProps({ ...formProps, snackbar: { ...formProps.snackbar, message: undefined } }),
    },
  })

  const dispatch = useReduxDispatch()
  const authDispatch = useDispatch()

  const hookForm = useForm<any>({ resolver: yupResolver(schema) })

  const onSubmit = async (props: ChangePasswordFormProps) => {
    const { status, message } = await changePassword(props)

    if (status === SUCCESS)
      dispatch(
        propsAction.alert({
          message,
          rootModal,
          onClose: () => authDispatch(authAction.auth()),
        })
      )
    else
     setFormProps({ ...formProps, processed: false, snackbar: { ...formProps.snackbar, message: message } })
  }

  const submitFailed = (_: ChangePasswordFormProps) => {
    setFormProps({ ...formProps, processed: false, snackbar: { ...formProps.snackbar, message: notValid } })
  }

  return (
    <Box maxWidth={430} mt={3} mx="auto">
      <form onSubmit={hookForm.handleSubmit(onSubmit, submitFailed)} autoComplete="off">
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <PasswordInput label="Введите новый пароль" name="password" form={hookForm} />
          </Grid>

          <Grid item xs={12}>
            <PasswordInput label="Повторите пароль" name="password_confirmation" form={hookForm} />
          </Grid>

          <Grid item xs={12}>
            <Snackbar {...formProps.snackbar} />
          </Grid>

          <Grid item xs={12}>
            <Box width="100%" maxWidth={260} mt={2} mx="auto">
              <SubmitButton
                fullWidth
                color="secondary"
                variant="contained"
                title="Сменить пароль"
                disabled={formProps.processed}
                processed={formProps.processed}
              />
            </Box>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default ChangePasswordForm
