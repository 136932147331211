import { Fragment, useEffect, useRef, useState } from 'react'
import { Box, Button, Collapse, Fade, Grid } from '@material-ui/core'
import { LocationOn, Pets } from '@material-ui/icons'
import { parse } from 'query-string'
import useReactRouter from 'use-react-router'
import { yupResolver } from '@hookform/resolvers/yup'
import ReCAPTCHA from 'react-google-recaptcha'
import { useForm } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import {
  AutocompleteFetch,
  AutocompleteOption,
  AutocompleteSelect,
  CheckboxInput,
  DateInput,
  EmailInput,
  formLocale,
  PhoneInput,
  SelectInput,
  TextInput,
} from 'components/form-control'
import { Snackbar, SocialAuth, SubmitButton } from 'components'
import { getFias, inviteOwner, signup, socialUpdate } from 'api/actions'
import { ga } from 'utils'
import { useReduxDispatch, useReduxSelector } from 'hooks'
import { ApiAnswerStatus, Entity, FormProps, RegisterUser } from 'types'
import { propsAction } from 'store/props'
import { authAction } from 'store/auth'
import { schemas } from './signup-validation'

const SignupForm: React.FC<{ rootModal: string }> = ({ rootModal }) => {
  const maxSteps = 4
  const DEBUG = process.env.REACT_APP_DEBUG === 'true'
  const { history, location } = useReactRouter()
  const { search } = location
  const { SUCCESS, BREAK } = ApiAnswerStatus

  const recaptchaRef = useRef<ReCAPTCHA | null>(null)

  const { modal } = useReduxSelector((state) => state.props)
  const { params } = modal[rootModal] || {}

  const dispatch = useReduxDispatch()
  const authDispatch = useDispatch()
  const { isAuth } = useReduxSelector((state) => state.auth)
  const { recaptchaSitekey, breedTypes, registerFocus, breeds } = useReduxSelector((state) => state.props)

  const { notValid, serverError, captchaError, rulesCheckbox, subscribeSmsCheckbox, subscribeEmailCheckbox } =
    formLocale

  const [step, setStep] = useState<number>(1)
  const [registerFocusId, setRegisterFocusId] = useState<number>()
  const [breedsItems, setBreedsItems] = useState<Entity[]>([])

  const [breedPhone, setBreedPhone] = useState<boolean>(false)
  const [formProps, setFormProps] = useState<FormProps<any>>({
    data: undefined,
    processed: false,
    snackbar: {
      onClose: () => setFormProps({ ...formProps, snackbar: { ...formProps.snackbar, message: undefined } }),
    },
  })

  const { provider, provider_user_id, name, first_name, last_name, email } = parse(search)
  const hookForm = useForm<any>({
    defaultValues: { provider, provider_user_id, name, first_name, last_name, email },
    resolver: yupResolver(schemas[step - 1]),
  })
  const {
    reset,
    getValues,
    formState: { isValid },
  } = hookForm

  const setBreeds = (breed_species_id?: number) => {
    const res: Entity[] = []
    breeds?.forEach((b) => {
      if (b.breed_species.id === breed_species_id) res.push({ ...b, key: b.title })
    })
    setBreedsItems(res)
  }

  const getBreedSpecie = (breed_id: number) => {
    let res
    if (breeds) {
      for (let i = 0; i < breeds.length; i++)
        if (breeds[i].id === breed_id) {
          res = breeds[i]
          break
        }
    }
    return res
  }

  const onRecaptchaChange = async (recaptcha: string) => {
    const { message, status } = await signup({ ...formProps.data, recaptcha })
    recaptchaRef.current?.reset()

    switch (status) {
      case SUCCESS:
        setFormProps({ ...formProps, processed: false })
        dispatch(
          propsAction.alert({
            message,
            rootModal,
            onClose: () => history.push({ pathname: '/', search: `?signin&login=${formProps.data?.phone}` }),
          })
        )
        ga.event({ cat: 'Registration', action: 'send_r_form', label: 'success' })
        break
      default:
        setFormProps({ ...formProps, processed: false })
        dispatch(
          propsAction.alert({
            message,
            onClose: () => {
              setStep(1)
              reset()
            },
          })
        )
        ga.event({
          cat: 'Registration',
          action: 'send_r_form',
          label: 'unsuccess',
          ctx: message.error?.join(';'),
          code: status,
        })
        break
      // default:
        // setFormProps({ ...formProps, processed: false, snackbar: { ...formProps.snackbar, message: serverError } })
        // ga.event({
        //   cat: 'Registration',
        //   action: 'send_r_form',
        //   label: 'unsuccess',
        //   ctx: serverError.error?.join(';'),
        //   code: status,
        // })
    }
  }

  const onSubmit = (data: any) => {
    if (!recaptchaSitekey) {
      setFormProps({ ...formProps, snackbar: { ...formProps.snackbar, message: captchaError } })
      return
    }

    // Вытаскиваем id из объектов (select/autocomplete input)
    const props: { [x: string]: any } = {}
    // eslint-disable-next-line array-callback-return
    Object.keys(data).map((k) => {
      props[k] = typeof data[k] === 'object' ? data[k]?.id ?? data[k] : data[k]
    })

    const { city, region, area, np } = data.address || {}
    const payload = { ...props, city, region, area, np }

    if (DEBUG) console.log('signUp Submit', payload)

    if (step < maxSteps) {
      setStep((s) => s + 1)
      setFormProps({
        ...formProps,
        data: { ...formProps.data, ...payload },
        snackbar: { ...formProps.snackbar, message: undefined },
      })
    } else {
      setFormProps({
        ...formProps,
        processed: true,
        data: { ...formProps.data, ...payload },
        snackbar: { ...formProps.snackbar, message: undefined },
      })
      recaptchaRef.current?.execute()
    }
  }

  const submitFailed = (err: { [x: string]: string[] }) => {
    ga.event({
      cat: 'Registration',
      action: 'send_r_form',
      label: 'unsuccess',
      ctx: `Некоторые поля не заполнены или заполнены неверно: ${Object.entries(err)
        .map(([key]) => key)
        .join(', ')}`,
    })
    if (DEBUG) console.log('signUp Error', err)
    setFormProps({ ...formProps, snackbar: { ...formProps.snackbar, message: notValid } })
  }

  useEffect(() => {
    switch (step) {
      case 1:
        hookForm.setFocus('last_name')
        break
      case 2:
        hookForm.setFocus('phone')
        break
      case 3:
        hookForm.setFocus('breed_species_id')
        break
      case 4:
        hookForm.setFocus(typeof params?.r === 'undefined' ? 'nursery_recorder_id' : 'breeder_phone')
        break
      default:
    }
  }, [step])

  const handleSocialUpdate = async () => {
    const { status, message } = await socialUpdate({ provider, provider_user_id })
    if (status === SUCCESS) authDispatch(authAction.auth(() => dispatch(propsAction.alert({ message }))))
  }

  const handleInviteOwner = async () => {
    const { status, data } = await inviteOwner(params?.r)
    if (status === SUCCESS)
      Object.entries(data as RegisterUser).map(([key, val]) => {
        if (val) {
          switch (key) {
            case 'breeder_phone':
              hookForm.setValue('nursery_recorder_id', 1)
              hookForm.setValue('breeder_phone', val)
              break
            case 'breed_id': {
              const breedSpecie = getBreedSpecie(parseInt(val.toString(), 10))
              setBreeds(breedSpecie?.breed_species?.id)
              hookForm.setValue('breed_species_id', breedSpecie?.breed_species?.id)
              hookForm.setValue('breed_id', {
                id: breedSpecie?.id,
                title: breedSpecie?.title,
                key: breedSpecie?.title,
              })
              break
            }
            default:
              hookForm.setValue(key, val)
          }
        }
        return [key, val]
      })
  }

  useEffect(() => {
    if (isAuth && provider && provider_user_id) handleSocialUpdate()
    if (params?.r) handleInviteOwner()
  }, [isAuth])

  useEffect(() => {
    if (params?.r && breeds) handleInviteOwner()
  }, [breeds])

  useEffect(() => {
    if (isValid)
      setFormProps({
        ...formProps,
        snackbar: { ...formProps.snackbar, message: undefined },
      })
  }, [isValid])

  useEffect(() => {
    ga.event({ cat: 'Registration', action: 'open_r_form' })
    return () => {
      const dirtyFields: string[] = []
      // eslint-disable-next-line array-callback-return
      Object.entries(getValues()).map(([key, val]) => {
        if (val) dirtyFields.push(key)
      })
      if (dirtyFields.length > 0)
        ga.event({ cat: 'Registration', action: 'leave_registartion', label: dirtyFields?.join(', ') })
    }
  }, [])

  const addressFetch = async (val?: string): Promise<AutocompleteOption[]> => {
    const data = await getFias({ query: val ?? '' })
    const res = data?.suggestions
      .filter((s: any) => {
        return s.data.fias_level !== '7' && s.data.fias_level !== '65' && !s.data.street && !s.data.house
      })
      .map((s: any) => ({
        value: s?.value,
        title: s?.value,
        key: s?.key,
        unrestricted_value: s?.unrestricted_value,
        city: s?.data.city_with_type,
        region: s?.data.region_with_type,
        area: s?.data.area_with_type,
        np: s?.data.settlement_with_type,
      }))
    return res
  }

  return (
    <Fragment>
      <Box maxWidth={430} mx="auto" style={{ borderBottom: '1px solid #DAD0CB' }}>
        <SocialAuth
          eventCategory="Registration"
          eventAction="over_social_r"
          label="Зарегистрироваться через соц.сеть"
        />
      </Box>

      <form onSubmit={hookForm.handleSubmit(onSubmit, submitFailed)} noValidate>
        {recaptchaSitekey && (
          <ReCAPTCHA
            ref={recaptchaRef}
            size="invisible"
            sitekey={recaptchaSitekey}
            onChange={(t) => onRecaptchaChange(t ?? '')}
            onErrored={() => console.error('onRecaptchaErrored')}
            onExpired={() => setFormProps({ ...formProps, processed: false, snackbar: undefined })}
          />
        )}

        <Box maxWidth={430} pt={4} m="0 auto" style={{ transition: '0.15s all ease-in-out' }}>
          <Grid container direction="column" justifyContent="space-between" style={{ minHeight: 400 }}>
            <Grid item xs={12}>
              <Box pb={4} fontSize={15} fontWeight={500} textAlign="right" color="#8C9297">
                Шаг {step} из {maxSteps}
              </Box>

              {step === 1 && (
                <Fade in>
                  <Box>
                    <TextInput name="last_name" label="Фамилия" form={hookForm} placeholder="Пример: Иванов" />

                    <TextInput name="first_name" label="Имя" form={hookForm} placeholder="Пример: Иван" />

                    <TextInput
                      name="middle_name"
                      label="Отчество"
                      form={hookForm}
                      placeholder="Пример: Иванович"
                      options={{ required: false }}
                    />
                  </Box>
                </Fade>
              )}

              {step === 2 && (
                <Fade in>
                  <Box>
                    <PhoneInput name="phone" form={hookForm} />
                    <EmailInput name="email" form={hookForm} />
                    <AutocompleteFetch
                      label="Населенный пункт"
                      placeholder="Например: Москва"
                      name="address"
                      fetch={addressFetch}
                      form={hookForm}
                      optionIcon={<LocationOn fontSize="inherit" style={{ padding: 0 }} />}
                    />
                  </Box>
                </Fade>
              )}

              {step === 3 && (
                <Fade in>
                  <Box>
                    <SelectInput
                      name="breed_species_id"
                      label="Питомец"
                      items={breedTypes}
                      form={hookForm}
                      onChange={setBreeds}
                    />

                    <AutocompleteSelect
                      name="breed_id"
                      label="Порода"
                      defaultOptions={breedsItems}
                      placeholder="Не выбрано"
                      optionIcon={<Pets />}
                      disabled={breedsItems.length === 0}
                      form={hookForm}
                    />

                    <TextInput name="pet_name" label="Кличка" form={hookForm} placeholder="Пример: Лаки" />

                    <DateInput
                      name="pet_birthday_at"
                      label="Дата рождения"
                      min={new Date('1980-01-01')}
                      form={hookForm}
                    />
                  </Box>
                </Fade>
              )}

              {step === 4 && (
                <Fade in>
                  <Box>
                    <SelectInput
                      name="nursery_recorder_id"
                      label="Вы приобрели питомца у заводчика?"
                      items={[
                        { id: 1, key: '1', title: 'Да' },
                        { id: 2, key: '0', title: 'Нет' },
                      ]}
                      onChange={(id) => setBreedPhone(id === 1)}
                      form={hookForm}
                    />
                    <Collapse in={breedPhone || typeof params?.r !== 'undefined'}>
                      <Box
                        className={`animated ${
                          breedPhone || typeof params?.r !== 'undefined' ? 'zoomIn' : 'zoomOut'
                        } faster`}
                      >
                        <PhoneInput
                          name="breeder_phone"
                          label="Телефон заводчика"
                          required={breedPhone}
                          form={hookForm}
                          disabled={!breedPhone && typeof params?.r === 'undefined'}
                        />
                      </Box>
                    </Collapse>
                    <SelectInput
                      name="register_focus_id"
                      label="Откуда вы узнали о программе лояльности?"
                      items={registerFocus}
                      form={hookForm}
                      onChange={(id) => {
                        setRegisterFocusId(id)
                      }}
                    />

                    {registerFocusId === 7 && (
                      <TextInput
                        name="focus_note"
                        label="Свой вариант"
                        form={hookForm}
                        placeholder=""
                        options={{ required: false }}
                      />
                    )}
                    <Box textAlign="left" mb={3}>
                      <CheckboxInput name="rules" label={rulesCheckbox} required form={hookForm} />
                      <CheckboxInput name="subscribe_sms" label={subscribeSmsCheckbox} form={hookForm} />
                      <CheckboxInput name="subscribe_email" label={subscribeEmailCheckbox} form={hookForm} />
                    </Box>
                  </Box>
                </Fade>
              )}
            </Grid>

            <Grid item xs={12}>
              <Snackbar {...formProps.snackbar} />
            </Grid>

            <Grid item xs={12}>
              <Box mt={5}>
                <Grid container justifyContent="space-between">
                  <Grid item xs={6} sm={5}>
                    {step > 1 && (
                      <Button
                        fullWidth
                        variant="contained"
                        onClick={() => {
                          if (step > 1) setStep(step - 1)
                        }}
                      >
                        Назад
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={6} sm={5}>
                    <SubmitButton
                      color="secondary"
                      variant="contained"
                      fullWidth
                      disabled={formProps.processed}
                      processed={formProps.processed}
                      title={step < maxSteps ? 'Далее' : 'Подтвердить'}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </form>
    </Fragment>
  )
}

export default SignupForm
