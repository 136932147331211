import useReactRouter from 'use-react-router'
import { Modal } from 'components'
import TrackEvent from 'components/GAnalitics'
import { useReduxSelector } from 'hooks'
import ForgotForm from './forgot-form'

export const forgotModalName = 'ForgotPasswordModal'

export const ForgotModal: React.FC = () => {
  const { history } = useReactRouter()
  const { modal } = useReduxSelector((state) => state.props)
  const { show = false } = modal[forgotModalName] || {}

  return (
    <Modal
      open={show}
      maxWidth="sm"
      name={forgotModalName}
      title="Восстановление пароля"
      onCloseDialog={() => history.push({ search: '' })}
      onOpen={() => TrackEvent({ eventCategory: 'Forgot', eventAction: 'open_f_form' })}
    >
      <ForgotForm rootModal={forgotModalName} />
    </Modal>
  )
}
