import { Box, Button, Grid } from '@material-ui/core'
import { Modal } from 'components'
import { propsAction } from 'store/props'
import { useReduxDispatch, useReduxSelector } from 'hooks'
import { useStyles } from '../../promo.style'

export const uploadRulesModalName = 'UploadRulesModal'

export const UploadRulesModal: React.FC = () => {
  const dispatch = useReduxDispatch()
  const { modal } = useReduxSelector((state) => state.props)
  const { show = false, params } = modal[uploadRulesModalName] || {}
  const { variant = 'photo' } = (params as { variant: 'photo' | 'video' | 'vk' }) || {}
  const classes = useStyles()

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const titleVars = (variant: string) => {
    switch (variant) {
      case 'photo':
        return 'Загрузите фото своего&nbsp;питомца'
      case 'video':
        return 'Загрузите видео со&nbsp;своим&nbsp;питомцем'
      case 'vk':
        return 'Требования к работе'
      default:
        return 'Информация'
    }
  }

  const handleClose = () => dispatch(propsAction.modal({ modal: { [uploadRulesModalName]: { show: false } } }))

  return (
    <Modal open={show} maxWidth="sm" title={titleVars(variant)} name={uploadRulesModalName} onCloseDialog={handleClose}>
      {variant === 'vk' && (
        <Box>
          <Box mb={3} color="#566169">
            Загруженная работа должна соответствовать следующим требованиям:
          </Box>
          <Box mb={3} color="#566169" textAlign="left" fontSize={16}>
            <ul className={classes.bullet}>
              <li>
                Ссылка должна вести на пост, размещённый на Вашей странице ВКонтакте с хештегами #МойТалантливыйДруг и
                #Alphapet
              </li>
              <li>Ваша страница ВКонтакте должна быть открыта</li>
              <li>
                Объектом фотосъемки/видеосъемки в посте должны быть домашние питомцы (кошки и/или собаки), для которых
                был приобретен корм AlphaPet
              </li>
              <li>
                На фотографии/видео не должно быть продукции под другими товарными знаками, допустима только продукция
                AlphaPet
              </li>
            </ul>
          </Box>

          <Box mx="auto" maxWidth={200}>
            <Button fullWidth variant="contained" color="secondary" onClick={handleClose}>
              Понятно
            </Button>
          </Box>
        </Box>
      )}
      {(variant === 'photo' || variant === 'video') && (
        <Box>
          <Box mb={3} color="#566169">
            Креатив и уникальность приветствуются
          </Box>

          {/* <Box borderBottom="1px solid #DAD0CB" py={2} fontSize={{ xs: 16, sm: 18 }}>
          <Grid container justifyContent="space-between">
            <Grid item>
              <Box color="primary.main">Тип файла</Box>
            </Grid>
            <Grid item>
              <Box fontWeight={400}>{variant === 'photo' ? 'JPEG, JPG, HEIF' : 'AVI, MP4, HEVC'}</Box>
            </Grid>
          </Grid>
        </Box> */}

          <Box borderBottom="1px solid #DAD0CB" py={2} fontSize={{ xs: 16, sm: 18 }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Box color="primary.main">Размер</Box>
              </Grid>
              <Grid item>
                <Box fontWeight={400}>{variant === 'photo' ? 'не более 15 Мб' : 'не более 100 Мб'}</Box>
              </Grid>
            </Grid>
          </Box>

          <Box py={2} fontSize={{ xs: 16, sm: 18 }}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <Box color="primary.main">{variant === 'photo' ? 'Разрешение' : 'Длительность'}</Box>
              </Grid>
              <Grid item>
                <Box fontWeight={400}>{variant === 'photo' ? 'не менее 200 (двести) dpi' : 'не более 1 мин'}</Box>
              </Grid>
            </Grid>
          </Box>

          <Box mt={3} mb={5} bgcolor="#F0EDEC" p={3} fontSize={14} fontWeight={400}>
            {variant === 'photo' ? 'Фото' : 'Видео'} должно быть технически качественным
          </Box>

          <Box mx="auto" maxWidth={200}>
            <Button fullWidth variant="contained" color="secondary" onClick={handleClose}>
              Понятно
            </Button>
          </Box>
        </Box>
      )}
    </Modal>
  )
}
