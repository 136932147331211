import moment from 'moment'
import { KeyboardTimePicker } from '@material-ui/pickers'
import { AccessTime } from '@material-ui/icons'
import { Controller, UseFormReturn } from 'react-hook-form'

type Props = {
  form: UseFormReturn<any>
  name: string
  disabled?: boolean
  placeholder?: string
  label?: string
}

export const TimeInput: React.FC<Props> = ({
  form,
  name,
  disabled = false,
  label = 'Время регистрации',
  placeholder = `Пример: ${moment().format('HH:mm')}`,
}) => {
  const { control, formState, setError } = form
  const { errors } = formState

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: { onChange, value = null, onBlur, ref } }) => (
        <KeyboardTimePicker
          autoOk
          inputRef={ref}
          value={value}
          ampm={false}
          onChange={(v) => onChange(moment(v).format('YYYY-MM-DD HH:mm:ss'))}
          disabled={disabled}
          label={label}
          // onError={(err) => {
          //   const msg = errors[name]?.message
          //   if (err && err.toString() !== msg) {
          //     setError(name, { type: 'focus', message: err.toString() })
          //   }
          // }}
          orientation="portrait"
          format="HH:mm"
          maskChar=" "
          onBlur={onBlur}
          placeholder={placeholder}
          inputVariant="filled"
          keyboardIcon={<AccessTime style={{ padding: 0, width: '0.8em' }} />}
          fullWidth
          InputLabelProps={{ shrink: true }}
          cancelLabel="Отмена"
          okLabel="Готово"
          invalidDateMessage="Введено некорректное время"
          helperText={errors[name]?.message}
        />
      )}
    />
  )
}
