import { MutableRefObject, useEffect } from 'react'

const listenerCallbacks = new WeakMap()
let mainObserver: IntersectionObserver | undefined

const handleIntersections = (entries: IntersectionObserverEntry[]) => {
  entries.forEach((entry) => {
    if (listenerCallbacks.has(entry.target)) {
      const callback = listenerCallbacks.get(entry.target)

      if (entry.isIntersecting || entry.intersectionRatio > 0) {
        mainObserver?.unobserve(entry.target)
        listenerCallbacks.delete(entry.target)
        callback()
      }
    }
  })
}

const getIntersectionObserver = () => {
  if (mainObserver === undefined) {
    mainObserver = new IntersectionObserver(handleIntersections, {
      rootMargin: '100px',
      threshold: 0.15,
    })
  }
  return mainObserver
}

type UseIntersection = (elem: MutableRefObject<HTMLDivElement | null>, callback: () => void) => void

export const useIntersection: UseIntersection = (elem, callback) => {
  useEffect(() => {
    const target = elem.current
    const observer = getIntersectionObserver()

    if (target) {
      listenerCallbacks.set(target, callback)
      observer.observe(target)
    }

    return () => {
      if (target) {
        listenerCallbacks.delete(target)
        observer.unobserve(target)
      }
    }
  }, [])
}
