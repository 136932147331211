import { ReactNode, useEffect, useState } from 'react'
import { Box, Button, Collapse, Paper } from '@material-ui/core'
import { useDropzone } from 'react-dropzone'
import { dataURLtoFile, image, imageOrientation, str } from 'utils'
import { receiptUpload } from 'api'
import { ApiAnswerStatus } from 'types'
import { Preloader } from 'components'
import { ScanFunction } from '../types'
import locale from '../locale'
import useStyles from '../style'
import { propsAction } from '../../../store/props'
import { useReduxDispatch } from '../../../hooks'

interface Props {
  image?: string

  onExit(id?: any): void

  handleScan: ScanFunction
  title?: ReactNode | string
  processed?: boolean
}

const UploadReceiptDropzone: React.FC<Props> = (props) => {
  const {
    title = str.normalize(locale.dropzone.mainLabel),
    processed: externalProcessed = false,
    handleScan,
    onExit,
    image: externalImage,
  } = props

  const classes = useStyles()
  const DEBUG = process.env.REACT_APP_DEBUG === 'true'
  const dispatch = useReduxDispatch()
  const [show, setShow] = useState<boolean>(false)
  const [processed, setProcessed] = useState(false)
  const [orientation, setOrientation] = useState<imageOrientation>(1)
  const [b64Image, setB64Image] = useState<string | undefined>(undefined)

  const onDrop = (acceptedFiles: File[]) => {
    setProcessed(true)
    const file = acceptedFiles[0]
    image.getOrientation(file, (o) => {
      if (DEBUG) console.log('getOrientation:', o)
      if (o > 0) setOrientation(o as imageOrientation)
    })
    const fr = new FileReader()
    fr.onloadend = () => setB64Image(fr.result?.toString())
    fr.readAsDataURL(file)
  }

  const { getRootProps, getInputProps } = useDropzone({ multiple: false, accept: 'image/*', onDrop })

  const handleUpload = async (imgData?: File) => {
    if (imgData) {
      const { status, data, message } = await receiptUpload({ image: imgData as Blob })
      setProcessed(false)
      console.log(data)

      if (status === ApiAnswerStatus.ERROR && data === null) {
        onExit()
        dispatch(
          propsAction.alert({
            title: 'Информация',
            message,
            actions: [{ title: 'Хорошо', variant: 'outlined', color: 'primary' }],
          })
        )
      } else {
        const { extra, id } = data?.image
        if (status === ApiAnswerStatus.SUCCESS && typeof extra?.qr !== 'undefined') {
          handleScan(extra.qr, b64Image, id)
        } else {
          onExit(id)
        }
      }
    }
  }

  useEffect(() => {
    setB64Image(externalImage)
  }, [externalImage])

  useEffect(() => {
    if (typeof b64Image !== 'undefined' && typeof externalImage === 'undefined') {
      const [type, ext] = b64Image.split(':')[1].split(';')[0].split('/')
      if (type !== 'image' || (ext !== 'jpeg' && ext !== 'png')) onExit(null)

      const HTMLImage = new Image()
      HTMLImage.onload = ({ target }) => {
        const { canvas } = image.setOrientation(target as HTMLImageElement, orientation)
        const b64 = canvas?.toDataURL('image/jpeg')
        const f = dataURLtoFile(b64, 'receipt.jpeg')
        if (f) handleUpload(f)
      }
      HTMLImage.src = b64Image
    }
  }, [b64Image])

  useEffect(() => {
    let mounted = true
    if (mounted) {
      setShow(true)
    }
    return () => {
      mounted = false
      setShow(false)
    }
  }, [])

  return (
    <Collapse in={show}>
      <Box {...getRootProps({ className: `dropzone animated ${show ? 'zoomIn' : 'zoomOut'} faster` })}>
        <input
          {...getInputProps()}
          disabled={processed || externalProcessed || (typeof b64Image !== 'undefined' && b64Image?.length > 0)}
        />

        <Paper square>
          <Box
            component={Button}
            className={`${classes.dropArea} ${b64Image && !processed && !externalProcessed ? 'preview' : ''}`}
            style={{ background: `url(${b64Image}) top / 110% no-repeat` }}
          >
            {!b64Image && title}

            {(processed || externalProcessed) && (
              <Box className={classes.preloader}>
                <Preloader />
              </Box>
            )}
          </Box>
        </Paper>
      </Box>
    </Collapse>
  )
}

export default UploadReceiptDropzone
