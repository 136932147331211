import { useState, useEffect, ReactNode } from 'react'
import { Grid, Typography, TextField } from '@material-ui/core'
import { ExpandMoreRounded } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { UseFormReturn, Controller } from 'react-hook-form'
import { withPlanner, WithPlannerProps } from '../hoc'

type Props = {
  form: UseFormReturn<any>
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  processed?: boolean
  optionIcon?: ReactNode
  noOptionsText?: string
  defaultOptions?: AutocompleteOption[]
  freeSolo?: boolean
  fetch(val: string): Promise<AutocompleteOption[]>
}

export type AutocompleteOption = {
  id?: number
  key: any
  value: string | number
  title: string
  unrestricted_value?: string | number
}

export const AutocompleteFetch: React.FC<Props & WithPlannerProps> = withPlanner((props) => {
  const {
    form,
    name,
    label,
    disabled = false,
    processed = false,
    noOptionsText = 'Нет доступных вариантов',
    planner,
    placeholder,
    optionIcon,
    fetch,
  } = props

  const { control } = form

  const [loading, setLoading] = useState<boolean>(false)
  const [inputValue, setInputValue] = useState<string | undefined>()
  const [options, setOptions] = useState<AutocompleteOption[]>([])

  const handleFetch = async () => {
    if (inputValue && inputValue?.length > 2) {
      setLoading(true)
      const data = await fetch(inputValue)
      setLoading(false)
      setOptions(data)
    }
  }

  useEffect(() => {
    if (planner?.clearTimeouts())
      planner?.timeout(() => handleFetch(), inputValue && inputValue?.indexOf(' д ') > 5 ? 0 : 500)
  }, [inputValue])

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={[]}
      render={({ field: { ref, ...field }, fieldState: { error } }) => (
        <Autocomplete
          {...field}
          disabled={disabled}
          disableListWrap
          disabledItemsFocusable
          loading={loading || processed}
          noOptionsText={noOptionsText}
          loadingText="Загрузка..."
          popupIcon={<ExpandMoreRounded />}
          getOptionLabel={(option) => option?.title ?? ''}
          id={`${name}-id`}
          onChange={(_event, val) => {
            field.onChange(val)
          }}
          getOptionSelected={(option, val) => option.id === val.id}
          filterOptions={(ops, _state) => {
            const newOptions: AutocompleteOption[] = []
            ops.forEach((element) => {
              if (element.title.toLowerCase().includes(_state.inputValue.toLowerCase()) || _state.inputValue === '')
                newOptions.push(element)
            })
            return newOptions
          }}
          options={options}
          renderOption={({ title, key }: AutocompleteOption) => (
            <Grid container wrap="nowrap" alignItems="center" key={`${title}_${key}`}>
              {optionIcon && (
                <Grid item style={{ fontSize: 18 }}>
                  {optionIcon}
                </Grid>
              )}

              <Grid item>
                <Typography variant="body1" style={{ color: 'inherit', lineHeight: 1.2, paddingLeft: '0.6em' }}>
                  {title}
                </Typography>
              </Grid>
            </Grid>
          )}
          renderInput={(params) => (
            <TextField
              color="primary"
              variant="filled"
              placeholder={placeholder}
              label={label}
              error={!!error}
              helperText={error?.message}
              name={name}
              type="text"
              onChange={(e) => {
                setInputValue(e.target.value)
              }}
              inputRef={ref}
              {...params}
              InputLabelProps={{
                shrink: true,
              }}
            />
          )}
        />
      )}
    />
  )
})
