import jsQR from 'jsqr'
import { useEffect, useState, useRef } from 'react'
import { Box, Button, Collapse } from '@material-ui/core'
import { withPlanner, WithPlannerProps } from 'components/hoc'
import { Preloader } from 'components'
import { ScanFunction } from '../types'
import useStyles from '../style'
import locale from '../locale'

const videoConstraints: MediaStreamConstraints = { audio: false, video: { facingMode: 'environment' } }

type UploadReceiptCameraProps = { show: boolean; onExit(): void; handleScan: ScanFunction }

const UploadReceiptCamera: React.FC<UploadReceiptCameraProps & WithPlannerProps> = withPlanner((props) => {
  const { show, planner, onExit, handleScan } = props
  const DEBUG = process.env.REACT_APP_DEBUG === 'true'
  const classes = useStyles()

  const frame = useRef<number>()
  const stream = useRef<MediaStream>()
  const video = useRef<HTMLVideoElement>(null)
  const canvas = useRef<HTMLCanvasElement>(null)
  const canvasCtx = useRef<CanvasRenderingContext2D | null>(null)

  const [played, setPlayed] = useState(false)
  const [loadingCamera, setLoadingCamera] = useState(true)

  const disableStream = () => {
    if (DEBUG) console.log('_disableStream')
    if (frame.current) cancelAnimationFrame(frame.current)
    stream.current?.getTracks().forEach((track) => track.stop())
    if (played) {
      video.current?.pause()
      setPlayed(false)
    }
  }

  const tick = () => {
    const { current: videoC } = video
    const { current: canvasC } = canvas
    const { current: ctx } = canvasCtx
    if (videoC && canvasC && ctx && videoC.readyState === videoC.HAVE_ENOUGH_DATA) {
      ctx.save()
      ctx.clearRect(0, 0, videoC.videoWidth, videoC.videoHeight)
      ctx.drawImage(videoC, 0, 0, videoC.videoWidth, videoC.videoHeight)
      ctx.restore()

      const imageData = ctx.getImageData(0, 0, videoC.videoWidth, videoC.videoHeight)
      const code = jsQR(imageData.data, imageData.width, imageData.height)
      if (code) handleScan(code.data, canvasC?.toDataURL('image/jpeg'))
    }
  }

  const enableStream = async () => {
    try {
      const { current: videoC } = video
      const { current: canvasC } = canvas
      stream.current = await navigator.mediaDevices.getUserMedia(videoConstraints)

      if (videoC && canvasC) {
        videoC.srcObject = stream.current
        videoC.setAttribute('playsinline', 'true')
        if (!played) {
          await videoC.play()
          setPlayed(true)
        }
        setLoadingCamera(false)

        videoC.width = videoC.videoWidth
        videoC.height = videoC.videoHeight
        canvasC.width = videoC.width
        canvasC.height = videoC.height

        canvasCtx.current = canvasC.getContext('2d')

        planner?.interval(() => {
          frame.current = requestAnimationFrame(tick)
        }, 700)

        if (DEBUG) console.log('_enableStream')
      }
    } catch (err) {
      console.error('_enableStreamError:', err)
      onExit()
    }
  }

  useEffect(() => {
    let mounted = true
    if (show && mounted) {
      if (!loadingCamera) setLoadingCamera(true)
      enableStream()
    }
    return () => {
      mounted = false
      disableStream()
    }
  }, [show])

  return (
    <Collapse in={show}>
      <Box className={`animated ${show ? 'zoomIn' : 'zoomOut'} faster`}>
        <Box mb={2} fontSize={18}>
          {locale.camera.title}
        </Box>

        <Box className={classes.cameraView}>
          {loadingCamera && (
            <Box className={classes.preloader}>
              <Preloader />
            </Box>
          )}

          <video ref={video} />
          <canvas style={{ display: 'none' }} ref={canvas} />
        </Box>

        <Box pt={3} pb={2}>
          <Box>{locale.camera.helperLabel}</Box>
        </Box>

        <Button variant="contained" color="secondary" onClick={onExit}>
          {locale.camera.toDropzoneButtonLabel}
        </Button>
      </Box>
    </Collapse>
  )
})

export default UploadReceiptCamera
