import { InputHTMLAttributes, useState } from 'react'
import { RegisterOptions, UseFormReturn } from 'react-hook-form'
import { Box, IconButton, InputAdornment, makeStyles } from '@material-ui/core'
import { HighlightOff } from '@material-ui/icons'
import { GenericTextInput } from './_generic-input'

type Props = {
  form: UseFormReturn<any>
  name: string
  label?: string
  disabled?: boolean
  placeholder?: string
  options?: RegisterOptions
  inputProps?: InputHTMLAttributes<HTMLInputElement>
  acceptType?: string[]
  onChange?(target: any): void
  onClear?(): void
}

const useStyles = makeStyles(() => ({
  root: {
    zIndex: 9,
    display: 'flex',
    alignItems: 'center',
    position: 'relative',

    '& input[type="file"]': {
      cursor: 'pointer',
      opacity: 0,
      zIndex: 10,
    },
  },

  placeholder: {
    position: 'absolute',
    width: 'calc(100% - 50px)',
    textAlign: 'left',
    left: 10,
    top: 13,
    fontSize: 17,
    fontWeight: 400,
    pointerEvents: 'none',
  },
}))

export const FileInput: React.FC<Props> = ({
  form,
  name,
  label = 'Файл',
  placeholder = 'Выбрать файл',
  disabled = false,
  acceptType = ['image/png', 'image/jpeg', 'image/jpg'],
  options,
  inputProps,
  onChange,
  onClear,
}) => {
  const classes = useStyles()

  const { register, clearErrors, setValue, formState }: UseFormReturn = form

  const [file, setFile] = useState<File>()

  const handleClear = () => {
    setFile(undefined)
    setValue(name, undefined)
    if (onClear) onClear()
  }

  const handleChange = (e: any) => {
    setFile(e.target.files[0])
    if (e.target?.files && e.target.files?.[0]) setValue(name, e.target.files)
    clearErrors(name)
    if (onChange) onChange(e)
  }

  return (
    <Box className={classes.root}>
      <GenericTextInput
        register={register(name, { required: true, ...options })}
        InputProps={{
          inputProps: { accept: acceptType.join(', '), multiple: false, style: { height: 48 }, ...inputProps },
          endAdornment: file ? (
            <InputAdornment position="end">
              <IconButton color="primary" style={{ fontSize: '166%' }} onClick={handleClear}>
                <HighlightOff fontSize="inherit" />
              </IconButton>
            </InputAdornment>
          ) : null,
          onChange: handleChange,
        }}
        disabled={disabled}
        label={label}
        type="file"
        helperText={formState?.errors[name]?.message}
      />

      <Box className={`${classes.placeholder} nowrap_txt`}>
        {file ? `${file?.name} (${Math.floor(file?.size / 1000)} Кб)` : <Box color="#A2A7AB">{placeholder}</Box>}
      </Box>
    </Box>
  )
}
