import { useEffect, useState } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Fade,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import useReactRouter from "use-react-router";
import { scroller } from "react-scroll";
import { useReduxDispatch, useReduxSelector } from "hooks";
import { Redirect } from "react-router-dom";
import { withPlanner, WithPlannerProps } from "components/hoc";
import { useStyles } from "./promo.style";
import { getImage } from "./assets";
import RulesSection from "./sections/rules";
import PrizesSection from "./sections/prizes";
import WinnersSection from "./sections/winners";
import { propsAction } from "../../store/props";
import { Message } from "../../types";

const PromoPage: React.FC<WithPlannerProps> = withPlanner(({ planner }) => {
  const classes = useStyles();
  const { history } = useReactRouter();
  const { isAuth } = useReduxSelector((state) => state.auth);
  const { promoAviable } = useReduxSelector((state) => state.props);

  const lg = useMediaQuery(({ breakpoints: { down } }: Theme) => down("md"));
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down("sm"));
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down("xs"));
  const dispatch = useReduxDispatch();
  const [pvar, setPvar] = useState<number>(0);

  const handleClick = () => {
    if (isAuth) history.push({ pathname: "profile", hash:"action" });
    else history.push({ search: "signin" });
  };

  useEffect(() => {
    if (history.location.hash === "#contest_rules") {
      planner?.timeout(() => {
        scroller.scrollTo("contest_rules", {
          ignoreCancelEvents: true,
          activeClass: "active",
          duration: 700,
          hashSpy: true,
          smooth: true,
          offset: 0,
          spy: true,
        });
        history.push({ hash: "" });
      }, 150);
    }
  }, [history.location]);

  // useEffect(() => {
  //   dispatch(
  //       propsAction.alert({
  //         title: "Уважаемые участники!",
  //         message: {
  //           info: [
  //             // eslint-disable-next-line max-len
  //             `Регистрация чеков для участия в Акции завершена 19 мая 2024 года.`,
  //           ],
  //         },
  //
  //       })
  //   );
  // }, []);


  if (typeof promoAviable === "undefined")
    return (
      <Box my={4} textAlign="center">
        <CircularProgress />
      </Box>
    );

  if (promoAviable === false) {
    return <Redirect to={{ pathname: "/" }} />;
  }

  return (
    <Fade in timeout={350}>
      <Box style={{paddingBottom:'0!important'}} className={classes.root}>
        <Box className={classes.top}>
          {!sm ? (
            <img src={getImage?.["bg.png"]} style={{ width: "100%" }} alt="" />
          ) : (
            <Box>
              <Typography
                variant="h2"
                style={{
                  paddingTop: 60,
                  paddingBottom: 20,
                  fontSize: "4vw",
                  fontWeight: 900,
                  textTransform: "uppercase",
                  color: "#fff",
                }}
              >
                Исполняйте желания
                <br /> своих питомцев вместе с
              </Typography>

              <Box textAlign={"center"} style={{ paddingBottom: "10vw" }}>
                <img
                  src={getImage?.["logo_w.svg"]}
                  style={{ maxWidth: "100%", margin: "0 auto" }}
                  alt=""
                />
              </Box>
              <Box textAlign={"center"} style={{ paddingBottom: "10vw" }}>
                <Typography
                  variant="h2"
                  style={{
                    fontSize: "6vw",
                    fontWeight: 700,
                    color: "#fff",
                  }}
                >
                  Принимайте участие в игре <br />и получайте призы
                </Typography>
              </Box>

              <Box textAlign={"center"} style={{ paddingBottom: "20px" }}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleClick}
                  style={{ minWidth: "200px" }}
                >
                  Играть
                </Button>
              </Box>
              <Box textAlign={"center"} style={{ paddingBottom: "25vw" }}>
                <Typography
                  variant="h2"
                  style={{
                    marginTop: 0,
                    fontSize: "3vw",
                    fontWeight: 600,
                    textTransform: "uppercase",
                    color: "#fff",
                  }}
                >
                  Срок участия в Акции с 05.11.24 по 16.12.24
                </Typography>
              </Box>
            </Box>
          )}
        </Box>

        <Box className={classes.body}>
          {/*<Suspense fallback="">*/}
          <RulesSection />
          {/*<GallerySection />*/}
          <PrizesSection />
          <Box>
            <img
              src={getImage?.["bg-bottom-wave.png"]}
              style={{ width: "100%", marginBottom: -2 }}
              alt=""
            />
          </Box>

          <WinnersSection />
          {/*</Suspense>*/}
        </Box>
      </Box>
    </Fade>
  );
});

export default PromoPage;
