import { useEffect, useState } from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { Box, Grid, Theme, useMediaQuery, Fade, Button, Collapse, Hidden } from '@material-ui/core'
import { DateInput, NumberInput, TimeInput, formLocale } from 'components/form-control'
import { SubmitButton, Snackbar } from 'components'
import { FormProps } from 'types'
import { str } from 'utils'
import { QrCheckProps } from '../types'
import locale from '../locale'
import { schemas } from './validation'

interface Props {
  show: boolean
  defaultValues?: QrCheckProps
  onSubmit(data: QrCheckProps): void
  onExit(): void
  processed: boolean
}

const UploadReceiptForm: React.FC<Props> = (props) => {
  const { show, onSubmit: handleSubmit, defaultValues, processed, onExit } = props
  const { notValid } = formLocale

  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xs'))

  const maxSteps = 2
  const [step, setStep] = useState<number>(1)
  const [formProps, setFormProps] = useState<FormProps<any>>({
    data: undefined,
    processed: false,
    snackbar: {
      onClose: () => setFormProps({ ...formProps, snackbar: { ...formProps.snackbar, message: undefined } }),
    },
  })

  const hookForm = useForm<any>({
    defaultValues,
    resolver: yupResolver(sm ? schemas[step - 1] : schemas[2]),
  })
  const { reset } = hookForm

  const onSubmit = (data: any) => {
    setFormProps({ ...formProps, snackbar: { ...formProps.snackbar, message: undefined } })
    if (step < maxSteps && sm) setStep((s) => s + 1)
    else setFormProps({ ...formProps, data, processed: true })
  }

  const submitFailed = (err: { [x: string]: string[] }) => {
    console.log(err)
    // TrackEvent({
    //   eventCategory: 'Registration',
    //   eventAction: 'send_r_form',
    //   eventLabel: 'unsuccess',
    //   eventContext: `Некоторые поля не заполнены или заполнены неверно: ${Object.entries(err)
    //     .map(([key]) => key)
    //     .join(', ')}`,
    // })
    setFormProps({ ...formProps, processed: false, snackbar: { ...formProps.snackbar, message: notValid } })
  }

  useEffect(() => {
    if (!show) reset()
  }, [show])

  useEffect(() => {
    if (typeof formProps?.data !== 'undefined') handleSubmit({ ...defaultValues, ...formProps.data })
  }, [formProps])

  return (
    <Collapse in={show}>
      <Box mt={3} className={`animated ${show ? 'zoomIn' : 'zoomOut'} faster`}>
        <Box mb={{ xs: 3, sm: 7 }} fontSize={17}>
          {str.normalize(locale.form.prevLabel)}{' '}
          <Box component="a" whiteSpace="nowrap" onClick={onExit}>
            {locale.form.toDropzoneLinkLabel}
          </Box>{' '}
          {str.normalize(locale.form.nextLabel)}
        </Box>

        <form onSubmit={hookForm.handleSubmit(onSubmit, submitFailed)} noValidate>
          <Box mx="auto">
            <Grid container direction="column">
              <Grid item xs={12}>
                <Grid container spacing={2} justifyContent="center">
                  {sm && (
                    <Grid item xs={12}>
                      <Box pb={4} fontSize={15} fontWeight={500} textAlign="right" color="#8C9297">
                        Шаг {step} из {maxSteps}
                      </Box>
                    </Grid>
                  )}

                  {(step === 1 || !sm) && (
                    <Fade in>
                      <Grid item xs={12} sm={6}>
                        <DateInput
                          name="check_date"
                          label="Дата чека"
                          form={hookForm}
                          min={new Date('2021-01-01')}
                          disabled={processed}
                        />

                        <TimeInput name="check_time" label="Время чека" form={hookForm} disabled={processed} />

                        <NumberInput
                          name="check_summa"
                          label="Сумма чека"
                          placeholder="Пример: 200.00 ₽"
                          form={hookForm}
                          disabled={processed}
                          InputProps={{ inputProps: { inputMode: 'decimal' } }}
                        />
                      </Grid>
                    </Fade>
                  )}

                  {(step === 2 || !sm) && (
                    <Fade in>
                      <Grid item xs={12} sm={6}>
                        <NumberInput name="check_fd" label="ФД" form={hookForm} disabled={processed} />

                        <NumberInput name="check_fn" label="ФН" form={hookForm} disabled={processed} />

                        <NumberInput name="check_fpd" label="ФПД" form={hookForm} disabled={processed} />
                      </Grid>
                    </Fade>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <Snackbar {...formProps.snackbar} />
              </Grid>

              <Grid item xs={12}>
                <Box pt={2}>
                  <Grid container justifyContent={sm ? 'space-between' : 'center'}>
                    <Hidden smUp>
                      <Grid item xs={6}>
                        {step > 1 && (
                          <Button
                            fullWidth
                            variant="contained"
                            onClick={() => {
                              if (step > 1) setStep(step - 1)
                            }}
                          >
                            Назад
                          </Button>
                        )}
                      </Grid>
                    </Hidden>

                    <Grid item xs={6}>
                      <SubmitButton
                        color="secondary"
                        variant="contained"
                        fullWidth
                        disabled={processed}
                        processed={processed}
                        title={step < maxSteps && sm ? 'Далее' : 'Зарегистрировать'}
                      />
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </form>
      </Box>
    </Collapse>
  )
}

export default UploadReceiptForm
