import { AUTH, FETCH_AUTH_FAILURE, FETCH_AUTH_SUCCESS, FETCH_AUTH_REQUEST, AuthProps } from './types'

const initialState = {
  data: undefined,
  isAuth: false,
  loading: true,
  error: undefined,
}

export const authReducer = (state: AuthProps = initialState, action: AUTH): AuthProps => {
  const { data } = state
  const { type, payload } = action

  switch (type) {
    case FETCH_AUTH_REQUEST:
      return { ...state, loading: true, isAuth: false }
    case FETCH_AUTH_SUCCESS:
      return { data: payload?.data, loading: false, error: undefined, isAuth: true }
    case FETCH_AUTH_FAILURE:
      return { data, loading: false, error: payload, isAuth: false }
    default:
      return state
  }
}
