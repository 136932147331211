import { User, Message } from 'types'

export const FETCH_AUTH_REQUEST = 'FETCH_AUTH_REQUEST'
export const FETCH_AUTH_SUCCESS = 'FETCH_AUTH_SUCCESS'
export const FETCH_AUTH_FAILURE = 'FETCH_AUTH_FAILURE'

export interface AuthProps {
  data?: User
  isAuth: boolean
  loading: boolean
  error?: Message[]
}

interface Request {
  type: typeof FETCH_AUTH_REQUEST
  payload: any
}
interface Success {
  type: typeof FETCH_AUTH_SUCCESS
  payload: any
}
interface Falture {
  type: typeof FETCH_AUTH_FAILURE
  payload: any
}

export type AUTH = Request | Success | Falture
