import { Grid } from '@material-ui/core'
import './style.css'

export const Preloader: React.FC = () => (
  <Grid container justifyContent="center" alignItems="center">
    <Grid item>
      <div className="blobs">
        <div className="blob-center" />
        <div className="blob" />
        <div className="blob" />
        <div className="blob" />
        <div className="blob" />
        <div className="blob" />
        <div className="blob" />
      </div>
    </Grid>
  </Grid>
)
