import { Fragment, useState } from "react";
import {
  Box,
  ClickAwayListener,
  Grid,
  Grow,
  Hidden,
  Link,
  Paper,
  Popper,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import useReactRouter from "use-react-router";
import { User } from "types/user";
import { str } from "utils";
import { MyLink } from "./menu/nav-link";
import { useStyles } from "./header.style";
import { useReduxSelector } from "../../../hooks";

export const PopperAuthBody: React.FC<{ user?: User; onClose(): void }> = ({
  user,
  onClose,
}) => {
  const classes = useStyles();
  const { history } = useReactRouter();
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down("sm"));

  const handleClickExit = () => {
    onClose();
    history.push("/signout");
  };

  return (
    <ClickAwayListener mouseEvent="onClick" onClickAway={onClose}>
      <Paper variant="outlined" square>
        <Box
          maxWidth={{ xs: 600, sm: 320, md: 300 }}
          fontSize={{ xs: 14, md: 16 }}
          boxSizing="border-box"
          p={{ xs: 2, sm: 3 }}
          mx="auto"
        >
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Box
                mb={1}
                overflow="hidden"
                color={md ? "primary.main" : "primary.contrastText"}
              >
                <Grid
                  container
                  alignItems="center"
                  wrap="nowrap"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Grid item xs={9}>
                    <Grid
                      onClick={() => {
                        onClose();
                        history.push("/profile/#main");
                      }}
                      alignItems="center"
                      wrap="nowrap"
                      spacing={1}
                      container
                    >
                      <Grid item>
                        {md ? (
                          <img
                            draggable={false}
                            src="/imgs/icon/user.svg"
                            alt=""
                          />
                        ) : (
                          <img
                            draggable={false}
                            src="/imgs/icon/user-w.svg"
                            alt=""
                          />
                        )}
                      </Grid>
                      <Grid item xs={9} md={11}>
                        <Box
                          style={{ textTransform: "uppercase" }}
                          textAlign="left"
                          className="nowrap_txt"
                          fontWeight={700}
                          color="inherit"
                          fontSize={14}
                        >
                          {user?.first_name ?? "Личный кабинет"}
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>

                  <Hidden mdUp>
                    <Grid item xs={3}>
                      <Box
                        className={classes.exitBtn}
                        onClick={handleClickExit}
                      >
                        Выход
                      </Box>
                    </Grid>
                  </Hidden>
                </Grid>
              </Box>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Hidden mdUp>
                  <Grid item>Тел.:</Grid>
                </Hidden>
                <Grid item>
                  <span title={str.format.phone(user?.phone ?? "")}>
                    {str.format.phone(user?.phone ?? "")}
                  </span>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Hidden mdUp>
                  <Grid item>E-mail:</Grid>
                </Hidden>
                <Grid item>
                  <span title={user?.email}>{user?.email}</span>
                </Grid>
              </Grid>
            </Grid>

            <Hidden smDown>
              <Grid item style={{ marginLeft: "auto" }}>
                <Box className={classes.exitBtn} onClick={handleClickExit}>
                  Выход
                </Box>
              </Grid>
            </Hidden>
          </Grid>
        </Box>
      </Paper>
    </ClickAwayListener>
  );
};

export const PopperAuth: React.FC<{ user?: User }> = ({ user }) => {
  const {
    location: { pathname },
  } = useReactRouter();
  const calsses = useStyles();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const xs = useMediaQuery(({ breakpoints: { up } }: Theme) => up("md"));
  // eslint-disable-next-line consistent-return
  const handleClickLk = (event: any) => {
    if (pathname.indexOf("/profile") >= 0) {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
      setOpen(!open);
      return false;
    }
  };
  const canBeOpen = open && Boolean(anchorEl);
  const id = canBeOpen ? "user-popper" : undefined;
  return (
    <Fragment>
      <Link component={MyLink} to="/profile#main" draggable={false}>
        <Box onClick={handleClickLk}>
          <Grid container alignItems="center" wrap="nowrap" spacing={1}>
            <Grid item>
              {xs && (
                <img
                  draggable={false}
                  src="/imgs/icon/user.svg"
                  alt=""
                  style={{
                    display: "block",
                    filter: pathname === "/contest1"
                      ? "brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(2%) hue-rotate(284deg) brightness(106%) contrast(100%)"
                      : "none",
                  }}
                />
              )}{" "}
            </Grid>
            <Grid item xs={10}>
              <Box
                className="nowrap_txt"
                maxWidth={160}
                color={pathname === "/profile" ? "primary.main" : "inherit"}
                style={{ color: pathname !== "/contest1" ? "inherit" : "#fff" }}
              >
                {user?.first_name || "Личный кабинет"}
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Link>

      <Popper
        id={id}
        open={open}
        anchorEl={anchorEl}
        transition
        placement="bottom-end"
        disablePortal
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={350}>
            <Box className={calsses.popperRoot}>
              <PopperAuthBody user={user} onClose={() => setOpen(false)} />
            </Box>
          </Grow>
        )}
      </Popper>
    </Fragment>
  );
};
