import { Box, Button } from '@material-ui/core'
import { Modal } from 'components'
import { propsAction } from 'store/props'
import { useReduxDispatch, useReduxSelector } from 'hooks'
import ChangePasswordForm from './change-password-form'

export const changePasswordModalName = 'ChangePasswordModal'

export const ChangePasswordModal: React.FC = () => {
  const dispatch = useReduxDispatch()
  const { modal } = useReduxSelector((state) => state.props)
  const { show = false } = modal[changePasswordModalName] || {}

  const handleClose = () => dispatch(propsAction.modal({ modal: { [changePasswordModalName]: { show: false } } }))

  return (
    <Modal open={show} maxWidth="sm" title="Смена пароля" name={changePasswordModalName} onCloseDialog={handleClose}>
      <ChangePasswordForm rootModal={changePasswordModalName} />

      <Box width="100%" maxWidth={260} mt={2} mx="auto">
        <Button fullWidth variant="contained" onClick={handleClose}>
          Отмена
        </Button>
      </Box>
    </Modal>
  )
}
