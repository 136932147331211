import React, { Fragment } from 'react'
import { Box } from '@material-ui/core'
import moment from 'moment'

type StartPageProps = { now?: string | null; start?: string | null }

export const StartPage: React.FC<StartPageProps> = ({ now, start }) => (
  <Fragment>
    {start && moment(start).isAfter(moment(now)) && (
      <Box textAlign="center" py={2} style={{ opacity: 0.5 }}>
        {/* {str.normalize(`Старт акции ${moment.unix(start).format('DD-MM-YYYY HH:mm:ss')}`)} */}
      </Box>
    )}
  </Fragment>
)

StartPage.defaultProps = {
  now: undefined,
  start: undefined,
}
