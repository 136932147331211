import {
    ApiAnswer,
    PromoGallery, PromoGame,
    ReceiptPromo,
    UploadPhotoForm,
    UploadVideoForm,
    UploadWorkForm,
} from "types";
import { apiFetch, withPrivateAPIToken } from "./config";

const toFormData = (props: any): FormData => {
  if (props) {
    const data = new FormData();
    Object.keys(props).forEach((key) => {
      if (typeof props[key] !== "undefined") {
        data.append(key, props[key]);
      }
    });
    return data;
  }
  return props;
};

export const promoUserInfo = async () =>
  withPrivateAPIToken({
    url: "/user",
    method: "get",
  });

export const finishGameApi = async (
    data: any,
    game_id: number
): Promise<ApiAnswer<PromoGame>> =>
    withPrivateAPIToken({
        url: `/game/store`,
        method: "post",
        data: toFormData({game_id: game_id, data: JSON.stringify(data)}),
    });

export const startGameApi = async (
  test: boolean
): Promise<ApiAnswer<PromoGame>> =>
  withPrivateAPIToken({
    url: `/game`,
    method: "post",
    data: toFormData({ test: test }),
  });

export const promoUserDocs = async (
  page?: number
): Promise<ApiAnswer<ReceiptPromo[]>> =>
  withPrivateAPIToken({
    url: `/user/check${page ? `/?page=${page}` : ""}`,
    method: "get",
  });

export const promoUserGallery = async (
  page?: number
): Promise<ApiAnswer<PromoGallery>> =>
  withPrivateAPIToken({
    url: `/user/gallery${page ? `/?page=${page}` : ""}`,
    method: "post",
    data: toFormData({ sort: "-date" }),
  });

export const promoGalleryAll = async (
  props?: {
    phone?: string;
    gallery_id?: number;
    sort?: "date" | "-date" | "like" | "-like";
  },
  page?: number
): Promise<ApiAnswer<PromoGallery>> =>
  withPrivateAPIToken({
    url: `/user/gallery/all${page ? `/?page=${page}` : ""}`,
    method: "post",
    data: toFormData(props),
  });

export const promoPublicGalleryAll = async (
  props?: {
    phone?: string;
    gallery_id?: number;
    sort?: "date" | "-date" | "like" | "-like";
  },
  page?: number
): Promise<ApiAnswer<PromoGallery>> =>
  apiFetch({
    url: `/public/gallery/all${page ? `/?page=${page}` : ""}`,
    method: "post",
    data: toFormData(props),
  });

export const promoGalleryVote = async (id: number): Promise<ApiAnswer<any>> =>
  withPrivateAPIToken({
    url: `/user/gallery/${id}/vote`,
    method: "post",
    data: toFormData({ id }),
  });

export const promoGalleryUnvote = async (id: number): Promise<ApiAnswer<any>> =>
  withPrivateAPIToken({
    url: `/user/gallery/${id}/unvote`,
    method: "post",
    data: toFormData({ id }),
  });

export const promoUserRegister = async (props: { rules: boolean }) =>
  withPrivateAPIToken({
    url: "/user",
    method: "post",
    data: toFormData(props),
  });

export const getWinners = async (props: { phone?: string }) =>
  withPrivateAPIToken({
    url: "/winners",
    method: "post",
    data: toFormData(props),
  });

export const promoUploadPhoto = async (props: UploadPhotoForm) =>
  withPrivateAPIToken({
    url: "/user/photo",
    method: "post",
    data: toFormData(props),
  });

export const promoWorkPhoto = async (props: UploadWorkForm) =>
  withPrivateAPIToken({
    url: "/user/gallery/register",
    method: "post",
    data: props,
  });

export const promoContestLoad = async (props: { type: "cat" | "dog" }) =>
  withPrivateAPIToken({
    url: `/public/question/${props.type}`,
    method: "get",
    data: props,
  });

export const promoContestStore = async (props: { answers: number[] }) =>
  withPrivateAPIToken({
    url: "/user/poll/store",
    method: "post",
    data: props,
  });

export const promoUploadVideo = async (props: UploadVideoForm) =>
  withPrivateAPIToken({
    url: "/user/video",
    method: "post",
    data: toFormData(props),
  });

export const promoGet = async (url: string, params?: any) =>
  apiFetch({
    url: `/${url}`,
    method: "get",
    params,
  });
