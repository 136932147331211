import React, { forwardRef, useEffect, ReactElement, Ref } from 'react'
import {
  DialogProps,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grow,
  useMediaQuery,
  Theme,
} from '@material-ui/core'
import { TransitionProps } from '@material-ui/core/transitions'
import { useReduxSelector } from 'hooks'
import { str } from 'utils'
import CloseButton from './close-button'

export interface Params {
  processed?: boolean
  store?: boolean
  open?: boolean
  name?: string
  closeButton?: boolean
  onCloseDialog?: () => void
  dialogContentClassName?: string
  onOpen?: () => void
}

const Transition: React.ForwardRefExoticComponent<any> = forwardRef(
  (props: TransitionProps & { children: ReactElement<any, any> }, ref: Ref<unknown>) => <Grow ref={ref} {...props} />
)

export const Modal: React.FC<Params & DialogProps> = (params) => {
  const {
    processed = false,
    onOpen = () => {},
    store = false,
    name = 'default',
    closeButton = true,
    onCloseDialog = () => {},
    dialogContentClassName = '',
    open,
    fullScreen = false,
    ...props
  } = params

  const dialogTitleId = `${name}-dialog-title`
  const { dialog } = useReduxSelector((state) => state.props)
  const xs = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xs'))

  useEffect(() => {
    if (!!dialog?.[name]?.open || open) onOpen()
  }, [dialog, name, onOpen, open])

  return (
    <Dialog
      TransitionComponent={Transition}
      aria-labelledby={dialogTitleId}
      fullScreen={fullScreen || xs}
      onClose={(_, reason) => {
        if (reason !== 'backdropClick') onCloseDialog()
      }}
      scroll="body"
      open={open}
      {...(store ? dialog?.[name] : {})}
      {...props}
      title={undefined}
    >
      <DialogTitle id={dialogTitleId} disableTypography>
        {!!props.title && <Box flex="1 1 auto">{str.normalize(props.title, true)}</Box>}
      </DialogTitle>

      {closeButton && (
        <CloseButton
          close={() => {
            onCloseDialog()
          }}
          disabled={processed}
        />
      )}

      <DialogContent className={dialogContentClassName}>{props.children}</DialogContent>

      <DialogActions />
    </Dialog>
  )
}
