import MaskedInput from 'react-text-mask'

export const PhoneMask: React.FC<{ inputRef: any }> = ({ inputRef, ...other }) => {
  return (
    <MaskedInput
      {...other}
      ref={(ref: any) => {
        inputRef(ref ? ref.inputElement : null)
      }}
      // prettier-ignore
      mask={["+", "7", " ", "(", /[9]/, /\d/, /\d/, ")", " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/]}
      guide={false}
    />
  )
}
