import { Box, IconButton, Grid, makeStyles, useMediaQuery, Theme } from '@material-ui/core'
import { faFacebookF as FB, faOdnoklassniki as OK, faVk as VK } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'
import { str, ga, CATEGORY, ACTION } from 'utils'

type Props = {
  label?: string
  userProvider?: any
  disabled?: boolean
  eventAction: ACTION
  eventCategory: CATEGORY
}

const useStyles = makeStyles(({ breakpoints: { down }, spacing, palette }: Theme) => ({
  iconButton: {
    width: 32,
    height: 32,
    padding: 0,
    fontSize: 18,
    color: palette.text.hint,
    borderRadius: spacing(1 / 2),
    backgroundColor: palette.primary.main,

    '& span': { width: 'auto' },
    '& svg': { display: 'block' },
    '&:hover, &:disabled': { backgroundColor: palette.action.hover },
    // '&.ok, &.vk, &.fb': { backgroundColor: palette.primary.main },
  },

  label: {
    textAlign: 'left',
    fontSize: 18,
    fontWeight: 500,
    [down('sm')]: { textAlign: 'left' },
  },
}))

export const SocialAuth: React.FC<Props> = (props) => {
  const classes = useStyles()
  const sm = useMediaQuery(({ breakpoints: { up } }: Theme) => up('sm'))
  const { label = '', userProvider = {}, eventAction, eventCategory, disabled } = props

  const { REACT_APP_API_URL } = process.env
  const getUrl = (name: string): string =>
    `${REACT_APP_API_URL?.replace(/\/api/gi, '')}/social/signin/${name.replace(/^\/+/, '')}`

  const onClickSocialAuth = (name: string) => {
    ga.event({ cat: eventCategory, action: eventAction, label: name })
    window.location.href = getUrl(name)
  }

  return (
    <Box py={2}>
      <Grid
        container
        spacing={2}
        alignItems="center"
        wrap={sm ? 'nowrap' : 'wrap'}
        justifyContent={sm ? 'space-between' : 'flex-start'}
      >
        <Grid item className={classes.label}>
          {str.normalize(label)}
        </Grid>

        <Grid item>
          <Grid container spacing={1} justifyContent={sm ? 'flex-end' : 'center'} wrap="nowrap">
            {/* Vkontakte */}
            <Grid item>
              <IconButton
                type="submit"
                color="primary"
                className={`${classes.iconButton} vk`}
                disabled={disabled || userProvider?.vkontakte}
                onClick={() => onClickSocialAuth('vkontakte_owner')}
              >
                <span className="fa-fw fa-1x">
                  <Icon icon={VK} size="1x" />
                </span>
              </IconButton>
            </Grid>

            {/* Facebook */}
            {/* <Grid item>
              <IconButton
                type="submit"
                color="primary"
                className={`${classes.iconButton} fb`}
                disabled={disabled || userProvider?.facebook}
                onClick={() => onClickSocialAuth('facebook_owner')}
              >
                <span className="fa-fw fa-1x">
                  <Icon icon={FB} size="1x" />
                </span>
              </IconButton>
            </Grid> */}

            {/* Odnoklassniki */}
            {/* <Grid item>
              <IconButton
                type="submit"
                color="primary"
                disabled={disabled || userProvider?.odnoklassniki}
                className={`${classes.iconButton} ok`}
                onClick={() => onClickSocialAuth('odnoklassniki_owner')}
              >
                <span className="fa-fw fa-1x">
                  <Icon icon={OK} size="1x" />
                </span>
              </IconButton>
            </Grid> */}
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
