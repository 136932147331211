import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box } from '@material-ui/core'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import { addPhone } from 'api'
import { SubmitButton } from 'components'
import { PhoneInput, validation } from 'components/form-control'
import { ApiAnswerStatus, FormProps } from 'types'
import { authAction } from 'store/auth'

const schema = validation({
  phone: yup
    .string()
    .required()
    .matches(/^\d{10}$/i, { message: 'Указан некорректный номер телефона' }),
})

type PhoneFormProps = { phone: string }
type Props = { onExit(): void }

const TransferPhoneForm: React.FC<Props> = ({ onExit }) => {
  const dispatch = useDispatch()
  const { SUCCESS } = ApiAnswerStatus

  const [formProps, setFormProps] = useState<FormProps<PhoneFormProps>>({
    data: { phone: '' },
    processed: false,
  })

  const hookForm = useForm<any>({ resolver: yupResolver(schema) })

  const onSubmit = async (props: PhoneFormProps) => {
    setFormProps({ ...formProps, processed: true })
    const { status } = await addPhone(props)
    // console.log(data)

    if (status === SUCCESS) {
      setFormProps({ ...formProps, processed: false })
      dispatch(authAction.auth())
      onExit()
    } else setFormProps({ ...formProps, processed: false })
  }

  return (
    <Box maxWidth={390} mx="auto">
      <form onSubmit={hookForm.handleSubmit(onSubmit)}>
        <PhoneInput name="phone" label="Номер телефона" form={hookForm} />

        <Box mt={1} mx="auto" maxWidth={260}>
          <SubmitButton
            fullWidth
            title="Добавить"
            color="secondary"
            variant="contained"
            disabled={formProps.processed}
            processed={formProps.processed}
          />
        </Box>
      </form>
    </Box>
  )
}

export default TransferPhoneForm
