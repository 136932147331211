import {
  Box,
  Container,
  Grid,
  makeStyles,
  Theme,
  Typography, useMediaQuery,
} from "@material-ui/core";
import { getImage } from "../assets";

export const useStyles = makeStyles(
  ({ palette, breakpoints: { down, up }, spacing }: Theme) => ({
    prizes: {
      borderRadius: "30px",
      height: "100%",

      "& .description": {
        color: "#323E48",
        fontSize: 20,
        fontWeight: "600",
        textTransform: "uppercase",
        wordWrap: "break-word",
        marginBottom: spacing(3),
        [down("md")]: {
          fontSize: 16,
        },
        [down("sm")]: {
          fontSize: 14,
        },
      },

      "& .name": {
        color: "#323E48",
        fontSize: 40,
        fontWeight: "700",
        textTransform: "uppercase",
        wordWrap: "break-word",
        [down("md")]: {
          fontSize: 30,
        },
        [down("sm")]: {
          fontSize: 17,
        },
      },
      "& .pr":{
        padding:"40px",
        [down("md")]: {
          padding:"30px",
        },
        [down("sm")]: {
          padding:"20px",
        },
      },

      "& span": {
        background: "#fff",
        color: "#323E48",
        fontWeight: "600",
        padding: "5px 15px",
        margin: 10,
        fontSize: 32,
        display: "inline-block",
        borderRadius: "35px",
        [down("md")]: {
          fontSize: 20,
          margin: 5,
          padding: "3px 10px",
        },
        [down("sm")]: {
          margin: 2,
          fontSize: 14,
        },
      },
    },
  })
);

const PrizesSection: React.FC = () => {
  const classes = useStyles();
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down("xs"));
  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down("sm"));

  return (
    <Box py={{ xs: 5, sm: 10, md: 12 }}>
      <Container maxWidth="xl">
        <Box fontSize={{ xs: 24, md: 45 }} pb={1} pt={5}>
          <Typography
            variant="h2"
            style={{
              marginTop: 0,
              fontSize: "inherit",
              textTransform: "uppercase",
            }}
          >
            Призовой фонд
          </Typography>
        </Box>
        <Box fontSize={{ xs: 20, sm: 20, md: 36 }}>
          <Grid container
                alignItems={"stretch"}
                spacing={2}
             >
            <Grid item xs={12} sm={7} md={8}>

                <Grid
                  container
                  direction={"column"}
                  wrap={"nowrap"}
                  alignItems={"stretch"}
                  alignContent={"stretch"}
                  spacing={2}
                  style={{position: "relative", height: "100%"}}
                >
                  <Grid item style={{ flex: "1 1 auto" }}>
                    <Box
                      className={classes.prizes}
                      style={{
                        background: `url(${
                          getImage?.["a2.png"] ?? ""
                        }) center right no-repeat #C0D6ED`,
                        backgroundSize: "cover",
                      }}
                    >
                      <Grid container className={"pr"}>
                        <Grid item xs={7} sm={7} md={7}>
                          <Box className={"description"}>
                            Призы, которые могут выпасть в&nbsp;игре
                          </Box>
                          <Box className={"name"}>Сертификаты OZON</Box>
                          <Box>
                            <span>300₽</span>
                            <span>500₽</span>
                            <span>1000₽</span>
                          </Box>
                        </Grid>
                        <Grid item xs={5} sm={5} md={5}>
                          <img
                            src={getImage?.["p1.png"]}
                            alt={"Сертификаты OZON"}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                  <Grid item style={{ flex: "1 1 auto" }}>
                    <Box
                      className={classes.prizes}
                      style={{
                        background: `url(${
                          getImage?.["a.png"] ?? ""
                        }) top right no-repeat, linear-gradient(277deg, #FFD490 0%, rgba(255, 212, 144, 0.53) 100%)`,
                        backgroundSize: "contain",
                      }}
                    >
                      <Grid container className={"pr"}>
                        <Grid item xs={7}>
                          <Box className={"description"}>Еженедельный приз</Box>
                          <Box className={"name"}>
                            Запас корма<br/> на&nbsp;1&nbsp;месяц
                          </Box>
                        </Grid>
                        <Grid item xs={5}>
                          <img
                              style={{maxWidth: "90%"}}
                            src={getImage?.["p2.png"]}
                            alt={"Запас корма на 1 месяц"}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
            </Grid>
            <Grid
              item
              xs={12}
              sm={5}
              md={4}
              style={{
              }}
            >
              <Box
                className={classes.prizes}
                style={{ background: "#FFF6EA"}}
              >
                <Grid
                  direction={sm?"row": "column"}
                  container
                  wrap={"nowrap"}
                  justifyContent={"space-between"}
                  className={"pr"}
                  style={{ height: "100%"}}
                >
                  <Grid item xs={7} sm={12}>
                    <Box className={"description"}>Главный приз</Box>
                    <Box className={"name"}>Запас корма на&nbsp;3&nbsp;месяца</Box>
                  </Grid>
                  <Grid item xs={5} sm={12}>
                    <img
                      src={getImage?.["p3.png"]}
                      alt={"Запас корма на 3 месяца"}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default PrizesSection;
