import { useEffect, useRef, useState } from "react";
import {
  Box,
  CircularProgress,
  Fade,
  Grid,
  makeStyles,
  styled,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { getMetaContent, Modal } from "components";
import { propsAction } from "store/props";
import { useReduxDispatch, useReduxSelector } from "hooks";
import { PromoUserGalleryItem } from "types";
import { useLocation } from "react-router-dom";
import { str } from "utils";
import { VKShareButton } from "react-share";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faVk } from "@fortawesome/free-brands-svg-icons";

const useStyles = makeStyles(
  ({ spacing, palette, breakpoints: { down } }: Theme) => ({
    root: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      minHeight: "20vh",
      position: "relative",
      backgroundColor: "#0C0D0E",
      "& video, & img": {
        display: "block",
        width: "100%",
        height: "auto",
      },
      "& img": { transform: "scale(1.003)" },
    },

    videoPlayer: {
      position: "relative",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      boxSizing: "border-box",
      boxShadow: "0px 10px 20px rgb(0 0 0 / 25%)",
      marginTop: spacing(2),
      marginBottom: spacing(3 / 2),
      maxWidth: 796,
      marginLeft: "auto",
      marginRight: "auto",
      borderRadius: spacing(1),
      overflow: "hidden",
      [down("xs")]: { borderRadius: 0 },

      "& video": {
        display: "block",
        width: "100%",
        height: "auto",
        maxHeight: 450,
        boxSizing: "border-box",
        background: "#ECEBED",
        borderRadius: spacing(1),
        border: `3px solid ${palette.secondary.light}`,
        [down("xs")]: { border: "none", borderRadius: 0 },
      },
    },

    button: {
      outline: "none",
      cursor: "pointer",
      position: "absolute",
      fontSize: "4rem",
      color: "#fff",
      top: "36%",
      padding: spacing(3),
      borderRadius: "70px",
      backgroundColor: "rgb(0,14,26,0.2)",
      border: "1px solid #fff",
      backdropFilter: "blur(3px)",
      "& svg": { display: "block" },
      [down("xs")]: { padding: spacing(1), top: "24%" },
    },

    likeButton: {
      transition: "color 0.15s ease-in-out",
      "&:hover": {
        color: palette.error.main,
      },
    },
  })
);

export const CssModal = styled(Modal)(() => ({
  "& .MuiDialog-paper": {
    boxShadow: "0 0 2em rgba(0,0,0,0.7)",
    "& .MuiDialogTitle-root": { padding: 0 },
    "& .MuiIconButton-root": { zIndex: 2, backgroundColor: "#F5ECE5" },
    "& .MuiDialogContent-root": {
      maxWidth: "initial",
      padding: 0,
      overflow: "hidden",
    },
    "& .MuiDialogActions-root": { padding: 0 },
  },
}));

export const mediaViewerModalName = "MediaViewerModal";

type MediaViewerModalParams = PromoUserGalleryItem & {
  vote?(id: number, index: number, success?: () => void): Promise<void>;
  unvote?(id: number, index: number, success?: () => void): Promise<void>;
  showNoAuthAlert?(rootModal?: string): void;
  hideVote?: boolean;
  index: number;
};

export const MediaViewerModal: React.FC = () => {
  const classes = useStyles();

  const dispatch = useReduxDispatch();
  const { pathname } = useLocation();

  const { isAuth } = useReduxSelector((state) => state.auth);
  const { modal } = useReduxSelector((state) => state.props);
  const { show = false, params } = modal[mediaViewerModalName] || {};

  const {
    id,
    content,
    gallery_votes_count,
    is_vote,
    vote_access,
    vote,
    unvote,
    index,
    showNoAuthAlert,
    hideVote = false,
  } = (params as MediaViewerModalParams) || {};

  const { middle_src_link, pet_name, src_link, source, status } = content || {};

  const videoFef = useRef<HTMLVideoElement | null>(null);
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down("xs"));

  const [photoLoaded, setPhotoLoaded] = useState(false);
  const [voteProcessig, setVoteProcessing] = useState(false);
  const [localVoted, setLocalVoted] = useState(false);
  const [localVoteCount, setLocalVoteCount] = useState(0);

  const handleClose = () => {
    if (videoFef?.current) videoFef.current?.pause();
    setPhotoLoaded(false);
    dispatch(
      propsAction.modal({ modal: { [mediaViewerModalName]: { show: false } } })
    );
  };

  useEffect(() => {
    if (show) {
      setLocalVoted(Boolean(is_vote));
      setLocalVoteCount(gallery_votes_count ?? 0);
    }
  }, [show]);

  return (
    <CssModal
      open={show}
      maxWidth="md"
      title=""
      name={mediaViewerModalName}
      onCloseDialog={handleClose}
    >
      {show && (
        <>
          <Box className={classes.root}>
            {!photoLoaded && (
              <Box position="absolute" zIndex={0} color="#fff">
                <CircularProgress color="inherit" />
              </Box>
            )}

            {source && source.length > 0 && (
              <video
                ref={videoFef}
                controls
                playsInline
                autoPlay
                width="100%"
                onPlay={() => setPhotoLoaded(true)}
                height="auto"
                preload="metadata"
                muted
                poster={middle_src_link ?? ""}
              >
                {source.map(({ src, type }, i) => (
                  <source
                    key={`video_view_${i}`}
                    src={src ?? ""}
                    type={type ?? ""}
                  />
                ))}
              </video>
            )}

            {src_link && !source && (
              <Fade in={photoLoaded} timeout={400}>
                <img
                  src={src_link ?? ""}
                  onLoad={() => setPhotoLoaded(true)}
                  height="auto"
                  alt={pet_name ?? ""}
                />
              </Fade>
            )}
          </Box>

          <Box
            p={{ xs: 2, sm: 3 }}
            width="100%"
            bgcolor="#fff"
            boxSizing="border-box"
            boxShadow="inset 0 2px 0.3em rgba(0,0,0,0.3)"
          >
            <Grid container alignItems="center" wrap="nowrap">
              <Grid item>
                <Box fontWeight={600} fontSize={{ xs: 14, md: 24 }}>
                  <Box className="nowrap_txt" maxWidth={230} lineHeight={1.1}>
                    {str.format.firstToUpper(pet_name ?? "")}
                  </Box>
                </Box>
              </Grid>

              <Grid item>
                <Box
                  fontWeight={500}
                  color="#C1C3C5"
                  pl={1}
                  lineHeight={{ xs: 1.1, md: 1.3 }}
                  fontSize={{ xs: 14, md: 18 }}
                >
                  №&nbsp;{id}
                </Box>
              </Grid>

              {pathname === "/contest" ? (
                <Grid item style={{ marginLeft: "auto" }}>
                  {/*{hideVote || (isAuth && !vote_access && !is_vote) ? (*/}
                  {/*  <></>*/}
                  {/*) : (*/}
                  {/*  <Box*/}
                  {/*    fontSize={{ xs: 16, sm: 22 }}*/}
                  {/*    style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}*/}
                  {/*    color={localVoted ? 'error.main' : 'initial'}*/}
                  {/*  >*/}
                  {/*    <span style={{ fontSize: sm ? 14 : 16, marginRight: 2, marginLeft: 2, fontWeight: 400 }}>*/}
                  {/*      {localVoteCount}*/}
                  {/*    </span>*/}

                  {/*    <IconButton*/}
                  {/*      color="inherit"*/}
                  {/*      disabled*/}
                  {/*      style={{ padding: 3, backgroundColor: 'transparent' }}*/}
                  {/*      onClick={() => {*/}
                  {/*        // if (isAuth) {*/}
                  {/*        //   if (id && !voteProcessig) {*/}
                  {/*        //     if (!localVoted && vote) {*/}
                  {/*        //       setVoteProcessing(true)*/}
                  {/*        //       vote(id, index, () => {*/}
                  {/*        //         setLocalVoted(true)*/}
                  {/*        //         setLocalVoteCount((c) => c + 1)*/}
                  {/*        //         setVoteProcessing(false)*/}
                  {/*        //       })*/}
                  {/*        //     }*/}
                  {/*        //     if (localVoted && unvote) {*/}
                  {/*        //       setVoteProcessing(true)*/}
                  {/*        //       unvote(id, index, () => {*/}
                  {/*        //         setLocalVoted(false)*/}
                  {/*        //         setLocalVoteCount((c) => c - 1)*/}
                  {/*        //         setVoteProcessing(false)*/}
                  {/*        //       })*/}
                  {/*        //     }*/}
                  {/*        //   }*/}
                  {/*        // } else if (showNoAuthAlert) showNoAuthAlert(mediaViewerModalName)*/}
                  {/*      }}*/}
                  {/*      className={classes.likeButton}*/}
                  {/*    >*/}
                  {/*      {localVoted ? (*/}
                  {/*        <Favorite color="inherit" fontSize={sm ? 'small' : 'medium'} />*/}
                  {/*      ) : (*/}
                  {/*        <FavoriteBorder color="inherit" fontSize={sm ? 'small' : 'medium'} />*/}
                  {/*      )}*/}
                  {/*    </IconButton>*/}
                  {/*  </Box>*/}
                  {/*)}*/}
                </Grid>
              ) : (
                <Grid item style={{ marginLeft: "auto" }}>
                  {status?.slug === "confirm" && (
                    <Box
                      fontSize={24}
                      color="#DAD0CB"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        flexWrap: "nowrap",
                      }}
                    >
                      {/*<VKShareButton*/}
                      {/*  url={`${getMetaContent("og:url")}/contest`}*/}
                      {/*  image={middle_src_link ?? ""}*/}
                      {/*  // eslint-disable-next-line max-len*/}
                      {/*  title="Я участвую в конкурсе «Стильная осень с AlphaPet»! Переходи по ссылке ниже и голосуй за моего питомца 😺"*/}
                      {/*  // title={`${str.format.firstToUpper(pet_name ?? '')} (№ ${id})`}*/}
                      {/*  // beforeOnClick={() => clickShareButton({ eventLabel: 'vkontakte' })}*/}
                      {/*>*/}
                      {/*  <FontAwesomeIcon icon={faVk} size="1x" />*/}
                      {/*</VKShareButton>*/}
                    </Box>
                  )}
                </Grid>
              )}
            </Grid>

            <Box
              textAlign={"left"}
              fontWeight={400}
              p={1}
              lineHeight={{ xs: 1.1 }}
              fontSize={{ xs: 14, md: 18 }}
            >
              {content?.history}
            </Box>
          </Box>
        </>
      )}
    </CssModal>
  );
};
