import { Box, Button, Grid } from '@material-ui/core'
import { Modal } from 'components'
import { useReduxSelector, useReduxDispatch } from 'hooks'
import { propsAction } from 'store/props'
import { str } from 'utils'
import { giftPetModalName } from '../gift-pet'

export const readyGiftModalName = 'ReadyGiftNameModal'

export const ReadyGiftModal: React.FC = () => {
  const dispatch = useReduxDispatch()
  const { modal } = useReduxSelector((state) => state.props)
  const { show = false } = modal[readyGiftModalName] || {}

  const handleClose = () => dispatch(propsAction.modal({ modal: { [readyGiftModalName]: { show: false } } }))

  const handleOpenGetGift = () => {
    handleClose()
    dispatch(propsAction.modal({ modal: { [giftPetModalName]: { show: true } } }))
  }

  return (
    <Modal open={show} maxWidth="sm" title="Информация" name={readyGiftModalName} onCloseDialog={handleClose}>
      <Box>
        <Box>
          {str.normalize(
            `Спасибо. Ваши данные приняты.
						Вы можете получить подарок для питомца.`,
            true
          )}
        </Box>

        <Box mt={4}>
          <Grid container spacing={2} justifyContent="center">
            <Grid item>
              <Box minWidth={200} mx="auto">
                <Button onClick={handleClose} fullWidth variant="outlined" color="primary">
                  Позже
                </Button>
              </Box>
            </Grid>

            <Grid item>
              <Box minWidth={200} mx="auto">
                <Button onClick={handleOpenGetGift} fullWidth variant="contained" color="secondary">
                  Получить сейчас
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Modal>
  )
}
