import { Typography, Box } from '@material-ui/core'

type Prop = {
  children: React.ReactNode
  index: number
  value: number
  className?: any
}

export const TabPanel: React.FC<Prop> = (props) => {
  const { children, value, index, ...other } = props

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box width={{ xs: 'calc(100% + 32px)', sm: 'auto' }} ml={{ xs: '-16px', sm: 0 }}>
          {children}
        </Box>
      )}
    </Box>
  )
}
