import { useState } from 'react'
import { UseFormReturn } from 'react-hook-form'
import { VisibilityOffOutlined, VisibilityOutlined } from '@material-ui/icons'
import { IconButton, InputAdornment } from '@material-ui/core'
import { passwordInputProps } from './props-autocomplete'
import { GenericTextInput } from './_generic-input'

type Props = {
  form: UseFormReturn
  name: string
  disabled?: boolean
  label?: string
}

export const PasswordInput: React.FC<Props> = ({ form, name, disabled = false, label = 'Пароль' }) => {
  const { register, formState } = form
  const [showPassword, setShowPassword] = useState<boolean>(false)

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }
  const handleMouseDownPassword = (event: any) => {
    event.preventDefault()
  }

  return (
    <GenericTextInput
      register={register(name, { required: true, maxLength: 6, minLength: 6, pattern: /^\d{6}$/i })}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              color="secondary"
              style={{ fontSize: '120%' }}
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              aria-label="toggle password visibility"
            >
              {showPassword ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
            </IconButton>
          </InputAdornment>
        ),
        inputProps: { ...(passwordInputProps as any) },
      }}
      label={label}
      placeholder="  -  -  -  -"
      disabled={disabled}
      type={showPassword ? 'text' : 'password'}
      helperText={formState.errors[name]?.message}
    />
  )
}
