import { FC, ReactNode } from 'react'
import { Box, makeStyles, Theme } from '@material-ui/core'
import { Header } from './header'
import { Footer } from './footer'

const useStyles = makeStyles(({ breakpoints: { down } }: Theme) => ({
  root: {
    minWidth:370,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    minHeight: '100vh',
  },

  content: {
    width: '100%',
    marginTop: 94,
    flex: '1 0 auto',
    position: 'relative',
    [down('sm')]: { marginTop: 56 },
  },

  footer: {
    zIndex: 1000,
    width: '100%',
    flex: '0 0 auto',
    overflow: 'hidden',
  },
}))

export const PageContainer: FC<{ children: ReactNode }> = ({ children }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Header />
      <Box className={classes.content}>{children}</Box>
      <Box className={classes.footer}>
        <Footer />
      </Box>
    </Box>
  )
}
