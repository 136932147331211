import React, { PropsWithChildren } from 'react'
import { Box, Popover as PopoverDefault } from '@material-ui/core'

export const Popover: React.FC<
  PropsWithChildren<{
    anchorEl?: Element | ((element: Element) => Element) | HTMLElement | null | undefined
    onClose?(): void
  }>
> = ({ anchorEl, onClose, children }) => (
  <PopoverDefault
    disableRestoreFocus
    id="mouse-over-popover"
    open={!!anchorEl}
    anchorEl={anchorEl}
    onClose={onClose}
    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    transformOrigin={{ vertical: 'top', horizontal: 'right' }}
  >
    <Box p={2}>{children}</Box>
  </PopoverDefault>
)
