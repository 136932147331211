import { Redirect, Route, RouteProps } from 'react-router-dom'
import { Box } from '@material-ui/core'
import { useReduxSelector } from 'hooks'
import { Preloader } from '../preloader'

type Props = {
  component: any
  key?: any
  rest?: any
  path: string
  exact?: boolean
}

export const PrivateRoute: React.FC<RouteProps & Props> = ({ component: Component, ...rest }) => {
  const { loading, isAuth } = useReduxSelector((state) => state.auth)

  switch (true) {
    case loading:
      return (
        <Box py={20}>
          <Preloader />
        </Box>
      )

    case !isAuth:
      return <Redirect to={{ pathname: '/', search: '?signin' }} />

    default:
      return <Route {...rest} render={(props) => <Component {...props} />} />
  }
}
