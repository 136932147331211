import { Box, Checkbox, FormControl, FormControlLabel, FormHelperText } from '@material-ui/core'
import { Controller, UseFormReturn } from 'react-hook-form'
import { ChangeEvent, ReactElement } from 'react'
import { ReactComponent as Check } from './icons/check.svg'
import { ReactComponent as Cheked } from './icons/cheked.svg'

type Props = {
  form: UseFormReturn<any>
  name: string
  label: string | ReactElement
  disabled?: boolean
  required?: boolean
  onChange?(e: ChangeEvent<HTMLInputElement>, checked: boolean): void
}

export const CheckboxInput: React.FC<Props> = ({
  form,
  name,
  label,
  required = false,
  disabled = false,
  onChange: change,
}) => {
  const { control, formState } = form

  return (
    <Box mb={3 / 2}>
      <FormControl>
        <FormControlLabel
          control={
            <Controller
              control={control}
              name={name}
              rules={{ required }}
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <Checkbox
                  onBlur={onBlur}
                  onChange={(e, c) => {
                    onChange(e, c)
                    if (change) change(e, c)
                  }}
                  checked={value}
                  inputRef={ref}
                  disabled={disabled}
                  checkedIcon={<Cheked />}
                  icon={<Check />}
                />
              )}
            />
          }
          label={label}
        />

        <FormHelperText style={{ bottom: -12 }}>{formState.errors[name]?.message}</FormHelperText>
      </FormControl>
    </Box>
  )
}
