import {
  Box, Button,
  Container,
  Grid,
  Theme,
  Typography,
  useMediaQuery,
} from "@material-ui/core";
import { str } from "utils";
import { useReduxSelector } from "hooks";
import {getImage} from "../assets";

const RulesSection: React.FC = () => {
  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down("xs"));

  const { promoRulesSrc = "#" } = useReduxSelector((s) => s.props);

  const rules = [
    {
      src: getImage?.["r1.png"] ?? "",
      title: "Покупайте корм ТМ&nbsp;AlphaPet®",
      description: "",
    },
    {
      src: getImage?.["r2.png"] ?? "",
      title: "Регистрируйте чеки на сайте",
      description: "",
    },
    {
      src: getImage?.["r3.png"] ?? "",
      title:
        "Играйте в увлекательную игру",
      description: "",
    },
    {
      src: getImage?.["r4.png"] ?? "",
      title: "Участвуйте в розыгрышах ценных призов",
      description: "",
    },
  ];

  return (
    <Box
      py={{ xs: 5, sm: 8 }}
      id="rules"
      style={{ background: `url(${getImage?.["bg_rules.png"]})` }}
    >
      <Container maxWidth="xl">
        <Box fontSize={{ xs: 24, md: 45 }}>
          <Typography
            variant="h2"
            style={{
              marginTop: 0,
              fontSize: "inherit",
              textTransform: "uppercase",
            }}
          >
            Правила
          </Typography>
        </Box>

        <Grid container spacing={3} justifyContent={"space-around"}>
          {rules.map(({ src, title, description }, idx) => (
            <Grid key={`rule-${idx}`} item xs={10} sm={5} md={5} lg={3}>
              <Grid container justifyContent={sm ? "flex-start" : "center"}>
                <Grid item xs={12}>
                  <Box mb={2} mx="auto" maxWidth="90%" textAlign="center">
                    <img
                      draggable={false}
                      src={src}
                      alt=""
                      height="auto"
                      style={{ maxWidth: 250, width: "100%", margin: "0 auto" }}
                    />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Box position="relative" mx="auto" maxWidth="90%">

                    <Box
                      position="relative"
                      pb={{ xs: 0.5, sm: 1 }}
                      pl={{ xs: 2, sm: 3.5, md: 4, lg: 4.5 }}
                      zIndex={2}
                      fontSize={{ xs: 16, sm: 18, md: 25 }}
                      fontWeight={600}
                      textAlign={'center'}
                    >
                      {str.normalize(title)}
                    </Box>
                    <Box fontWeight={400} fontSize={14} textAlign={"center"}>
                      {str.normalize(description)}
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>
        <Grid item xs={12}>
          <Box pt={3} fontWeight={600} fontSize={'0.9em'}>*Не участвуют фасовки 7,5 и 18 кг., а также линейка кормов AlphaPet® MENU.</Box>
        </Grid>
      </Container>


    </Box>
  );
};

export default RulesSection;
