import { RegisterOptions, UseFormReturn } from 'react-hook-form'
import { emailInputProps } from './props-autocomplete'
import { GenericTextInput } from './_generic-input'

type Props = {
  form: UseFormReturn<any>
  name: string
  disabled?: boolean
  options?: RegisterOptions
}

export const EmailInput: React.FC<Props> = ({ name, form, disabled = false, options }) => {
  const { register, formState } = form

  return (
    <GenericTextInput
      register={register(name, {
        required: true,
        // pattern: /^\s*([\w.%+-]+)@([\w-]+\.)+([\w]{2,})\s*$/i,
        ...options,
      })}
      placeholder="Пример: example@domain.com"
      disabled={disabled}
      label="E-mail"
      type="email"
      InputProps={{ inputProps: { ...(emailInputProps as any) } }}
      helperText={formState.errors[name]?.message}
    />
  )
}
