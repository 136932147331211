export const formLocale = {
  succsess: {},

  badRequest: {},

  serverError: { error: ['Сервер временно недоступен. Пожалуйста, попробуйте позже.'] },

  captchaError: { error: ['Ошибка капчи. Пожалуйста, попробуйте позже.'] },

  notValid: {
    warning: [
      `Внимание! Одно или несколько полей не заполнены или заполнены неверно.
		 	<br/>Проверьте корректность заполнения полей.`,
    ],
  },

  signin: {
    invalidCredentials: { error: ['Неверный логин или пароль.'] },
  },

  rulesCheckbox: (
    <>
      Я прочитал и&nbsp;согласен с&nbsp;
      <a
        href={`${process.env.REACT_APP_API_URL?.replace(/\/api$/, '')}/doc/owner/agreement`}
        rel="noreferrer"
        target="_blank"
      >
        Пользовательским соглашением
      </a>
      , на&nbsp;обработку персональных данных и&nbsp;
      <a
        href={`${process.env.REACT_APP_API_URL?.replace(/\/api$/, '')}/doc/owner/rules`}
        rel="noreferrer"
        target="_blank"
      >
        Правилами Программы&nbsp;лояльности
      </a>
    </>
  ),
  subscribeSmsCheckbox: 'Я согласен на смс рассылку',
  subscribeEmailCheckbox: 'Я согласен на E-mail рассылку',
}
