export default {
  modalTitle: 'Регистрация чека',

  btnMain: 'Зарегистрировать чек',

  dropzone: {
    mainLabel: 'Загрузите или перетяните сюда фото чека.<br/> На фото должен быть чётко виден QR-код и данные чека.',
  },

  camera: {
    title: 'Наведи камеру на QR-код',
    helperLabel: 'QR-код не считывается?',
    toDropzoneButtonLabel: 'Загрузить фото чека',
  },

  form: {
    prevLabel: 'К сожалению, информация на чеке не была&nbsp;распознана.<br/>Загрузите, пожалуйста,',
    toDropzoneLinkLabel: 'другую фотографию чека',
    nextLabel: 'или введите данные чека вручную.',
  },
}
