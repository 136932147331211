import { createTheme, Theme } from '@material-ui/core'
import palette from './palette'
import font from './font'

const {
  spacing,
  breakpoints: { down, up, values },
} = createTheme()

export const theme: Theme = createTheme({
  breakpoints: { values: { ...values, md: 980, xl: 1680 } },
  palette,
  font,

  typography: {
    fontSize: 17,
    fontFamily: font.primary,

    h1: {
      fontSize: 50,
      lineHeight: 1.4,
      fontWeight: 900,
      textTransform: 'uppercase',
      [down('sm')]: { fontSize: 23 },
    },

    h2: {
      fontSize: 42,
      lineHeight: 1.2,
      fontWeight: 900,
      textAlign: 'center',
      marginTop: spacing(6),
      marginBottom: spacing(6),
      textTransform: 'uppercase',
      [down('xs')]: {
        fontSize: 17,
        textTransform: 'initial',
        marginTop: spacing(4),
        marginBottom: spacing(4),
      },
    },
    h3: {
      fontSize: 28,
      fontWeight: 900,
      [down('xs')]: { fontSize: 18, textTransform: '', textAlign: 'center' },
    },
    h4: {
      fontSize: 32,
      fontWeight: 900,
      textTransform: 'uppercase',
      [down('xs')]: { fontSize: 16 },
    },
    h5: {
      fontSize: 20,
      fontWeight: 500,
      [down('xs')]: { fontSize: 15 },
    },
    body1: {
      fontSize: 14,
      color: palette.text?.primary,
      // [down('md')]: {
      //   fontSize: 14,
      //   textTransform: '',
      //   textAlign: 'center',
      // },
    },

    body2: {
      fontSize: 17,
      color: palette.text?.primary,
      fontWeight: 500,
    },

    subtitle1: {
      color: palette.text?.secondary,
      fontWeight: 500,
      fontSize: 14,
      lineHeight: 1.12,
    },

    subtitle2: {
      textTransform: 'uppercase',
      fontWeight: 600,
      fontSize: 18,
      marginBottom: 10,
    },
  },

  overrides: {
    MuiCssBaseline: {
      '@global': {
        html: {
          WebkitFontSmoothing: 'auto',
        },
      },
    },
    MuiIconButton: {
      root: {
        color: palette.primary.main,
        lineHeight: 1,
        '&.popoverButton': {
          color: palette.text?.secondary,
          border: '1px solid #fff',
          borderRadius: spacing(2),
        },
      },
      edgeEnd: { marginRight: -spacing(1 / 2) },
      label: {},
      colorSecondary: {
        '& span': {
          color: palette.text?.secondary,
        },
        '&.popoverButton': {
          backgroundColor: '#cf0a2c',
          border: '0 none',
        },
      },
    },
    MuiBackdrop: {
      root: {
        // [down('sm')]: { top: 56 },
        backdropFilter: 'blur(1px)',
        [down('xs')]: { backdropFilter: 'none' },
      },
    },
    MuiPickersDay: {
      hidden: {
        opacity: 0.8,
        '& span': {
          fontWeight: 400,
          color: palette.text.disabled,
        },
      },
      daySelected: {
        '& span': {
          color: '#fff',
        },
      },
      dayDisabled: {
        '& span': {
          color: palette.text.disabled,
        },
      },
    },

    MuiAccordion: {
      root: {
        backgroundColor: palette.background.default,
        borderRadius: 0,
        boxShadow: 'none',
        margin: '0!important',
      },
    },
    MuiAccordionSummary: {
      root: {
        padding: 0,
        borderRadius: 0,
        borderBottom: '1px solid #E3DDDC',
        minHeight: '48px!important',
        '&$expanded': {
          margin: 0,
        },
      },
      content: {
        margin: '0!important',
      },
    },
    MuiDialog: {
      paper: {
        width: '100%',
        backgroundColor: '#fff',
        borderRadius: '0px',
        color: '#323E48',
        boxSizing: 'border-box',
        boxShadow: 'inset 0 0 15px rgba(0,0,0,.1)',
      },
      paperWidthSm: { maxWidth: 550 },
      paperFullScreen: {
        height: 'auto',
      },
    },
    MuiDialogContent: {
      root: {
        overflowY: 'initial',
        paddingLeft: spacing(6),
        paddingRight: spacing(6),
        textAlign: 'center',
        fontWeight: 500,
        fontSize: 18,

        [down('sm')]: {
          paddingLeft: spacing(2),
          paddingRight: spacing(2),
        },

        '& .text': {
          color: '#323E48',
        },

        '& .alert_msg': { marginBlockStart: 0 },

        '& .link': {
          fontSize: '100%',
          color: '#323E48',
          textDecoration: 'none',
          '&:hover': {
            color: '#323E48',
          },
        },

        '& .fields': {
          textAlign: 'center',
          color: palette.text?.secondary,
          backgroundColor: 'transparent',
          paddingTop: spacing(2),
          '& fieldset': {
            // borderColor: '#f4d48c!important',
          },

          [down('sm')]: {},
          [down('xs')]: {
            // paddingTop: spacing(4),
          },

          '& .field_row>*': {
            [up('md')]: {
              '&:first-child:not(:last-child)': {
                paddingRight: spacing(1),
              },
              '&:last-child:not(:first-child)': {
                paddingLeft: spacing(1),
              },
            },
          },
          '&.fields-bottom': {
            // костыль
            borderTopLeftRadius: 0,
            borderTopRightRadius: 0,
          },
          '&.fields-top:first-child:not(:last-child)': {
            // костыль
            borderBottomLeftRadius: 0,
            borderBottomRightRadius: 0,
            marginBottom: -1,
          },
        },
        '& .actions': {
          // width: "75%",
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        position: 'relative',
        backgroundColor: 'transparent',
        color: '#323E48',
        textAlign: 'center',
        fontSize: 32,
        textTransform: 'none',
        fontWeight: 900,
        paddingTop: spacing(5),
        paddingBottom: spacing(4),
        display: 'flex',
        justifyContent: 'center',

        [down('xs')]: {
          paddingBottom: spacing(2),
          fontSize: 25,
        },
      },
    },
    MuiDialogActions: {
      root: {
        paddingBottom: spacing(4),
        justifyContent: 'center',
        paddingLeft: spacing(8),
        paddingRight: spacing(8),
        [down('sm')]: {
          paddingLeft: spacing(3),
          paddingRight: spacing(3),
        },
        // '& button': {
        //   fontSize: 20,
        //   color: '#fff',
        //   background: '#526070',
        //   fontWeight: 400,
        //   '&:hover': {
        //     background: '#526070',
        //   },
        // },
      },
    },
    MuiContainer: {
      root: {
        maxWidth: 1680,
        margin: '0 auto',
      },
    },
    MuiPickersBasePicker: {
      pickerView: {
        minHeight: 325,
        margin: '0 auto',
        scrollbarColor: `${palette.primary.main} ${palette.background.default}`,
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': { width: 10, height: 10 },
        '&::-webkit-scrollbar-track': { backgroundColor: palette.background.default },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: palette.primary.main,
          border: `3px solid ${palette.background.default}`,
          '&:hover': { border: `3px solid ${palette.primary.main}` },
        },
      },
    },

    MuiPickersCalendarHeader: {
      switchHeader: { paddingLeft: '0.5rem', paddingRight: '0.5rem' },
      transitionContainer: { '& p': { textTransform: 'capitalize' } },
      dayLabel: {
        color: palette.text.primary,
      },
    },

    MuiPickersYearSelection: {
      container: {
        scrollbarColor: `${palette.primary.main} ${palette.background.default}`,
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': { width: 10, height: 10 },
        '&::-webkit-scrollbar-track': { backgroundColor: palette.background.default },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: palette.primary.main,
          border: `3px solid ${palette.background.default}`,
          '&:hover': { border: `3px solid ${palette.primary.main}` },
        },
      },
    },

    MuiPickersToolbar: {
      toolbar: { height: 85 },
    },

    MuiPickersToolbarButton: {
      toolbarBtn: {
        border: 'none',
        '&:hover': { border: 'none' },
        '& h2': {
          margin: 0,
          padding: '0 1rem',
        },
        '& h4': {
          fontWeight: 600,
        },
        '& h6': {
          fontSize: 16,
        },
      },
    },

    MuiButton: {
      root: {
        borderRadius: 0,
        zIndex: 1,
        textTransform: 'uppercase',
        fontWeight: 400,
        lineHeight: 'initial',
        // letterSpacing: '1px',
        '&:hover': {
          borderColor: '#fff',
          color: '#fff',
          textShadow: 'none',
        },
        '&:active,&.active': {},
        '&$disabled': {
          // opacity: '.9',
        },
      },

      outlinedPrimary: {
        borderRadius: 0,
        paddingLeft: 30,
        paddingRight: 30,
        color: '#AD7D66',
        height: 48,
        boxSizing: 'border-box',
        fontSize: 14,
        fontWeight: 600,
        border: '1px solid #AD7D66',

        '&:hover': {
          border: '1px solid #8E583E!important',
          background: '#8E583E!important',
          color: '#fff',
        },

        '&$disabled': {
          backgroundColor: '#F0EDEC',
          color: palette.primary.main,
          textTransform: 'initial',
          border: 'none',
        },

        [down('md')]: {
          height: 48,
          fontSize: 12,
          '&:after': {
            height: 48,
          },
        },
      },
      contained: {
        paddingLeft: 30,
        paddingRight: 30,
        color: '#8C9297',
        fontSize: 14,
        minHeight: 48,
        fontWeight: 600,
        backgroundColor: '#fff',
        boxSizing: 'border-box',
        boxShadow: 'none',
        '&:hover': {
          border: '0px solid #fff',
          color: '#AD7D66',
          background: '#fff!important',
          boxShadow: 'none',
        },
      },
      containedPrimary: {
        paddingLeft: 30,
        paddingRight: 84,
        paddingTop: spacing(5 / 2),
        paddingBottom: spacing(5 / 2),
        color: '#fff',
        fontSize: 14,
        fontWeight: 600,
        border: '0px solid #AD7D66',
        background: '#AD7D66',
        position: 'relative',

        '&:after': {
          display: 'block',
          position: 'absolute',
          right: 0,
          height: '100%',
          width: 62,
          borderLeft: '2px solid #D6BEB2',
          background: 'url("/imgs/icon/arrow.svg") no-repeat center transparent',
          color: '#D6BEB2',
          content: '""',
        },

        '& span': { lineHeight: 1 },

        '&:hover': {
          border: '0px solid #AD7D66',
          color: '#fff',
          background: '#8E583E!important',
          '&:after': {
            background: 'url("/imgs/icon/arrow.svg") no-repeat center transparent',
          },
        },

        '&$disabled': {
          opacity: '.8',
          color: '#fff',
          background: '#E1BD8C',
        },
        [down('sm')]: {
          paddingTop: spacing(1),
          paddingBottom: spacing(1),
          paddingRight: 68,
          fontSize: 12,
          '&:after': { width: 48 },
        },
      },
      containedSecondary: {
        paddingLeft: 30,
        paddingRight: 30,
        color: '#fff',
        fontSize: 14,
        height: 48,
        fontWeight: 600,
        // border: '1px solid #AD7D66',
        backgroundColor: '#AD7D66',

        '&:hover': {
          // border: '1px solid #8E583E',
          color: '#fff',
          background: '#8E583E!important',
        },

        '&$disabled': {
          opacity: '.8',
          color: '#fff',
          background: '#E1BD8C',
        },
      },

      text: {
        padding: 0,
        fontSize: 17,
        lineHeight: 1.3,
        fontWeight: 500,
        transition: 'color 0.15s ease-in-out',
        color: palette.secondary.light,
        borderBottom: `1px dashed ${palette.secondary.light}`,
        textTransform: 'initial',
        '&:hover': {
          color: palette.text.primary,
          borderColor: palette.text.primary,
          borderStyle: 'solid',
          backgroundColor: 'transparent',
        },
      },
    },
    MuiAppBar: {
      root: {
        width: 'auto',
      },
      colorPrimary: {
        backgroundColor: 'transparent',
      },
    },
    MuiSelect: {
      root: {
        '&$disabled': {
          color: palette.text.disabled,
        },
      },
      selectMenu: { height: '100%', minHeight: 46, textAlign: 'left', lineHeight: '46px' },
      iconFilled: {
        fontSize: '1.5rem',
        color: palette.text?.primary,
      },
    },
    MuiMenuItem: {
      root: {
        '&$selected': {
          // background: palette.secondary,
        },
        '&:hover': { color: palette.text.hint },
      },
      gutters: {
        // color: palette.primary,
      },
    },
    MuiSvgIcon: {
      root: {
        fontSize: '100%',
      },
    },
    MuiDrawer: {
      root: {
        marginTop: 56,
        '& .MuiBackdrop-root': { top: 56 },
      },

      paper: {
        height: 'calc(100% - 56px)',
        bottom: 0,
        top: 'initial',
        minWidth: 300,
        boxShadow: 'none',
      },
    },
    MuiTableBody: {
      root: {
        paddingTop: 22,
        textTransform: 'none',
        paddingBottom: 22,
        minHeight: 70,
        '& a': {
          fontSize: 14,
          display: 'block',
          color: palette.primary.main,
          textDecoration: 'none',
          borderBottom: `1px dotted ${palette.primary.main}`,
          '&:hover': { borderBottom: `1px solid ${palette.primary.main}` },
        },

        '&>tr:nth-child(even)': {
          '&>td': {
            backgroundColor: '#F8F6F5',
            color: '#323E48',
            [down('xs')]: { backgroundColor: '#fff' },
          },
        },
        [down('xs')]: {
          '&>tr:nth-child(odd)': {
            '&>td': {
              backgroundColor: '#F8F6F5',
            },
          },
        },
      },
    },
    MuiTableCell: {
      root: {
        width: '25%',
        border: 'none!important',
        textAlign: 'center',
        fontSize: '100%',
        fontWeight: 400,
        lineHeight: 1.25,
        minHeight: 70,
        paddingBottom: 18,
        paddingTop: 18,
        [down('xs')]: { width: 'initial' },
      },
      body: {
        color: '#323E48',
        fontSize: 16,
        fontWeight: 400,
      },
    },
    MuiTableRow: {},
    MuiTable: {
      root: { width: '100%' },
    },
    MuiTableHead: {
      root: {
        padding: 0,

        '& .MuiTableCell-root': {
          textAlign: 'center',
          backgroundColor: 'transparent',
          color: '#AD7D66',
          padding: '22px 10px',

          fontWeight: 600,
          textTransform: 'uppercase',
          lineHeight: 1,
          fontSize: 14,
          minHeight: 50,
          borderBottom: '1px solid rgba(173, 125, 102, 0.31)!important',
        },
      },
    },

    /* форма */
    MuiCheckbox: {
      root: {
        padding: spacing(1 / 2),
        '& span:hover': { boxShadow: `inset 0 0 0 2px ${palette.primary.main}` },
      },
      colorPrimary: {
        color: palette.text?.secondary,
        '&$checked': {
          color: palette.text?.secondary,
        },
        '&$disabled': {
          color: 'rgba(255, 255, 255, 0.5)',
        },
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: '#FAFAFA',
        color: palette.text?.primary,
        flexWrap: 'nowrap',
        fontSize: 12,
        borderRadius: 0,
        alignItems: 'center',
        lineHeight: 1.25,
        textAlign: 'center',
        boxShadow: '0 0 1em rgba(0,0,0,0.1)',
        '& p': { margin: 0 },
      },
      message: {
        flex: '0 0 100%',
        padding: 0,
        fontWeight: 400,
      },
      action: {},
    },
    MuiFormHelperText: {
      root: {
        left: 0,
        bottom: -17,
        position: 'absolute',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        width: '100%',
        color: palette.error.main,
        whiteSpace: 'nowrap',
        lineHeight: 1.25,
        fontSize: 11,

        '&$error': {
          // color: '#fff',
        },

        '&.checkbox-help': {
          bottom: 0,
          position: 'relative',
        },
      },
      contained: {
        marginLeft: 0,
        marginRight: 0,
      },
    },
    MuiFormControl: {
      root: {
        marginTop: 0,
        maxWidth: '100%',
        '& label': {
          fontWeight: 400,
        },
      },
    },
    MuiTextField: {
      root: {
        marginBottom: spacing(5),
        // fontSize: 16,
        // fontWeight: 400,
        // minHeight: 48,
        // color: '#323E48',
        // border: `1px solid #DAD0CB`,
        // borderRadius: '0px!important',
        // background: '#F4F4F4!important',
        // '&:before, &:after': {
        //   content: 'none!important',
        // },
        // '&:hover': {
        //   backgroundColor: '#fff',
        // },
        // '&$error': {
        //   color: 'red',
        //   fontWeight: 600,
        //   borderColor: '#FD2121',
        // },
        // '&$focused': {
        //   backgroundColor: '#fff',
        // },
      },
    },

    MuiInputAdornment: {
      root: {
        // '& button': { padding: spacing(1) },
      },
      positionEnd: {
        marginLeft: 0,
      },
    },

    MuiPagination: {
      root: { display: 'inline-block' },
    },

    MuiPaginationItem: {
      root: {
        fontSize: 14,
        fontWeight: 500,
        minWidth: 25,
        width: 25,
        height: 25,
        color: '#323E48',
        borderRadius: '50%',
        '&$selected': {
          color: palette.primary.main,
          borderRadius: '50%',
          textDecoration: 'none',
          background: 'none transparent !important',
          '&$textSecondary': {
            color: palette.text?.secondary,
            // backgroundColor: '#fff',
          },
          '&$textPrimary': {
            color: palette.text?.secondary,
            // backgroundColor: '#fff',
          },
        },
        '&$disabled': {
          // visibility: 'hidden',
        },
        '&$textSecondary': {
          color: '#fff',
        },
        '&:hover': {
          color: palette.text.hint,
          background: `${palette.primary.main} !important`,
        },
      },
      outlined: {
        // backgroundColor: '#ffffff',
        border: 'none',
        // color: palette?.primary,
        fontSize: '133%',
        height: 40,
        width: 40,
        borderRadius: 10,
        [down('xs')]: {
          height: 29,
          width: 29,
        },
        '&$sizeSmall': {
          color: '#fff!important',
        },
        '&$disabled': {
          visibility: 'visibility',
        },
        '&$selected': {
          color: '#fff',
          textDecoration: 'none!important',
          backgroundColor: palette.primary,
          borderRadius: 10,
          '&$textSecondary': {
            color: palette.secondary,
          },
          '&$sizeSmall': {
            background: '#fff!important',
            color: `${palette.primary}!important`,
          },
        },
      },
      ellipsis: {
        color: 'initial',
      },
      icon: {
        fontSize: '150%',
      },
    },
    MuiOutlinedInput: {
      root: {
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        borderTopLeftRadius: 4,
        borderTopRightRadius: 4,
        borderColor: '#fff',
        borderWidth: 2,
        fontSize: '80%',
        color: '#fff',

        padding: 0,
        height: 40,
        maxHeight: 40,
        '&:hover $notchedOutline': {
          borderColor: '#fff',
          borderWidth: 2,
        },
        '&$focused $notchedOutline': {
          borderColor: '#fff',
          borderWidth: 2,
        },
        '&$error $notchedOutline': {
          borderColor: '#fff',
          borderWidth: 2,
        },
        '&$disabled $notchedOutline': {
          borderWidth: 2,
        },
      },
      multiline: {
        height: 'auto',
        minHeight: 60,
        maxHeight: 'none',
      },
      notchedOutline: {
        borderRadius: 5,
        borderColor: '#fff',
        borderWidth: 1,
        top: 0,
        '& legend': {
          display: 'none',
        },
      },
      input: {
        padding: '5px 15px',
        height: 'auto',
        lineHeight: 1,
        '&$disabled': {
          color: 'rgba(255, 255, 255, 0.5)',
        },
        '&:-internal-autofill-selected': {
          background: 'transparent',
          '-webkit-box-shadow': 'inset 0 0 0 50px #051016!important',
          '-webkit-text-fill-color': '#fff!important',
        },
        '&:-webkit-autofill': {
          background: 'transparent',
          '-webkit-box-shadow': 'inset 0 0 0 50px #051016!important',
          '-webkit-text-fill-color': '#fff!important',
        },
      },
      adornedEnd: {
        paddingRight: 0,
      },
    },
    MuiFilledInput: {
      root: {
        fontSize: 16,
        fontWeight: 400,
        minHeight: 48,
        color: '#323E48',
        border: `1px solid #DAD0CB`,
        borderRadius: '0px!important',
        background: '#F4F4F4!important',
        '&:before, &:after': {
          content: 'none!important',
        },
        '&:hover': {
          backgroundColor: '#fff',
        },
        // '&$error': {
        //   color: 'red',
        //   fontWeight: 600,
        //   borderColor: '#FD2121',
        // },
        '&$focused': {
          backgroundColor: '#fff',
        },
      },
      input: {
        fontWeight: 400,
        // minHeight: 46,
        padding: '0 10px',
        color: '#323E48',
        '&:-internal-autofill-selected': {
          fontSize: '16px !important',
          background: 'transparent',
          '-webkit-box-shadow': 'inset 0 0 0 50px #F4F4F4!important',
          '-webkit-text-fill-color': '#323E48!important',
        },
        '&:-webkit-autofill': {
          background: 'transparent',
          '-webkit-box-shadow': 'inset 0 0 0 50px #F4F4F4!important',
          '-webkit-text-fill-color': '#323E48!important',
        },
      },
      adornedEnd: {
        paddingRight: 0,
      },
    },
    MuiInputBase: {
      root: {
        // marginBottom: spacing(5),
        borderColor: '#fff!impoetant',
      },
      input: {
        '&$disabled': {
          color: palette.text.disabled,
        },
      },
      // input: {
      //   '&:-internal-autofill-selected': {
      //     background: 'transparent',
      //     '-webkit-box-shadow': 'inset 0 0 0 50px #fff!important',
      //     '-webkit-text-fill-color': '#001645!important',
      //   },
      //   '&:-webkit-autofill': {
      //     background: 'transparent',
      //     '-webkit-box-shadow': 'inset 0 0 0 50px #fff!important',
      //     '-webkit-text-fill-color': '#001645!important',
      //   },
      // },
    },
    MuiExpansionPanelSummary: {
      root: {
        paddingTop: 0,
        paddingBottom: 0,
        fontWeight: 600,
        fontSize: 20,
        paddingLeft: 20,
        color: '#fff',
        margin: 0,
        [down('sm')]: {
          fontSize: '16px',
        },
        '&$expanded': {
          color: '#fff',
          margin: 0,

          minHeight: 'none',
          borderColor: '#ffffff',
        },
      },
      content: {
        marginTop: 0,
        marginBottom: 0,
        '&$expanded': {
          marginTop: 0,
          marginBottom: 0,
        },
      },
      expandIcon: {
        marginTop: 10,
        marginBottom: 10,
        // color: '#ffffff',
        fontSize: 20,
        background: '#7B8AAD',
        padding: 5,
        order: 0,
        '&:hover': {
          backgroundColor: '#7B8AAD',
        },
      },
    },
    MuiExpansionPanel: {
      root: {
        background: 'transparent',
        border: '1px solid #40517A',
        marginBottom: spacing(2),
        '&:last-child': {
          borderBottom: 'none',
        },
        margin: 0,

        '&:hover': {
          border: '1px solid transparent',
          background: '#102659',
        },
        '&$expanded': {
          border: '1px solid transparent',
          background: '#102659',
          margin: 0,
          marginBottom: 10,
        },
      },
      rounded: {
        borderRadius: 10,
        '&:first-child, &:last-child': {
          borderRadius: 10,
        },
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        fontSize: '18px',
        lineHeight: 1.3,
        fontWeight: 400,
        color: '#fff',
        display: 'block',
        padding: '8px 20px 10px',
        marginBottom: spacing(1),
        [down('sm')]: {
          fontSize: '16px',
        },
        '& a': {
          // color: palette.secondary + '!important',
        },
      },
    },
    MuiInputLabel: {
      root: {
        transformOrigin: 'left center',
        zIndex: 2,
        '&$focused': {
          color: palette.text?.primary,
          transform: 'translate(0px, -20px) scale(.8)',
        },
        fontWeight: 400,
        paddingLeft: 0,
        paddingRight: 0,
        '&$error': {
          color: palette.error.main,
        },

        '&$disabled': {
          color: palette.text.disabled,
        },
      },
      formControl: {
        transform: 'translate(0px, 17px) scale(1)',
      },

      filled: {
        fontWeight: 400,
        fontSize: 17,
        transform: 'translate(12px, 17px)',
        '&$shrink': {
          textAlign: 'left',
          fontSize: 17,
          transform: 'translate(0px, -135%)',
          color: '#323E48',

          '&$disabled': {
            color: palette.text.disabled,
          },
        },
      },
    },
    MuiFormLabel: {
      root: {
        '&$disabled': {
          color: palette.text.disabled,
        },

        '&focused': {
          color: '#fff',
        },
      },
    },
    MuiFormControlLabel: {
      root: { alignItems: 'flex-start', marginLeft: -4 },
      label: {
        userSelect: 'none',
        textAlign: 'left',
        paddingTop: spacing(1 / 2),
        paddingLeft: spacing(3 / 2),
        transition: '0.15s color ease-in-out',
        '& a': { color: palette.text?.primary, '&:hover': { color: palette.action?.hover } },
      },
    },
    MuiAutocomplete: {
      inputRoot: { padding: '0 !important' },
      paper: { padding: 0, borderRadius: 0 },
      endAdornment: {
        right: '5px !important',
        top: 'calc(50% - 15px)',
        '& button': { fontSize: '1.5em', color: palette.text.primary },
      },
      input: {
        padding: '0 0 0 10px !important',
        width: 'calc(100% - 80px) !important',
        flexGrow: 'initial',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
      },

      option: {
        '&[data-focus="true"]': { backgroundColor: palette.primary.light },
        '&[aria-selected="true"]': { backgroundColor: 'transparent' },
        '&:hover': { backgroundColor: palette.primary.light },
      },
    },

    MuiPickersModal: {
      dialogRoot: {
        background: '#fff',
        width: 'auto',
        borderRadius: 0,
        '& .MuiDialogActions-root': {
          justifyContent: 'space-between',
          '& button': {
            borderBottom: 'none',
            textTransform: 'uppercase',
            color: palette.text.secondary,
            '&:hover': { borderBottom: 'none', color: palette.primary.main },
          },
        },
      },
      dialog: {
        fontSize: 21,
        color: '#000',
      },
    },
    MuiPopover: {
      paper: {
        borderRadius: 2,
        maxWidth: 300,
        color: palette.text.primary,
        fontSize: 16,
        width: '100%',
        minHeight: 'initial',
      },
    },
    MuiTypography: {
      colorPrimary: {
        color: palette.primary.main,
      },
      colorSecondary: {
        color: '#323E48',
      },
    },
    MuiPaper: {
      root: {
        scrollbarColor: `${palette.primary.main} ${palette.background.paper}`,
        scrollbarWidth: 'thin',
        '&::-webkit-scrollbar': { width: 10, height: 10 },
        '&::-webkit-scrollbar-track': { backgroundColor: palette.background.paper },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: palette.primary.main,
          border: `3px solid ${palette.background.paper}`,
          '&:hover': { border: `3px solid ${palette.primary.main}` },
        },
      },

      outlined: {
        border: 'none',
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        [down('sm')]: {
          backgroundColor: palette.background.paper,
          color: palette.text.secondary,
        },
      },

      elevation1: {
        boxShadow: 'none',
      },

      elevation4: {
        boxShadow: 'none',
      },

      elevation8: {
        boxShadow: '0 0 0.7em rgba(0,0,0,0.25)',
      },
    },
    MuiTab: {
      root: { boxShadow: 'none' },
      wrapper: {
        padding: '0 10px',
        textTransform: 'uppercase',
        fontWeight: 700,
        fontSize: 32,
        color: palette.text.primary,

        [down('sm')]: {
          fontSize: 28,
          padding: '0 0.4em',
        },
        [down('xs')]: {
          fontSize: 16,
          padding: '0 0.4em',
        },
      },

      textColorInherit: {
        borderRadius: 20,
        opacity: 0.5,
        "&[aria-selected^='true']": {
          background: 'rgba(255,255,255,0.1)',
        },
      },
    },
    PrivateTabIndicator: {
      root: {
        background: `none`,
      },
      colorSecondary: { backgroundColor: 'transparent' },
    },
  },
})
