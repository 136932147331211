import { useCallback, useEffect, useState } from 'react'
import { Box, Collapse, Fade, Grid, IconButton, makeStyles, Slider, Theme } from '@material-ui/core'
import { ZoomIn, ZoomOut } from '@material-ui/icons'
import { SubmitButton, Snackbar, Modal } from 'components'
import { validation, formLocale, TextInput, FileInput } from 'components/form-control'
import { propsAction } from 'store/props'
import { useReduxDispatch, useReduxSelector } from 'hooks'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { promoUploadPhoto, promoWorkPhoto } from 'api/promo'
import { image, dataURLtoFile } from 'utils'
import { ApiAnswerStatus, FormProps, UploadPhotoForm, UploadWorkForm } from 'types'
import Cropper from 'react-easy-crop'
import { Area, Point } from 'react-easy-crop/types'
import { uploadPhotoModalName } from '../upload-photo'

export const uploadWorkModalName = 'UploadWorkModal'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  cropper: {
    position: 'relative',
    height: 220,
    display: 'flex',
    alignItems: 'center',

    '& .reactEasyCrop_Container': {
      '& .reactEasyCrop_Contain': {
        maxWidth: 'initial',
        maxHeight: 'initial',
        width: '100%',
        height: 'auto',
      },

      '& .reactEasyCrop_CropAreaGrid': {
        boxShadow: 'inset 0 0 3px rgba(0,0,0,0.2)',
        '&:before, &:after': {
          boxShadow: 'inset 0 0 3px rgba(0,0,0,0.2), 0 0 3px rgba(0,0,0,0.2)',
        },
      },
    },
  },

  cropViewZoom: {
    position: 'absolute',
    height: '100%',
    right: 0,
    paddingRight: spacing(1),
    paddingLeft: spacing(1),
    color: '#fff',
    fontSize: 25,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:before': {
      zIndex: 1,
      content: '""',
      right: 0,
      top: 0,
      position: 'absolute',
      height: '100%',
      width: '100%',
      background: 'linear-gradient(to left, rgba(0,0,0,0.5),rgba(0,0,0,0.5) 30%,transparent) no-repeat',
    },
    '& .MuiButtonBase-root': { padding: spacing(1) },
  },
}))

export const UploadWorkModal: React.FC = () => {
  const classes = useStyles()
  const dispatch = useReduxDispatch()
  const { modal } = useReduxSelector((state) => state.props)
  const { show = false, params } = modal[uploadWorkModalName] || {}
  const { fetchPromoUser } = params || {}

  const handleClose = () => dispatch(propsAction.modal({ modal: { [uploadWorkModalName]: { show: false } } }))

  const schema = validation({
    pet_name: yup
      .string()
      .required()
      .min(2)
      .max(40)
      .matches(/^[а-яёА-ЯЁa-zA-Z\s-]*$/i, 'Только буквенные символы'),
    url: yup.mixed().test('required', 'Укажите ссылку на пост', (val) => {
      return typeof val?.[0] !== 'undefined'
    }),
  })

  const [loading, setLoading] = useState(false)

  const [form, setForm] = useState<FormProps<UploadWorkForm>>({
    data: {},
    processed: false,
    snackbar: {
      onClose: () => setForm({ ...form, snackbar: { ...form.snackbar, message: undefined } }),
    },
  })

  const hookForm = useForm<any>({
    resolver: yupResolver(schema),
  })
  const { reset } = hookForm

  const resetModal = () => {
    reset()
    setForm({
      data: {},
      processed: false,
      snackbar: {
        onClose: () => setForm({ ...form, snackbar: { ...form.snackbar, message: undefined } }),
      },
    })
  }

  const onSubmit = async (props: any) => {
    setForm({ ...form, processed: true, data: props })

    const { status, message } = await promoWorkPhoto({ ...props })

    dispatch(
      propsAction.alert({
        message,
      })
    )

    setForm({
      ...form,
      processed: false,
    })

    if (status === ApiAnswerStatus.SUCCESS) {
      resetModal()
      fetchPromoUser()
      handleClose()
    }
  }

  const submitFailed = () => {
    setForm({ ...form, processed: false, snackbar: { ...form.snackbar, message: formLocale.notValid } })
  }

  useEffect(() => {
    if (!show) {
      resetModal()
    }
  }, [show])

  return (
    <Modal open={show} maxWidth="sm" title="Добавить работу" name={uploadWorkModalName} onCloseDialog={handleClose}>
      <Box mx="auto" maxWidth={{ xs: 'initial', sm: 390 }} pt={4}>
        <form onSubmit={hookForm.handleSubmit(onSubmit, submitFailed)} autoComplete="off">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextInput label="Кличка" placeholder="Пример: Лаки" name="pet_name" form={hookForm} />
            </Grid>
            <Grid item xs={12}>
              <TextInput label="Ссылка на пост в ВКонтакте" placeholder="Укажите ссылку" name="url" form={hookForm} />
            </Grid>
          </Grid>

          <Snackbar {...form.snackbar} />

          <Box width="100%" maxWidth={260} mt={4} mx="auto">
            <SubmitButton
              fullWidth
              color="secondary"
              variant="contained"
              title="Зарегистрировать"
              disabled={form.processed || loading}
              processed={form.processed || loading}
            />
          </Box>
        </form>
      </Box>
    </Modal>
  )
}
