import {
  AlertProps,
  BankCardList,
  Doc,
  Entity,
  PhoneList,
  Promo,
  PromoUser,
} from "types";

export const SET_PROPS = "SET_PROPS";
export const SET_MODAL = "SET_MODAL";
export const SET_ALERT = "SET_ALERT";

export interface ProjectAction {
  recaptchaSitekey?: string;
  breedTypes?: Entity[];
  registerFocus?: Entity[];
  breeds?: { id: number; title: string; breed_species: Entity }[];
  phoneList?: PhoneList[];
  bankCardList?: BankCardList[];

  promoUser?: PromoUser;
  promo?: Promo;

  promoRulesSrc?: string;

  promoAviable?: boolean;

  NOW?: string | null;
  OWNER_END_DATE?: string | null;
  OWNER_START_DATE?: string | null;
  DOC?: Doc | null;

  [other: string]: any;
}

export interface ModalAction {
  modal: { [name: string]: { show: boolean; params?: any } };
}

export interface AlertAction {
  alert: AlertProps;
}

export type PROPS = { type: typeof SET_PROPS; payload: ProjectAction };
export type MODAL = { type: typeof SET_MODAL; payload: ModalAction };
export type ALERT = { type: typeof SET_ALERT; payload: AlertAction };
