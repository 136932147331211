


type AllImagesObject = { [key: string]: string }
const getAllImagesObject = (r: __WebpackModuleApi.RequireContext) => {
  const images: AllImagesObject = {}
  r.keys().map((item: string) => {
    const imgItem = r(item)
    images[item.replace('./', '')] = imgItem.default
    return item
  })
  return images
}

export const getImage = getAllImagesObject(require.context(`./img/promo5`, false, /.png|.jpg|.svg/))

