import { useEffect, useState } from 'react'
import { Grid, SnackbarContent, IconButton, Box, Grow, Collapse, useTheme } from '@material-ui/core'
import { Close, Warning, Info, Error, CheckCircle } from '@material-ui/icons'
import { SnackbarProps } from 'types'
import { str } from 'utils'
import { withPlanner, WithPlannerProps } from '../hoc'

type snackbarMsgVariants = 'success' | 'warning' | 'error' | 'info' | 'reset'

export const Snackbar: React.FC<SnackbarProps & WithPlannerProps> = withPlanner(
  ({ message, onClose, planner, ...props }) => {
    const {
      palette: { success, warning, error, info },
    } = useTheme()
    const variant: snackbarMsgVariants = message ? (Object.keys(message)[0] as snackbarMsgVariants) : 'reset'

    const variantColor = { success, warning, error, info, reset: { main: 'white' } }
    const variantIcon = {
      success: CheckCircle,
      warning: Warning,
      error: Error,
      info: Info,
      alert: Warning,
      reset: undefined,
    }

    const Icon = variantIcon[variant]
    const [open, setOpen] = useState<boolean>(false)

    const handleClose = () => {
      planner?.timeout(() => {
        setOpen(false)
        if (onClose) onClose()
      }, 200)
    }

    useEffect(() => {
      if (message && planner?.clearTimeouts()) {
        planner?.timeout(() => {
          setOpen(true)
        }, 150)
      } else handleClose()
    }, [message])

    return (
      <Collapse in={open} appear={false}>
        <Grow in={open} style={{ transformOrigin: '50% 0 0' }} timeout={{ enter: 750, exit: 180 }}>
          <SnackbarContent
            aria-describedby="client-snackbar"
            message={
              <Box id="client-snackbar" color={variantColor[variant]?.main}>
                <Grid container justifyContent="space-between" wrap="nowrap" spacing={2} alignItems="center">
                  <Grid item>{Icon && <Icon color="inherit" style={{ fontSize: 24 }} />}</Grid>

                  <Grid item>
                    {!!message &&
                      typeof message !== 'string' &&
                      Object.entries(message)
                        .filter(([key, val]) => key !== 'exception')
                        .map(([_, val], idx) => (
                          <Box key={`snackbar-message-${idx}`}>
                            {Array.isArray(val) ? (
                              val.map((v, i) => <p key={i}>{str.normalize(v)}</p>)
                            ) : (
                              <p>{str.normalize(val)}</p>
                            )}
                          </Box>
                        ))}

                    {!!message && typeof message === 'string' && (
                      <Box key="snackbar-message">
                        <p>{str.normalize(message)}</p>
                      </Box>
                    )}
                  </Grid>

                  <Grid item>
                    <IconButton
                      key="close"
                      color="inherit"
                      aria-label="close"
                      onClick={handleClose}
                      style={{ marginRight: -12 }}
                    >
                      <Close style={{ fontSize: 24 }} />
                    </IconButton>
                  </Grid>
                </Grid>
              </Box>
            }
            {...props}
          />
        </Grow>
      </Collapse>
    )
  }
)
