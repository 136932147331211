import { ChangeEvent, Fragment, useState } from "react";
import {
  Box,
  Grid,
  Hidden,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import moment from "moment";
import Moment from "react-moment";
import Pagination from "@material-ui/lab/Pagination";
import { PromoUserWinner } from "types";

export const WinnerList: React.FC<{ items: PromoUserWinner[] }> = ({
  items = [],
}) => {
  const [page, setPage] = useState<number>(1);
  const perPage = 6;

  const sortDate = (arr: PromoUserWinner[]) =>
    arr.sort((row1, row2) => {
      const k1 = moment(row1.created_at);
      const k2 = moment(row2.created_at);
      if (k1 < k2) return 1;
      if (k2 < k1) return -1;
      return 0;
    });

  return (
    <Fragment>

      <Table className="animated fadeIn faster">
        <TableHead>
          <TableRow>
            <Hidden xsDown>
              <TableCell style={{color:'#323E48'}}>Дата</TableCell>
              <TableCell style={{color:'#323E48'}}>Телефон</TableCell>
              <TableCell style={{color:'#323E48'}}>Приз</TableCell>
            </Hidden>
          </TableRow>
        </TableHead>

        <TableBody>
          {items.length > 0 &&
            sortDate(items)
              .slice(perPage * (page - 1), page * perPage)
              .map((i: PromoUserWinner, idx: number) => {
                const { make_at, prize, user, gallery } = i;

                return (
                  <TableRow key={idx}>
                    <Hidden xsDown>
                      <Fragment key={idx}>
                        <TableCell>
                          <Moment>{make_at}</Moment>
                        </TableCell>
                        <TableCell>{user?.phone_secure}</TableCell>
                        <TableCell>{prize?.name}</TableCell>
                        {/*<TableCell>{gallery?.id}</TableCell>*/}
                      </Fragment>
                    </Hidden>

                    <Hidden smUp>
                      <TableCell key={idx}>
                        <Box textAlign="left" fontSize={14}>
                          <Grid container spacing={1}>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item>
                                  <Box color="primary.main" pb={1 / 4}>
                                    Дата розыгрыша
                                  </Box>
                                </Grid>
                                <Grid item xs={12} className="nowrap_txt">
                                  <Moment>{make_at}</Moment>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item>
                                  <Box color="primary.main" pb={1 / 4}>
                                    Телефон
                                  </Box>
                                </Grid>
                                <Grid item xs={12} className="nowrap_txt">
                                  {user?.phone_secure}
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item>
                                  <Box color="primary.main" pb={1 / 4}>
                                    Приз
                                  </Box>
                                </Grid>
                                <Grid item xs={12} className="nowrap_txt">
                                  {prize?.name}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Box>
                      </TableCell>
                    </Hidden>
                  </TableRow>
                );
              })}
        </TableBody>
      </Table>

      {items?.length > perPage && (
        <Box pt={4} textAlign="center">
          <Pagination
            page={page}
            variant="text"
            count={Math.ceil(items?.length / perPage)}
            onChange={(_: ChangeEvent<unknown>, value: number) =>
              setPage(value)
            }
          />
        </Box>
      )}
    </Fragment>
  );
};
