import * as ls from 'local-storage'
import axios, { AxiosRequestConfig } from 'axios'
import { ApiAnswer, ApiAnswerStatus } from '../types'

export enum TOKEN {
  PUBLIC = 'token_public',
  PRIVATE = 'token_private',
}

const { REACT_APP_API_URL = '/', REACT_APP_API_AUTH, REACT_APP_DEBUG } = process.env
const axiosClient = axios.create({
  baseURL: `${REACT_APP_API_URL.replace(/^\/+/, '')}/`,
  responseType: 'json',
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'X-Access-Token': `Basic ${REACT_APP_API_AUTH}`,
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
})

const apiFetchData = async (config: AxiosRequestConfig): Promise<ApiAnswer<any>> => {
  const result: ApiAnswer<any> = await axiosClient
    .request(config)
    .then((r) => {
      return r.data
    })
    .catch((e) => {
      if (REACT_APP_DEBUG === 'true') console.log('apiFetchData catch', e.response)

      console.log(e.response?.data)
      return (
        e.response?.data ?? {
          status: ApiAnswerStatus.ERROR,
          message: { alert: e.message },
        }
      )
    })
  return result
}

const reFetchPrivateAPIToken = async (refreshToken: string) => {
  return apiFetchData({
    url: '/oauth/token',
    method: 'post',
    data: {
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
    },
  })
}

const fetchPrivateAPIToken = async (username: string, password: string) => {
  const result = await apiFetchData({
    url: '/oauth/token',
    method: 'post',
    data: {
      scope: '*',
      grant_type: 'password',
      username,
      password,
    },
  })

  if (result.status === ApiAnswerStatus.SUCCESS) {
    // Save token to localstorage
    ls.set(TOKEN.PRIVATE, result.data)
  }

  return result
}

const withPrivateAPIToken = async (config: AxiosRequestConfig) => {
  // Get token and refresh token from localstorage
  const token = ls.get<{ access_token?: string; refresh_token?: string }>(TOKEN.PRIVATE)

  // Check public token
  if (token && token.access_token) {
    // Make request

    const test = await apiFetchData({
      ...config,
      headers: { 'X-Access-Token': `Bearer ${token.access_token}` },
    }).then(async (res) => {
      // console.log('ошибки токена нет - получаем ответ', res)
      if (res?.status !== ApiAnswerStatus.UNAUTHENTICATED) {

        console.log(res?.status)
        return res}
      console.log('Токен нужно обновить - рефреш токен')

      return reFetchPrivateAPIToken(token.refresh_token || '').then(async (result) => {
        if (result.status !== ApiAnswerStatus.SUCCESS) {
          console.log('Неудачный перезапрос access-токена')
          ls.remove(TOKEN.PRIVATE)
          return result
        }
        ls.set(TOKEN.PRIVATE, result.data)

        return apiFetchData({
          ...config,
          headers: { 'X-Access-Token': `Bearer ${result.data.access_token}` },
        })
      })
    })

    return test
  }
  // Пользователь не логинится и не авторизован - делаем запрос и возвращаем ошибку
  return apiFetchData(config)
}

const fetchPublicAPIToken = async () => {
  try {
    const result = await apiFetchData({
      url: '/oauth/token',
      method: 'post',
      data: {
        grant_type: 'client_credentials',
      },
    })

    // Save token to localstorage
    ls.set(TOKEN.PUBLIC, result.data)

    return result
  } catch (e) {
    console.error('Ошибка запроса токена:', e)
    throw e
  }
}

const withPublicAPIToken = async (config: AxiosRequestConfig) => {
  // Get token and refresh token from localstorage
  const token = ls.get<{ access_token?: string; refresh_token?: string }>(TOKEN.PUBLIC)
  // Check public token
  if (token && token.access_token) {
    // Make request
    const test = await apiFetchData({
      ...config,
      headers: { 'X-Access-Token': `Bearer ${token.access_token}` },
    }).then((res) => {
      if (res?.status !== ApiAnswerStatus.UNAUTHENTICATED) return res

      console.log('Токен нужно запросить новый public-токен')

      ls.remove(TOKEN.PUBLIC)

      return fetchPublicAPIToken().then((r) => {
        if (r?.status === ApiAnswerStatus.SUCCESS) {
          return apiFetchData({
            ...config,
            headers: { 'X-Access-Token': `Bearer ${r.data.access_token}` },
          })
        }
        return res
      })
    })
    return test
  }

  return fetchPublicAPIToken().then((res) => {
    if (res?.status === ApiAnswerStatus.SUCCESS) {
      return apiFetchData({
        ...config,
        headers: { 'X-Access-Token': `Bearer ${res.data.access_token}` },
      })
    }
    return res
  })
}

const socialFetchPrivateAPIToken = async (provider: string, accessToken: string) => {
  return apiFetchData({
    url: '/oauth/token',
    method: 'post',
    data: {
      access_token: accessToken,
      grant_type: 'social',
      provider,
    },
  }).then((r) => {
    if (r.status === ApiAnswerStatus.SUCCESS) {
      ls.set(TOKEN.PRIVATE, r.data)
    }
    return r
  })
}

const signout = async () => {
  return withPrivateAPIToken({
    url: '/user/owner/logout',
    method: 'get',
  }).then((_r) => {
    // if (r.status === ApiAnswerStatus.SUCCESS)
    ls.remove(TOKEN.PRIVATE)
  })
}

export {
  apiFetchData,
  withPrivateAPIToken,
  withPublicAPIToken,
  fetchPrivateAPIToken,
  socialFetchPrivateAPIToken,
  reFetchPrivateAPIToken,
  signout,
}
