import { Modal } from 'components'
import { useReduxSelector, useReduxDispatch } from 'hooks'
import { propsAction } from 'store/props'
import PetForm from './pet-form'

export const petModalName = 'PetModal'

export const PetModal: React.FC = () => {
  const dispatch = useReduxDispatch()
  const { modal } = useReduxSelector((state) => state.props)
  const { show = false, params } = modal[petModalName] || {}

  const handleClose = () => dispatch(propsAction.modal({ modal: { [petModalName]: { show: false } } }))

  return (
    <Modal
      open={show}
      maxWidth="sm"
      title={params?.pet ? 'Питомец' : 'Добавить питомца'}
      name={petModalName}
      onCloseDialog={handleClose}
    >
      <PetForm pet={params?.pet} rootModal={petModalName} />
    </Modal>
  )
}
