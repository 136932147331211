import { useState, useRef } from 'react'
import { Fade, makeStyles, Theme } from '@material-ui/core'
import cn from 'classnames'
import { useIntersection } from 'hooks'

const useStyles = makeStyles(({ palette }: Theme) => ({
  root: {
    backgroundColor: palette.background.paper,
    overflow: 'hidden',
    position: 'relative',
    width: '100%',
    margin: '0 auto',
    boxShadow: `0 0 0 1px ${palette.background.paper}`,
  },

  image: {
    position: 'absolute',
    display: 'block',
    width: '100%',
    height: '100%',
    minHeight: '100%',
    objectFit: 'cover',
    transform: 'scale(1)',
    transition: '0.2s ease-out',
    opacity: 0,
    '&.loaded': {
      opacity: 1,
    },

    '&.thumb': {
      opacity: 1,
      transform: 'scale(1.06)',
      filter: 'blur(10px)',
      position: 'absolute',
      '&.loaded': {
        opacity: 0,
      },
    },
  },
}))

type ImageRendererProps = {
  cover?: boolean
  url: string
  thumb: string
  height?: number
  width?: number
  alt?: string
}

export const ImageRenderer: React.FC<ImageRendererProps> = (props) => {
  const { url, thumb, height, width, cover = false, alt } = props

  const classes = useStyles()

  const [loaded, setLoaded] = useState(false)
  const [inView, setInView] = useState(false)
  const [showThumb, setShowThumb] = useState(true)
  const [thumbLoaded, setThumbLoaded] = useState(false)

  const imgRef = useRef<HTMLDivElement>(null)

  useIntersection(imgRef, () => {
    if (!inView) setInView(true)
  })

  const handleOnLoad = () => {
    setLoaded(true)
    setShowThumb(false)
  }

  return (
    <div
      className={classes.root}
      ref={imgRef}
      style={{
        height: cover ? '100%' : '',
        paddingBottom: !cover ? `${((height ?? 0) / (width ?? 0)) * 100}%` : '',
      }}
    >
      {inView && (
        <>
          <img draggable={false} className={cn(classes.image, { loaded })} src={url} onLoad={handleOnLoad} alt="" />

          {showThumb && (
            <Fade in={thumbLoaded}>
              <img
                draggable={false}
                className={cn(classes.image, 'thumb', { loaded })}
                onLoad={() => setThumbLoaded(true)}
                src={thumb}
                alt={alt}
              />
            </Fade>
          )}
        </>
      )}
    </div>
  )
}
