import { useState, Fragment, useRef } from 'react'
import { useForm } from 'react-hook-form'
import { Box } from '@material-ui/core'
import { parse } from 'query-string'
import ReCAPTCHA from 'react-google-recaptcha'
import { useHistory } from 'react-router-dom'
import { forgotPasswordPhone } from 'api'
import { useReduxSelector, useReduxDispatch } from 'hooks'
import { propsAction } from 'store/props'
import { SnackbarProps, ApiAnswerStatus } from 'types'
import { PhoneInput, SubmitButton, Snackbar } from 'components'
import { str } from 'utils'

const ForgotForm: React.FC<{ rootModal?: string }> = ({ rootModal }) => {
  const history = useHistory()
  const { location } = history
  const { search } = location
  const { SUCCESS } = ApiAnswerStatus

  const recaptchaRef = useRef<ReCAPTCHA>(null)
  const [formData, setFormData] = useState<{ phone: string }>({ phone: '' })
  const [processed, setProcessed] = useState<boolean>(false)

  const [snackbarProps, setSbnackbarProps] = useState<SnackbarProps>({
    onClose: () => setSbnackbarProps({ ...snackbarProps, message: undefined }),
  })

  const dispatch = useReduxDispatch()
  const { recaptchaSitekey } = useReduxSelector((state) => state.props)

  const { login } = parse(search)
  const hookForm = useForm({ defaultValues: { phone: parseInt(login?.toString() ?? '', 10) } })

  const onRecaptchaChange = async (recaptcha: string) => {
    const { phone } = formData
    const { status, message } = await forgotPasswordPhone({ phone, recaptcha })
    recaptchaRef.current?.reset()
    setProcessed(false)

    if (status === SUCCESS)
      dispatch(
        propsAction.alert({
          message,
          rootModal,
          onClose: () => history.push({ pathname: '/', search: `?signin&username=${phone}` }),
        })
      )
    else setSbnackbarProps({ ...snackbarProps, message })
  }

  const onSubmit = (props: { phone: string }) => {
    setFormData(props)
    setProcessed(true)
    recaptchaRef.current?.execute()
  }

  const onRecaptchaExpired = () => {
    setProcessed(false)
    setSbnackbarProps({ ...snackbarProps, message: undefined })
  }

  return (
    <Fragment>
      <form onSubmit={hookForm.handleSubmit(onSubmit)}>
        {recaptchaSitekey && (
          <ReCAPTCHA
            size="invisible"
            ref={recaptchaRef}
            sitekey={recaptchaSitekey}
            onExpired={onRecaptchaExpired}
            onChange={(t) => onRecaptchaChange(t ?? '')}
            onErrored={() => console.log('onRecaptchaErrored')}
          />
        )}

        <Box maxWidth={480} mx="auto">
          <Box pb={6}>
            {str.normalize(`
							Введите номер телефона, указанный при&nbsp;регистрации.<br/>Пароль к Вашему Личному кабинету
							будет отправлен на указанный номер телефона.
						`)}
          </Box>

          <PhoneInput name="phone" form={hookForm} />

          <Snackbar {...snackbarProps} />

          <Box width="100%" maxWidth={200} mt={2} mx="auto">
            <SubmitButton
              color="secondary"
              variant="contained"
              fullWidth
              disabled={processed}
              processed={processed}
              title="Восстановить"
            />
          </Box>
        </Box>
      </form>
    </Fragment>
  )
}

export default ForgotForm
