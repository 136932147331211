import { JSXElementConstructor, ReactElement } from 'react'
import ReactHtmlParser from 'react-html-parser'

export const str = {
  normalize: (s: string, withBr = false): ReactElement<any, string | JSXElementConstructor<any>>[] | string => {
    if (typeof s !== 'string' || s.length < 2) return s

    const pre: string[] = [
      'к',
      'в',
      'и',
      'с',
      'на',
      'за',
      'а',
      'по',
      'или',
      'из',
      'без',
      'между',
      'под',
      'вокруг',
      'о',
      'про',
      'не',
    ]

    const res = s
      ?.replace(/([а-яА-Я]+)( )/g, (_, p2: string) => p2 + (pre.includes(p2.toLowerCase()) ? '&nbsp;' : ' '))
      ?.replace(/(\d+)( )/g, (_, p2: string) => `${p2}&nbsp;`)
      ?.replace(/\n/g, withBr ? '<br/>' : '')

    return ReactHtmlParser(res)
  },

  format: {
    firstToUpper: (s: string): string => {
      return s ? s?.charAt(0)?.toUpperCase() + s?.slice(1) : s
    },

    nowrap: (s: string): ReactElement<any, string | JSXElementConstructor<any>>[] | string => {
      return ReactHtmlParser(s?.replace(/\s/g, '&nbsp;'))
    },

    phone: (p: string): string => {
      return p !== null && p && p?.length > 9
        ? (str.normalize(
            `+7&nbsp;(${p?.substring(0, 3)})&nbsp;${p?.substring(3, 6)}&nbsp;${p?.substring(6, 8)}&nbsp;${p?.substring(
              8
            )}`
          ) as string)
        : p
    },

    cost: (n: number | string, prefix?: string, postfix = '₽'): string => {
      if (typeof n === 'undefined') return '0'
      let cur = n
      if (typeof n === 'string') cur = parseFloat(n)
      let res = cur.toLocaleString(undefined)
      if (postfix) res = `${res}&nbsp;${postfix}`
      if (prefix) res = `${prefix}&nbsp;${res}`
      return res
    },
  },
}
