export const b64ToBlob = (Base64Image: string | null | undefined): Blob | undefined => {
  if (!Base64Image) return undefined
  // SPLIT INTO TWO PARTS
  const parts = Base64Image.split(';base64,')
  // HOLD THE CONTENT TYPE
  const imageType = parts[0].split(':')[1]
  // DECODE BASE64 STRING
  const decodedData = window.atob(parts[1])
  // CREATE UNIT8ARRAY OF SIZE SAME AS ROW DATA LENGTH
  const uInt8Array = new Uint8Array(decodedData.length)
  // INSERT ALL CHARACTER CODE INTO UINT8ARRAY
  for (let i = 0; i < decodedData.length; ++i) {
    uInt8Array[i] = decodedData.charCodeAt(i)
  }
  // RETURN BLOB IMAGE AFTER CONVERSION
  return new Blob([uInt8Array], { type: imageType })
}

export const dataURLtoFile = (dataurl: string, filename: string): File | null => {
  const arr = dataurl.split(',')
  if (arr?.length > 0) {
    const mime = arr[0]?.match(/:(.*?);/)?.[1]
    const bstr = window.atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }

    return new File([u8arr], filename, { type: mime })
  }
  return null
}
