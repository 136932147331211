import { useEffect } from 'react'
import useReactRouter from 'use-react-router'
import { Modal } from 'components'
import TrackEvent from 'components/GAnalitics'
import { useReduxSelector } from 'hooks'
import SigninForm from './signin-form'

export const signInModalName = 'SigninModal'

export const SignInModal: React.FC = () => {
  const { history } = useReactRouter()
  const { modal } = useReduxSelector((state) => state.props)
  const { isAuth } = useReduxSelector((state) => state.auth)
  const { show = false } = modal[signInModalName] || {}

  useEffect(() => {
    if (show && isAuth) history.push('/profile')
  }, [show, isAuth])

  return (
    <Modal
      open={show}
      maxWidth="sm"
      title="Авторизация"
      name={signInModalName}
      onCloseDialog={() => history.push({ search: '' })}
      onOpen={() => TrackEvent({ eventCategory: 'Authorization', eventAction: 'open_a_form' })}
    >
      <SigninForm />
    </Modal>
  )
}
