import React, { Fragment, useEffect } from 'react'
import moment from 'moment'
import { StartPage } from 'pages/start'
import { StopPage } from 'pages/stop'
import { useReduxSelector } from 'hooks'

export const PeriodAction: React.FC = ({ children }) => {
  const DEBUG = process.env.REACT_APP_DEBUG === 'true'
  const { OWNER_START_DATE, OWNER_END_DATE, NOW } = useReduxSelector((state) => state.props)

  useEffect(() => {
    if (DEBUG && OWNER_START_DATE && OWNER_END_DATE)
      console.log(
        'nowIsAfter:',
        moment(NOW).isAfter(moment(OWNER_START_DATE)),
        'now:',
        NOW,
        'start:',
        moment(OWNER_START_DATE).format('YYYY-MM-DD HH:mm:ss')
      )
  }, [OWNER_START_DATE, OWNER_END_DATE])

  switch (true) {
    case moment(NOW).isAfter(moment(OWNER_START_DATE)):
      return <Fragment>{children}</Fragment>

    case moment(NOW).isAfter(moment(OWNER_END_DATE)):
      return <StopPage now={NOW} stop={OWNER_END_DATE} />

    default:
      return <StartPage now={NOW} start={OWNER_START_DATE} />
  }
}
