import * as yup from 'yup'
import moment from 'moment'
import { validation } from 'components/form-control'

export const schemas = [
  // With steps
  validation({
    check_time: yup
      .string()
      .required()
      .test({ test: (val, _) => val?.toLowerCase() !== 'invalid date', message: 'Введено некорректное время' }),
    check_summa: yup
      .string()
      .matches(/^\d+[,.]\d{2}$/i, { message: 'Введите сумму с копейками', excludeEmptyString: true })
      .required(),
    check_date: yup
      .string()
      .required()
      .test({ test: (val, _) => val?.toLowerCase() !== 'invalid date', message: 'Введена некорректная дата' })
      .test({
        test: (val, _) => moment(val).isAfter('2021-01-01') && moment(val).isBefore(new Date()),
        message: 'Дата вне допустимого интервала',
      }),
  }),
  validation({
    check_fn: yup
      .string()
      .required()
      .matches(/^\d+$/i, { message: 'Только цифры', excludeEmptyString: true })
      .length(16, 'ФН содержит 16 символов'),
    check_fd: yup.string().required().matches(/^\d+$/i, { message: 'Только цифры', excludeEmptyString: true }).max(10),
    check_fpd: yup.string().required().matches(/^\d+$/i, { message: 'Только цифры', excludeEmptyString: true }).max(10),
  }),

  // All fields on screen
  validation({
    check_time: yup
      .string()
      .required()
      .test({ test: (val, _) => val?.toLowerCase() !== 'invalid date', message: 'Введено некорректное время' }),
    check_summa: yup
      .string()
      .matches(/^\d+[,.]\d{2}$/i, { message: 'Введите сумму с копейками', excludeEmptyString: true })
      .required(),
    check_date: yup
      .string()
      .required()
      .test({ test: (val, _) => val?.toLowerCase() !== 'invalid date', message: 'Введена некорректная дата' })
      .test({
        test: (val, _) => moment(val).isAfter('2021-01-01') && moment(val).isBefore(new Date()),
        message: 'Дата вне допустимого интервала',
      }),
    check_fn: yup
      .string()
      .required()
      .matches(/^\d+$/i, { message: 'Только цифры', excludeEmptyString: true })
      .length(16, 'ФН содержит 16 символов'),
    check_fd: yup.string().required().matches(/^\d+$/i, { message: 'Только цифры', excludeEmptyString: true }).max(10),
    check_fpd: yup.string().required().matches(/^\d+$/i, { message: 'Только цифры', excludeEmptyString: true }).max(10),
  }),
]
