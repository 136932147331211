import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Box, Button } from '@material-ui/core'
import { LocationOn } from '@material-ui/icons'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useDispatch } from 'react-redux'
import { requestBuyerPetPrize, getFias } from 'api'
import { Snackbar } from 'components'
import { AutocompleteFetch, formLocale, AutocompleteOption, validation } from 'components/form-control'
import { ApiAnswerStatus, FormProps } from 'types'
import { authAction } from 'store/auth'
import { propsAction } from 'store/props'

type GiftPetFormProps = { address?: { unrestricted_value?: string } }

const schema = validation({
  address: yup
    .object()
    .typeError('Указан некорректный адрес')
    .required('Указан некорректный адрес')
    .test('valid-addr', 'Указан некорректный адрес', (a) => a?.city || a?.np),
})

const GiftPetForm: React.FC<{ rootModal?: string }> = ({ rootModal }) => {
  const dispatch = useDispatch()
  const { SUCCESS, BREAK } = ApiAnswerStatus
  const { notValid } = formLocale

  const [formProps, setFormProps] = useState<FormProps<GiftPetFormProps>>({
    data: {},
    processed: false,
    snackbar: {
      onClose: () => setFormProps({ ...formProps, snackbar: { ...formProps.snackbar, message: undefined } }),
    },
  })

  const hookForm = useForm<any>({ resolver: yupResolver(schema) })

  const onSubmit = async ({ address }: GiftPetFormProps) => {
    setFormProps({ ...formProps, snackbar: { ...formProps.snackbar, message: undefined }, processed: true })
    const { status, message } = await requestBuyerPetPrize(address?.unrestricted_value ?? '')

    switch (status) {
      case SUCCESS:
        setFormProps({ ...formProps, processed: false })
        dispatch(propsAction.alert({ message, rootModal, onClose: () => dispatch(authAction.auth()) }))
        break
      case BREAK:
        setFormProps({
          ...formProps,
          snackbar: { ...formProps.snackbar, message: notValid },
          processed: false,
        })
        break
      default:
        setFormProps({
          ...formProps,
          snackbar: { ...formProps.snackbar, message },
          processed: false,
        })
        break
    }
  }

  const fetch = async (val?: string): Promise<AutocompleteOption[]> => {
    const data = await getFias({ query: val ?? '' })

    return data?.suggestions.map((s: any) => ({
      value: s?.value,
      title: s?.value,
      key: s?.key,
      unrestricted_value: s?.unrestricted_value,
      city: s?.data.city_with_type,
      region: s?.data.region_with_type,
      area: s?.data.area_with_type,
      np: s?.data.settlement_with_type,
    }))
  }

  return (
    <Box maxWidth={430} mx="auto">
      <Box>Для получения подарка Вашему питомцу укажите адрес доставки</Box>

      <form onSubmit={hookForm.handleSubmit(onSubmit)}>
        <Box pt={3}>
          <AutocompleteFetch
            name="address"
            form={hookForm}
            placeholder="Например: Москва, Тверская ул., 4"
            optionIcon={<LocationOn fontSize="inherit" style={{ padding: 0 }} />}
            fetch={fetch}
          />

          <Snackbar {...formProps.snackbar} />

          <Box width="100%" maxWidth={200} mx="auto" mt={3}>
            <Button fullWidth variant="contained" color="secondary" type="submit" disabled={formProps.processed}>
              Далее
            </Button>
          </Box>
        </Box>
      </form>
    </Box>
  )
}

export default GiftPetForm
