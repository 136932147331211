import { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import { MuiThemeProvider } from '@material-ui/core'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ErrorBoundary } from 'react-error-boundary'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import 'moment/locale/ru'
import './sass/main.sass'

import Moment from 'react-moment'
import moment from 'moment'
import MomentUtils from '@date-io/moment'

import store from './store'
import { theme } from './theme/theme'
import { App } from './app'

moment.locale('ru')
moment.defaultFormat = 'DD.MM.YYYY'
Moment.globalLocale = 'ru'
Moment.globalFormat = 'DD.MM.YYYY'

const ErrorFallback = lazy(() => import('./pages/error-fallback'))

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <MuiThemeProvider theme={theme}>
        <ErrorBoundary
          fallbackRender={() => (
            <Suspense fallback="">
              <ErrorFallback />
            </Suspense>
          )}
        >
          <MuiPickersUtilsProvider utils={MomentUtils}>
            <App />
          </MuiPickersUtilsProvider>
        </ErrorBoundary>
      </MuiThemeProvider>
    </BrowserRouter>
  </Provider>,

  document.getElementById('root')
)
