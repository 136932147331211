import {Fragment, useState} from 'react'
import {Box, Drawer, Grid, IconButton, Link} from '@material-ui/core'
import {Close} from '@material-ui/icons'
import {animateScroll as scroll, Events} from 'react-scroll'
import {SocialShare} from 'components'
import {useReduxSelector} from 'hooks'
import {PopperAuthBody} from '../popper-auth'
import {MyLink} from './nav-link'
import {Menu} from './menu'
import {useStyles} from '../header.style'
import {getImage} from "../../../promo/assets";
import useReactRouter from "use-react-router";

export const MobileMenu: React.FC = () => {
    const classes = useStyles()
    const {data: user, isAuth} = useReduxSelector((state) => state.auth)
    const {history, location} = useReactRouter();
    const {pathname} = location;
    const [drawer, setDrawer] = useState<boolean>(false)

    Events.scrollEvent.register('begin', () => setDrawer(false))

    return (
        <Fragment>
            <Grid container justifyContent="space-between" alignItems="center">
                <Grid item>
                    <Box height={40}>
                        {/* eslint-disable-next-line */}
                        <Link onClick={() => scroll.scrollToTop()} draggable={false} component={MyLink} to="/">
                            {pathname === "/contest" ?
                                    <img alt="" src={getImage?.["logo.svg"]} height="100%" width="auto"/>

                                :
                                <img alt="" src="/imgs/logo/logo-black.svg" height="100%" width="auto"/>}
                        </Link>
                    </Box>
                </Grid>

                <Grid item>
                    <IconButton className={classes.icon} aria-label="Menu" onClick={() => setDrawer((prev) => !prev)}>
                        {drawer ? (
                            <Close className="animated zoomIn faster"/>
                        ) : (
                            <img
                                draggable={false}
                                className="animated zoomIn faster"
                                src="/imgs/icon/menu-icon.svg"
                                width={23}
                                height={23}
                                alt=""
                            />
                        )}
                    </IconButton>
                </Grid>
            </Grid>

            <Drawer open={drawer} anchor="right" onClose={() => setDrawer(false)}>
                <Box className={classes.drawerBody}>
                    {isAuth && <PopperAuthBody user={user} onClose={() => setDrawer(false)}/>}

                    <Box tabIndex={0} role="button" px={2} onClick={() => setDrawer(false)}
                         onKeyDown={() => setDrawer(false)}>
                        <Menu/>
                    </Box>

                    <Box position="absolute" bottom="1em" left="1em">
                        <SocialShare title="Поделиться"/>
                    </Box>
                </Box>
            </Drawer>
        </Fragment>
    )
}
