import { FacebookShareButton, OKShareButton, VKShareButton } from 'react-share'
import { Box, Grid, makeStyles, Theme } from '@material-ui/core'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebookF, faOdnoklassniki, faVk } from '@fortawesome/free-brands-svg-icons'
import TrackEvent from '../GAnalitics'

export const getMetaContent = (() => {
  const metas: any = {}
  return (metaName: any) => {
    if (!metas[metaName]) {
      // eslint-disable-next-line consistent-return
      Array.prototype.some.call(document.getElementsByTagName('meta'), (el) => {
        if (el.name === metaName) {
          metas[metaName] = el.content
          return true
        }
        if (el.getAttribute('property') === metaName) {
          metas[metaName] = el.content
          return true
        }
        metas[metaName] = 'meta tag not found'
      })
    }
    return metas[metaName]
  }
})()

const Url = getMetaContent('og:url')
const shareTitle = getMetaContent('og:title')
const shareDescription = getMetaContent('og:description')

const clickShareButton = ({ eventLabel }: { eventLabel: string }) => {
  TrackEvent({ eventCategory: 'ExternalReference', eventAction: 'sharesn', eventLabel })
}

const useStyles = makeStyles(({ spacing }: Theme) => ({
  label: { fontSize: 13, fontWeight: 500, marginBottom: spacing(2), textTransform: 'uppercase' },

  iconButton: {
    borderRadius: 3,
    width: 32,
    height: 32,
    lineHeight: '32px',
    textAlign: 'center',
    userSelect: 'none',
    outline: 'none',
    transition: 'opacity 0.15s ease-in-out',
    '&:hover': { opacity: 0.8 },
    '& span': { width: 'auto', fontSize: 20 },
    '& button': { width: '100%' },
  },
}))

type Props = { color?: 'primary' | 'secondary'; shareUrl?: string; img?: string; title?: string }

export const SocialShare: React.FC<Props> = ({ img, shareUrl = Url, color = 'primary', title = 'Поделиться' }: any) => {
  const classes = useStyles()

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item>
        {title && (
          <Box className={classes.label} color={`${color}.main`}>
            {title}
          </Box>
        )}

        <Grid container alignItems="center" wrap="nowrap" spacing={1}>
          <Grid item>
            <Box className={classes.iconButton} bgcolor={`${color}.main`} color={`${color}.contrastText`}>
              <VKShareButton
                image={img}
                url={shareUrl}
                className="icon_vk"
                title={`${shareTitle}. ${shareDescription}`}
                beforeOnClick={() => clickShareButton({ eventLabel: 'vkontakte' })}
              >
                <span className="fa-fw fa-1x">
                  <FontAwesomeIcon icon={faVk} size="1x" />
                </span>
              </VKShareButton>
            </Box>
          </Grid>

          {/* <Grid item>
            <Box className={classes.iconButton} bgcolor={`${color}.main`} color={`${color}.contrastText`}>
              <FacebookShareButton
                url={shareUrl}
                className="icon_fb"
                beforeOnClick={() => clickShareButton({ eventLabel: 'facebook' })}
              >
                <span className="fa-fw fa-1x">
                  <FontAwesomeIcon icon={faFacebookF} size="1x" />
                </span>
              </FacebookShareButton>
            </Box>
          </Grid> */}

          {/* <Grid item>
            <Box className={classes.iconButton} bgcolor={`${color}.main`} color={`${color}.contrastText`}>
              <OKShareButton
                image={img}
                url={shareUrl}
                title={shareTitle}
                className="icon_ok"
                description={shareDescription}
                beforeOnClick={() => clickShareButton({ eventLabel: 'odnoklassniki' })}
              >
                <span className="fa-fw fa-1x">
                  <FontAwesomeIcon icon={faOdnoklassniki} size="1x" />
                </span>
              </OKShareButton>
            </Box>
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  )
}
