import moment from "moment";
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Theme,
  useMediaQuery,
} from "@material-ui/core";
import { animateScroll as scroll } from "react-scroll";
import { useReduxSelector } from "hooks";
import { SocialShare } from "components";
import useReactRouter from "use-react-router";

const useStyles = makeStyles(({ breakpoints: { down }, palette }: Theme) => ({
  footerBody2: {
    paddingTop: 15,
    paddingBottom: 15,
    [down("lg")]: {
      fontSize: 12,
    },
  },

  footerBody: {
    paddingTop: 30,
    paddingBottom: 30,
    color: palette.text.hint,
    fontSize: 16,
    fontWeight: 500,
    borderBottom: `1px solid ${palette.text.hint}`,
    "& a": {
      color: palette.text.hint,
      textDecoration: "none",
      "&:hover": { textDecoration: "underline" },
    },

    [down("md")]: {
      fontSize: 16,
      "& img.logo": {
        margin: "0 auto",
        position: "relative",
        display: "block",
        marginBottom: 30,
      },
    },
  },

  menuItem: {
    color: palette.text.hint,
    textDecoration: "none",
    fontWeight: 500,
    fontSize: 14,
    borderBottom: `1.5px dotted ${palette.text.hint}`,
    "&:hover": {
      borderBottom: `1.5px solid ${palette.text.hint}`,
      color: palette.text.hint,
    },
  },
}));

export const Footer: React.FC = () => {
  const classes = useStyles();
  // const { isAuth } = useReduxSelector((state) => state.auth)
  const {
    NOW,
    DOC,
    promoRulesSrc = "#",
    promoAviable,
  } = useReduxSelector((state) => state.props);

  const md = useMediaQuery(({ breakpoints: { down } }: Theme) => down("sm"));

  const { history, location } = useReactRouter();
  const { pathname } = location;

  return (
    <footer>
      <Box
        bgcolor="primary.main"
        style={{
          // backgroundColor: pathname !== "/contest" ? "" : "#658376",
        }}
      >
        <Container maxWidth={false}>
          <Grid
            container
            justifyContent={md ? "center" : "space-between"}
            className={classes.footerBody}
          >
            <Grid item xs={12} md="auto">
              <Box
                width="100%"
                mx="auto"
                maxWidth={{ xs: 102, sm: 190 }}
                pr={{ xs: 0, sm: 1 }}
                style={{ cursor: "pointer" }}
                onClick={() => {
                  scroll.scrollToTop();
                }}
              >
                <img
                  draggable={false}
                  className="logo"
                  src="/imgs/logo/logo-bottom.svg"
                  width="100%"
                  height="auto"
                  alt=""
                />
              </Box>
            </Grid>

            <Grid item>
              <Grid
                container
                spacing={md ? 3 : 6}
                alignItems="center"
                justifyContent={md ? "center" : "flex-end"}
              >
                <Grid item xs={12} sm="auto">
                  Задать вопрос
                  <br />
                  о программе лояльности:
                  <br />
                  <Box pt={{ xs: 1, sm: 2 }} pb={{ xs: 2, sm: 0 }}>
                    <a
                      draggable={false}
                      color="secondary"
                      target="_blank"
                      rel="noreferrer"
                      href="mailto:owner@alphapet-info.ru"
                    >
                      <img
                        draggable={false}
                        src="/imgs/icon/mail.svg"
                        style={{ marginRight: 10, marginBottom: -10 }}
                        alt=""
                      />
                      owner@alphapet-info.ru
                    </a>
                  </Box>
                </Grid>

                {/* <Grid item xs={12} sm="auto">
                  Получить консультацию
                  <br />о продукции ТМ AlphaPet<span style={{ fontSize: 10, verticalAlign: 'top' }}>®</span>:
                  <br />
                  <Box pt={{ xs: 1, sm: 2 }} pb={{ xs: 2, sm: 0 }}>
                    <a
                      draggable={false}
                      color="secondary"
                      target="_blank"
                      rel="noreferrer"
                      href="mailto:info@alphapet.ru"
                    >
                      <img
                        draggable={false}
                        src="/imgs/icon/mail.svg"
                        style={{ marginRight: 10, marginBottom: -10 }}
                        alt=""
                      />
                      info@alphapet.ru
                    </a>
                  </Box>
                </Grid> */}

                <Grid item xs={12} sm="auto">
                  <Box pl={{ xs: 0, lg: 13 }}>
                    <SocialShare color="secondary" />
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            alignItems="center"
            justifyContent="space-between"
            className={classes.footerBody2}
          >
            <Grid item>
              <Box color="primary.contrastText" fontSize={12} fontWeight={500}>
                © 2009-{moment(NOW).year()} AlphaPet
                <span style={{ fontSize: 9, verticalAlign: "top" }}>®</span>
              </Box>
            </Grid>

            <Grid item>
              <Grid
                container
                justifyContent={md ? "flex-start" : "flex-end"}
                spacing={md ? 2 : 5}
              >
                {promoAviable && typeof DOC?.rules_promo?.primary_src !== "undefined" && (
                  <Grid item>
                    <a
                      className={classes.menuItem}
                      rel="noreferrer"
                      target="_blank"
                      download={`${DOC?.rules_promo?.title?.replace(/\s/gi, "_")}.${
                        DOC?.rules_promo?.primary_src?.match(/[a-z]+?$/i)?.[0]
                      }`}
                      href={ DOC?.rules_promo?.primary_src ?? ""}
                    >
                      Правила Акции
                    </a>
                  </Grid>
                )}

                {typeof DOC?.rules?.primary_src !== "undefined" && (
                  <Grid item>
                    <a
                      className={classes.menuItem}
                      rel="noreferrer"
                      target="_blank"
                      download={`${DOC?.rules?.title?.replace(/\s/gi, "_")}.${
                        DOC?.rules?.primary_src?.match(/[a-z]+?$/i)?.[0]
                      }`}
                      href={DOC?.rules?.primary_src ?? ""}
                    >
                      Правила программы лояльности
                    </a>
                  </Grid>
                )}

                {typeof DOC?.agreement?.primary_src !== "undefined" && (
                  <Grid item>
                    <a
                      className={classes.menuItem}
                      rel="noreferrer"
                      target="_blank"
                      download={`${DOC?.agreement?.title?.replace(
                        /\s/gi,
                        "_"
                      )}.${
                        DOC?.agreement?.primary_src?.match(/[a-z]+?$/i)?.[0]
                      }`}
                      href={DOC?.agreement?.primary_src ?? ""}
                    >
                      Пользовательское соглашение
                    </a>
                  </Grid>
                )}

                <Grid item xs={12} sm="auto" md="auto">
                  <a
                    className={classes.menuItem}
                    color="secondary"
                    href="https://atoms.ru"
                    target="_blank"
                    rel="noreferrer"
                  >
                    Программа лояльности разработана в
                  </a>
                  <a
                    color="secondary"
                    href="https://atoms.ru"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      style={{
                        height: 26,
                        display: "inline-block",
                        marginLeft: 10,
                        marginBottom: -11,
                      }}
                      alt="Atoms"
                      src="/imgs/logo_white.png"
                    />
                  </a>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </footer>
  );
};
