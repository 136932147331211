import { useCallback, useEffect, useState } from 'react'
import { Box, Collapse, Fade, Grid, IconButton, makeStyles, Slider, Theme } from '@material-ui/core'
import { ZoomIn, ZoomOut } from '@material-ui/icons'
import { SubmitButton, Snackbar, Modal } from 'components'
import { validation, formLocale, TextInput, FileInput } from 'components/form-control'
import { propsAction } from 'store/props'
import { useReduxDispatch, useReduxSelector } from 'hooks'
import { useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { promoUploadPhoto } from 'api/promo'
import { image, dataURLtoFile } from 'utils'
import { ApiAnswerStatus, FormProps, UploadPhotoForm } from 'types'
import Cropper from 'react-easy-crop'
import { Area, Point } from 'react-easy-crop/types'

export const uploadPhotoModalName = 'UploadPhotoModal'

const useStyles = makeStyles(({ spacing }: Theme) => ({
  cropper: {
    position: 'relative',
    height: 220,
    display: 'flex',
    alignItems: 'center',

    '& .reactEasyCrop_Container': {
      '& .reactEasyCrop_Contain': {
        maxWidth: 'initial',
        maxHeight: 'initial',
        width: '100%',
        height: 'auto',
      },

      '& .reactEasyCrop_CropAreaGrid': {
        boxShadow: 'inset 0 0 3px rgba(0,0,0,0.2)',
        '&:before, &:after': {
          boxShadow: 'inset 0 0 3px rgba(0,0,0,0.2), 0 0 3px rgba(0,0,0,0.2)',
        },
      },
    },
  },

  cropViewZoom: {
    position: 'absolute',
    height: '100%',
    right: 0,
    paddingRight: spacing(1),
    paddingLeft: spacing(1),
    color: '#fff',
    fontSize: 25,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:before': {
      zIndex: 1,
      content: '""',
      right: 0,
      top: 0,
      position: 'absolute',
      height: '100%',
      width: '100%',
      background: 'linear-gradient(to left, rgba(0,0,0,0.5),rgba(0,0,0,0.5) 30%,transparent) no-repeat',
    },
    '& .MuiButtonBase-root': { padding: spacing(1) },
  },
}))

export const UploadPhotoModal: React.FC = () => {
  const classes = useStyles()
  const dispatch = useReduxDispatch()
  const { modal } = useReduxSelector((state) => state.props)
  const { show = false, params } = modal[uploadPhotoModalName] || {}
  const { fetchPromoUser } = params || {}

  const handleClose = () => dispatch(propsAction.modal({ modal: { [uploadPhotoModalName]: { show: false } } }))

  const acceptType = ['image/*']
  // const formats = acceptType.join(', ').replace(/\w+?\//g, '')

  const schema = validation({
    pet_name: yup
      .string()
      .required()
      .min(2)
      .max(40)
      .matches(/^[а-яёА-ЯЁa-zA-Z\s-]*$/i, 'Только буквенные символы'),
    // history: yup
    //     .string()
    //     .required()
    //     .min(300)
    //     .max(1000),
    image: yup
      .mixed()
      .test('required', 'Выберите фото питомца', (val) => {
        return typeof val?.[0] !== 'undefined'
      })
      .test(
        'fileSize',
        `Размер файла превышает ${Math.floor(15000000 / 1000000)} Мб`,
        (val) => val?.[0]?.size <= 15000000
      ),
    // .test('accept', `Выберите файл в формате ${formats}`, (val) => acceptType.includes(val?.[0]?.type)),
  })

  // const imageRef = useRef<HTMLImageElement>(null)

  const aspect = 16 / 9
  const [imgSrc, setImgSrc] = useState('')
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [pixelCrop, setPixelCrop] = useState<Area>()

  const [thumb, setThumb] = useState<Blob>()
  const [originalImg, setOriginalImg] = useState<Blob>()

  // const [debugMsg, setDebugMsg] = useState<string[]>([])
  const [loading, setLoading] = useState(false)

  const [form, setForm] = useState<FormProps<UploadPhotoForm>>({
    data: {},
    processed: false,
    snackbar: {
      onClose: () => setForm({ ...form, snackbar: { ...form.snackbar, message: undefined } }),
    },
  })

  const hookForm = useForm<any>({ resolver: yupResolver(schema) })
  const { reset } = hookForm

  const resetModal = () => {
    setImgSrc('')
    setCrop({ x: 0, y: 0 })
    setZoom(1)
    reset()
    setForm({
      data: {},
      processed: false,
      snackbar: {
        onClose: () => setForm({ ...form, snackbar: { ...form.snackbar, message: undefined } }),
      },
    })
  }

  const generateThumb = async () => {
    if (pixelCrop) {
      // console.log('_generateThumb imgSrc', imgSrc.slice(0, 35))
      const coppedImage = await image.getCroppedImg(imgSrc, pixelCrop)
      return coppedImage
    }
    return undefined
  }

  const onSubmit = async (props: any) => {
    setForm({ ...form, processed: true, data: props })
    const t = await generateThumb()
    // setDebugMsg((p) => [...p, `generatedThumb: ${t?.size}, ${t?.type}`])
    if (t) setThumb(t)
  }

  const uploadPhoto = async () => {
    const { status, message } = await promoUploadPhoto({ ...form.data, image: originalImg, thumb })
    // setDebugMsg((p) => [...p, `promoUploadPhoto: ${thumb?.size}, ${thumb?.type}`])
    setForm({ ...form, processed: false })
    resetModal()
    if (status === ApiAnswerStatus.SUCCESS) fetchPromoUser()
    dispatch(propsAction.alert({ title: 'Добавить фото', message, rootModal: uploadPhotoModalName }))
  }

  useEffect(() => {
    if (typeof thumb !== 'undefined') uploadPhoto()
  }, [thumb])

  const submitFailed = () => {
    setForm({ ...form, processed: false, snackbar: { ...form.snackbar, message: formLocale.notValid } })
  }

  const onSelectFile = ({ target }: any) => {
    if (target?.files && target?.files?.length > 0) {
      setLoading(true)
      // setDebugMsg((p) => [...p, `onSelectFile: ${target?.files[0].name}`])

      const reader = new FileReader()
      reader.addEventListener('load', () => {
        const res = reader?.result?.toString() ?? ''
        // setDebugMsg((p) => [...p, `setImgSrc: ${res.slice(0, 35)}`])
        setImgSrc(res)
        const f = dataURLtoFile(res, 'original_photo.jpg')
        setOriginalImg(f as Blob)
        setLoading(false)
      })
      reader.readAsDataURL(target.files[0])
    }
  }

  const onCropComplete = useCallback((_: Area, croppedAreaPixels: Area) => {
    // setDebugMsg((p) => [...p, `onCropComplete ${croppedAreaPixels.height}, ${croppedAreaPixels.width}`])
    setPixelCrop(croppedAreaPixels)
  }, [])

  useEffect(() => {
    if (!show) {
      resetModal()
    }
  }, [show])

  return (
    <Modal open={show} maxWidth="sm" title="Добавить фото" name={uploadPhotoModalName} onCloseDialog={handleClose}>
      <Box mx="auto" maxWidth={{ xs: 'initial', sm: 390 }} pt={4}>
        <form onSubmit={hookForm.handleSubmit(onSubmit, submitFailed)} autoComplete="off">
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <TextInput label="Кличка" placeholder="Пример: Лаки" name="pet_name" form={hookForm} />
            </Grid>

            {/*<Grid item xs={12}>*/}
            {/*  <TextInput label="Ваша история" multiline placeholder="Введите текст" name="history" form={hookForm} />*/}
            {/*</Grid>*/}

            <Grid item xs={12}>
              <FileInput
                name="image"
                label="Фото питомца"
                form={hookForm}
                acceptType={acceptType}
                onChange={onSelectFile}
                onClear={() => {
                  setImgSrc('')
                  setCrop({ x: 0, y: 0 })
                  setZoom(1)
                }}
              />
            </Grid>
          </Grid>

          <Collapse in={Boolean(imgSrc) && Boolean(crop)} timeout={500} appear>
            <Box mb={2}>
              <Fade in={Boolean(imgSrc) && Boolean(crop)} appear>
                <Box className={classes.cropper}>
                  <Cropper
                    image={imgSrc}
                    crop={crop}
                    zoom={zoom}
                    aspect={aspect}
                    onCropChange={setCrop}
                    zoomWithScroll={false}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                  />

                  <Box className={classes.cropViewZoom}>
                    <Box mb={1} mt={2} zIndex={2}>
                      <IconButton color="inherit" onClick={() => zoom < 3 && setZoom((z) => z + 0.1)}>
                        <ZoomIn fontSize="inherit" />
                      </IconButton>
                    </Box>

                    <Slider
                      min={1}
                      max={3}
                      step={0.1}
                      value={zoom}
                      style={{ zIndex: 2 }}
                      defaultValue={1}
                      color="secondary"
                      orientation="vertical"
                      aria-labelledby="Zoom"
                      onChange={(_, z) => setZoom(Number(z))}
                    />

                    <Box my={2} zIndex={2}>
                      <IconButton color="inherit" onClick={() => zoom > 1 && setZoom((z) => z - 0.1)}>
                        <ZoomOut fontSize="inherit" />
                      </IconButton>
                    </Box>
                  </Box>
                </Box>
              </Fade>
            </Box>
          </Collapse>

          {/* <ul style={{ marginTop: '20px' }}>
            {debugMsg.map((i, k) => (
              <li key={i + k} style={{ fontSize: 9, textAlign: 'left' }}>
                <i>{i}</i>
              </li>
            ))}
          </ul> */}

          <Snackbar {...form.snackbar} />

          <Box width="100%" maxWidth={260} mt={4} mx="auto">
            <SubmitButton
              fullWidth
              color="secondary"
              variant="contained"
              title="Добавить"
              disabled={form.processed || loading}
              processed={form.processed || loading}
            />
          </Box>
        </form>
      </Box>
    </Modal>
  )
}
