import { makeStyles, Theme } from '@material-ui/core'

const useStyles = makeStyles(({ spacing, palette, breakpoints: { down } }: Theme) => ({
  root: {
    transition: '0.15s all ease-in-out',
    '& a': {
      borderBottom: '1px dotted',
      borderColor: palette.text.primary,
      '&:hover': {
        color: palette.primary.main,
        borderBottomColor: palette.primary.main,
        borderBottomStyle: 'solid',
      },
    },
  },

  cameraView: {
    width: 300,
    height: 300,
    margin: '0 auto',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: palette.background.paper,
    boxSizing: 'border-box',
    border: `2px solid ${palette.common.black}`,

    '&:before': {
      content: '""',
      position: 'absolute',
      width: 180,
      height: 180,
      borderRadius: spacing(1),
      animation: 'pulse 0.8s ease-in-out infinite',
      border: '2px dashed rgba(255,255,255,0.7)',
    },
  },

  dropArea: {
    postion: 'relative',
    lineHeight: 1.4,
    fontSize: 17,
    fontWeight: 500,
    cursor: 'pointer',
    width: '100%',
    boxSizing: 'border-box',
    height: spacing(23),
    padding: spacing(5),
    borderRadius: spacing(1 / 4),
    borderBottom: 'none',
    textDecoration: 'underline',
    textDecorationStyle: 'dotted',
    textDecorationThickness: 'from-font',
    color: palette.text.primary,
    '&:focus': { outline: '0 none' },
    '&:hover': { borderStyle: 'none' },
    '&.preview': {
      overflow: 'hidden',
      boxShadow: ` inset 0 0 3em ${palette.secondary.light}`,
      animation: '50s ease-in-out infinite running slideBgY',
    },

    [down('xs')]: { fontSize: 15 },
  },

  preloader: {
    position: 'absolute',
    backgroundColor: 'rgba(255,255,255,0.7)',
    backdropFilter: 'blur(2px)',
    display: 'flex',
    alignItems: 'center',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
}))

export default useStyles
