import { FilledInputProps, OutlinedInputProps, TextFieldProps } from '@material-ui/core'
import { RegisterOptions, UseFormReturn } from 'react-hook-form'
import { numericInputProps } from './props-autocomplete'
import { GenericTextInput } from './_generic-input'

type Props = {
  form: UseFormReturn<any>
  name: string
  label?: string
  placeholder?: string
  disabled?: boolean
  options?: RegisterOptions
  inputProps?: any
  InputProps?: OutlinedInputProps | FilledInputProps
}

export const NumberInput: React.FC<Props & TextFieldProps> = ({
  form,
  name,
  placeholder = 'Пример: 1234',
  label = 'Код',
  disabled = false,
  options,
  InputProps,
  inputProps,
  variant = 'filled',
  ...props
}) => {
  const { register, formState } = form

  return (
    <GenericTextInput
      register={register(name, { required: true, disabled, ...options })}
      InputProps={{
        inputProps: { ...(numericInputProps as any), ...inputProps },
        ...InputProps,
      }}
      placeholder={placeholder}
      disabled={disabled}
      type="number"
      label={label}
      helperText={formState.errors[name]?.message}
      {...props}
    />
  )
}
