import { Fragment, useState, useEffect } from 'react'
import { Box, Button, useMediaQuery, Theme } from '@material-ui/core'
import { useDispatch } from 'react-redux'
import moment from 'moment'
import { ApiAnswerStatus } from 'types'
import { Modal } from 'components'
import { withPlanner, WithPlannerProps } from 'components/hoc'
import { useReduxSelector, useReduxDispatch } from 'hooks'
import { receiptRegister } from 'api'
import { propsAction } from 'store/props'
import { authAction } from 'store/auth'
import useStyles from './style'
import { Qr, QrCheckProps, ScanFunction } from './types'
import locale from './locale'
import { UploadReceiptDropzone, UploadReceiptCamera, UploadReceiptForm } from './screens'

export const uploadReceiptModalName = 'uploadReceiptModal'

type Screen = 'CAMERA' | 'FORM' | 'DROPZONE'

export const UploadReceiptModal: React.FC<WithPlannerProps> = withPlanner(({ planner }) => {
  const classes = useStyles()
  const DEBUG = process.env.REACT_APP_DEBUG === 'true'
  const { modal } = useReduxSelector((state) => state.props)
  const dispatch = useReduxDispatch()
  const authDispatch = useDispatch()
  const { show = false } = modal[uploadReceiptModalName] || {}

  const [processed, setProcessed] = useState(false)
  const [currentImage, setCurrentImage] = useState<string | undefined>()
  const [defaultValuesForm, setDefaultValuesForm] = useState<QrCheckProps>()
  const [screen, setScreen] = useState<Screen[]>(['DROPZONE'])

  let isRegistered = false

  const sm = useMediaQuery(({ breakpoints: { down } }: Theme) => down('xs'))

  const handleOpen = () => dispatch(propsAction.modal({ modal: { [uploadReceiptModalName]: { show: true } } }))
  const handleClose = () => dispatch(propsAction.modal({ modal: { [uploadReceiptModalName]: { show: false } } }))

  const validateExternalQr = ({ fn, fp, i, s, t }: Qr) => !!fn && !!fp && !!i && !!s && !!t

  const normalizeToForm = (qr: Qr, owner_check_media_id?: number): QrCheckProps => {
    const d = qr?.t?.split('T') ?? []
    let date = ''
    let time = ''
    if (d.length === 2) {
      date = moment(d[0], 'YYYYMMDD').format('YYYY-MM-DD')
      time = `${d[1].substring(0, 2)}:${d[1].substring(2, 4)}`
    }
    return {
      check_fn: qr?.fn,
      check_fd: qr?.i,
      check_fpd: qr?.fp,
      check_summa: qr?.s,
      check_date: date,
      check_time: time,
      local_parse: typeof owner_check_media_id === 'undefined',
      owner_check_media_id,
    }
  }

  const reset = () => {
    setScreen(['DROPZONE'])
    setDefaultValuesForm(undefined)
    setCurrentImage(undefined)
    isRegistered = false
  }

  const handleRegister = async (params: QrCheckProps) => {
    setProcessed(true)
    const { status, message } = await receiptRegister(params)
    setProcessed(false)
    console.log('handleRegister', status, message)

    switch (status) {
      case ApiAnswerStatus.SUCCESS:
        dispatch(
          propsAction.alert({
            message,
            rootModal: uploadReceiptModalName,
            onClose: () => {
              authDispatch(authAction.auth())
              reset()
            },
          })
        )
        isRegistered = true
        break

      default:
        dispatch(
          propsAction.alert({
            message,
            onClose: () => {
              setScreen(['DROPZONE'])
              setDefaultValuesForm(undefined)
              setCurrentImage(undefined)
              handleClose()
            },
          })
        )
    }
  }

  const handleScan: ScanFunction = async (qrData, image, owner_check_media_id, cb) => {
    console.log(qrData, image, owner_check_media_id)

    let qr_data: any = {}
    if (qrData) {
      qrData.split('&').map((i) => {
        const [key, val] = i.split('=')
        qr_data[key] = val
        return i
      })
    }

    const normalized = normalizeToForm(qr_data, owner_check_media_id)
    if (DEBUG) console.log('handleScan:', normalized)
    setDefaultValuesForm(normalized)

    if (validateExternalQr(qr_data)) {
      if (!isRegistered) {
        isRegistered = true
        if (DEBUG) console.log('handleRegister', isRegistered)
        await handleRegister(normalized)
      }
    } else {
      setProcessed(false)
      qr_data = undefined
      if (image) setCurrentImage(image)
      setScreen(image ? ['DROPZONE', 'FORM'] : ['FORM'])
    }

    if (cb) cb(image)
  }

  useEffect(() => {
    let mounted = false
    if (mounted) {
      if (show) setScreen(['CAMERA'])
      else {
        planner?.timeout(() => {
          setDefaultValuesForm(undefined)
          setCurrentImage(undefined)
          isRegistered = false
        }, 150)
      }
    }
    return () => {
      mounted = false
    }
  }, [show])

  return (
    <Fragment>
      <Button variant="contained" color="primary" fullWidth={sm} onClick={handleOpen}>
        {locale.btnMain}
      </Button>

      <Modal title={locale.modalTitle} open={show} onCloseDialog={handleClose}>
        <Box className={classes.root}>
          {screen.includes('CAMERA') && (
            <UploadReceiptCamera
              show={screen.includes('CAMERA')}
              handleScan={handleScan}
              onExit={() => setScreen(['DROPZONE'])}
            />
          )}
          {screen.includes('DROPZONE') && (
            <UploadReceiptDropzone
              image={currentImage}
              handleScan={handleScan}
              onExit={(id) => {
                if (id) {
                  setScreen(['DROPZONE', 'FORM'])
                  setDefaultValuesForm({ owner_check_media_id: id, local_parse: false })
                } else {
                  setDefaultValuesForm(undefined)
                  setCurrentImage(undefined)
                  setScreen(['DROPZONE'])
                  handleClose()
                  reset()
                }
              }}
              processed={processed}
            />
          )}
          <UploadReceiptForm
            show={screen.includes('FORM')}
            processed={processed}
            onSubmit={handleRegister}
            defaultValues={defaultValuesForm}
            onExit={() => {
              setCurrentImage(undefined)
              setScreen(['DROPZONE'])
            }}
          />
        </Box>
      </Modal>
    </Fragment>
  )
})
