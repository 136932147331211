import { useEffect, useState } from "react";
import { Box, Grid, Hidden, Link } from "@material-ui/core";
import {
  animateScroll as scroll,
  Link as ScrollLink,
  scroller,
} from "react-scroll";
import useReactRouter from "use-react-router";
import { useReduxSelector } from "hooks";
import { str } from "utils";
import { withPlanner, WithPlannerProps } from "components/hoc";
import classNames from "classnames";
import { PopperAuth } from "../popper-auth";
import { MyLink } from "./nav-link";
import { useStyles } from "../header.style";
import { getImage } from "../../../promo/assets";

type MenuItem = {
  title: string;
  visible: boolean;
  key: string;
  to: string;
  target: "_self" | "_blank";
};

export const Menu: React.FC<WithPlannerProps> = withPlanner(({ planner }) => {
  const classes = useStyles();
  const { history, location } = useReactRouter();
  const { pathname } = location;

  const [menu, setMenu] = useState<MenuItem[]>([]);

  const {
    data: user,
    isAuth,
    loading,
  } = useReduxSelector((state) => state.auth);
  const { promoAviable } = useReduxSelector((state) => state.props);

  const handleClick = (item?: MenuItem) => {
    if (item && item.key.length > 0) {
      if (pathname !== "/" && pathname !== "/action") history.push("/");
      if (pathname === "/" || item.key === "main" || item.key === "action") {
        planner?.timeout(() => scroll.scrollToTop(), 130);
      }

      planner?.timeout(() => {
        scroller.scrollTo(item.key, {
          ignoreCancelEvents: true,
          activeClass: "active",
          duration: 700,
          hashSpy: true,
          smooth: true,
          offset: 0,
          spy: true,
        });
      }, 150);
    }
  };

  useEffect(() => {
    const m: MenuItem[] = [
      {
        title: "Регистрация",
        visible: !isAuth && !loading,
        key: "signup",
        to: "?signup",
        target: "_self",
      },
      {
        title: "Вход",
        visible: !isAuth && !loading,
        key: "signin",
        to: "?signin",
        target: "_self",
      },
    ];

    if (pathname === "/action" && promoAviable) {
      m.unshift({
        title: "Правила акции",
        visible: true,
        key: "rules",
        to: "",
        target: "_self",
      });
    } else {
      m.unshift({
        title: "Правила AlphaPet® Клуб владельцев",
        visible: true,
        key: "rules",
        to: "",
        target: "_self",
      });
      if (promoAviable) {
        m.unshift({
          title: "Акция",
          visible: true,
          key: "action",
          to: "/action",
          target: "_self",
        });
      }
    }

    setMenu(m);
  }, [pathname, loading, isAuth, promoAviable]);

  return (
    <Box className={`${classes.menu}`}>
      <Grid container justifyContent="space-between" alignItems="center">
        <Grid item xs={12} md="auto">
          <Hidden smDown>
            {/* eslint-disable-next-line */}
            <Link
              onClick={() =>
                handleClick({
                  title: "Главная",
                  visible: true,
                  key: "main",
                  to: "",
                  target: "_self",
                })
              }
              draggable={false}
              component={MyLink}
              to="/"
              className={classes.menuLogo}
            >
              {pathname !== "/action" ? (
                <img
                  draggable={false}
                  alt="AlphaPet"
                  src="/imgs/logo/logo-top.svg"
                  height="100%"
                  width="auto"
                />
              ) : (
                <img
                  draggable={false}
                  alt="AlphaPet"
                  src="/imgs/logo/logo-top.svg"
                  height="100%"
                  width="auto"
                />
              )}
            </Link>
          </Hidden>
        </Grid>

        <Grid item xs={12} md="auto">
          <Grid container justifyContent="flex-end" alignItems="center">
            {menu.map((i, k) => {
              const { key, to, visible, title, target } = i;

              return visible ? (
                <Grid
                  key={k + key}
                  item
                  xs={12}
                  md="auto"
                  className={classNames(
                    classes.menuItem,
                    pathname !== "/action" ? "" : classes.menuItemW
                  )}
                >
                  <Link
                    className={key}
                    component={to === "" ? ScrollLink : MyLink}
                    onClick={() => handleClick(to === "" ? i : undefined)}
                    {...{ to, title, target }}
                    style={{ color: key === "contest" ? "#529CAF" : "" }}
                  >
                    {str.normalize(title)}
                  </Link>
                </Grid>
              ) : null;
            })}
            <Hidden smDown>
              <Grid item xs={12} md="auto" className={classes.menuItem}>
                {isAuth && <PopperAuth user={user} />}
              </Grid>
            </Hidden>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
});
