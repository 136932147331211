import { Modal } from 'components'
import { useReduxSelector, useReduxDispatch } from 'hooks'
import { propsAction } from 'store/props'
import GiftPetForm from './gift-pet-form'

export const giftPetModalName = 'GiftPetModal'

export const GiftPetModal: React.FC = () => {
  const dispatch = useReduxDispatch()
  const { modal } = useReduxSelector((state) => state.props)
  const { show = false } = modal[giftPetModalName] || {}

  const handleClose = () => dispatch(propsAction.modal({ modal: { [giftPetModalName]: { show: false } } }))

  return (
    <Modal open={show} maxWidth="sm" title="Подарок для питомца" name={giftPetModalName} onCloseDialog={handleClose}>
      <GiftPetForm rootModal={giftPetModalName} />
    </Modal>
  )
}
