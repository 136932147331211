import { FilledInputProps, MenuItem, OutlinedInputProps } from '@material-ui/core'
import { ExpandMore } from '@material-ui/icons'
import { UseFormReturn } from 'react-hook-form'
import { str } from 'utils'
import { GenericTextInput } from './_generic-input'

type Props = {
  form: UseFormReturn<any>
  name: string
  label?: string
  disabled?: boolean
  placeholder?: string
  required?: boolean
  items?: { id: number; key: string; title: string }[]
  InputProps?: OutlinedInputProps | FilledInputProps
  noHelperText?: boolean
  onChange?(val: any): void
}

export const SelectInput: React.FC<Props> = ({
  form,
  name,
  items,
  label = '',
  disabled = false,
  required = true,
  placeholder = 'Не выбрано',
  InputProps,
  noHelperText = false,
  onChange: change,
}) => {
  const { register, getValues, formState, clearErrors } = form
  const defaultValue = getValues(name)

  const onChange = (e: any) => {
    const val = e?.target?.value
    if (val) clearErrors(name)
    if (change && val) change(val)
  }

  return (
    <GenericTextInput
      key={`select_${defaultValue?.id ?? '_'}_${name}`}
      register={register(name, {
        required,
        setValueAs: (val) => {
          // console.log('name selectInputVal:', val)
          return val === '' ? undefined : val
        },
      })}
      SelectProps={{
        displayEmpty: true,
        onChange,
        defaultValue,
        IconComponent: ExpandMore,
        style: noHelperText ? { marginBottom: 0 } : undefined,
      }}
      disabled={disabled || !items || items?.length === 0}
      label={label}
      select
      InputProps={{ ...InputProps }}
      helperText={formState.errors[name]?.message}
    >
      <MenuItem style={{ display: required ? 'none' : 'flex' }}>{placeholder}</MenuItem>

      {items &&
        items.map(({ id, key, title }) => (
          <MenuItem key={`${name}-${title}-${key}`} value={id}>
            {str.normalize(str.format.firstToUpper(title))}
          </MenuItem>
        ))}
    </GenericTextInput>
  )
}
