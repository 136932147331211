import { Dispatch } from 'redux'
import { auth as authService } from 'api'
import { ApiAnswerStatus } from 'types'
import { FETCH_AUTH_REQUEST, FETCH_AUTH_SUCCESS, FETCH_AUTH_FAILURE } from './types'

const request = (payload?: any) => ({ type: FETCH_AUTH_REQUEST, payload })
const success = (payload: any) => ({ type: FETCH_AUTH_SUCCESS, payload })
const failure = (payload: any) => ({ type: FETCH_AUTH_FAILURE, payload })

export const authAction = {
  auth: (successAuth?: () => void, errorAuth?: () => void) => async (dispatch: Dispatch) => {
    const { SUCCESS, NEED_FULL_REGISTER } = ApiAnswerStatus
    dispatch(request())
    const data = await authService()

    switch (data.status) {
      case SUCCESS:
      case NEED_FULL_REGISTER:
        dispatch(success(data))
        if (successAuth) successAuth()
        break
      default:
        dispatch(failure(data))
        if (errorAuth) errorAuth()
        break
    }
    return data
  },
}
