import { AlertProps } from 'types'
import { SET_PROPS, PROPS, ModalAction, ALERT, SET_ALERT, SET_MODAL, MODAL } from './types'

export const propsAction = {
  alert: (payload: AlertProps): ALERT => ({ type: SET_ALERT, payload: { alert: payload } }),

  modal: (payload: ModalAction): MODAL => ({ type: SET_MODAL, payload }),

  setProps: (payload: any): PROPS => ({ type: SET_PROPS, payload }),
}
