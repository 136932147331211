import { makeStyles, Theme } from "@material-ui/core";
import { getImage } from "./assets";

export const useStyles = makeStyles(
  ({ palette, breakpoints: { down, up }, spacing }: Theme) => ({
    root: {
      "& img": {
        display: "block",
        maxWidth: "100%",
        pointerEvents: "none",
      },
    },
    top: {
      position: "relative",
      "&::after": {
        content: "''",
        background: `url(${
          getImage?.["bg-top-wave.png"] ?? ""
        }) bottom center repeat-x`,
        width: "100%",
        height: "100%",
        position: "absolute",
        display: "block",
        bottom: -4,
        backgroundSize:"100%",
        [down("xs")]: {
          background: 'none'
        },
      },
      [down("xs")]: {
        background: `url(${
            getImage?.["top-xs.png"] ?? ""
        }) bottom center no-repeat`,
        backgroundSize: "cover",
      },
    },

    body: {
      color: "#323E48",
    },

    likeButton: {
      transition: "color 0.15s ease-in-out",
      "&:hover": {
        color: palette.error.main,
      },
    },

    bullet: {
      textAlign: "left",
      paddingLeft: "3em",
      listStyle: "none",

      "& li": {
        position: "relative",
        paddingBottom: 12,
        fontWeight: 400,
        "&::before": {
          content: "'\\25AA'",
          color: "#AD7D66",
          fontSize: "3em",
          lineHeight: 0.4,
          top: 0,
          fontWeight: 600,
          position: "absolute",
          display: "inline-block",
          wight: "1em",
          marginLeft: -25,
        },
      },
    },

    sortBtn: {
      outline: "none",
      display: "inline-block",
      cursor: "pointer",
      userSelect: "none",
      "&.disabled": {
        cursor: "initial",
        color: palette.text.disabled,
        "&:hover, &:active": { color: palette.text.disabled },
      },
      "&:hover": {
        color: palette.primary.main,
      },
      "&:active": {
        color: palette.primary.light,
      },
      "& svg, & span": {
        display: "inline-block",
        verticalAlign: "middle",
      },
    },

    galleryImage: {
      position: "relative",
      cursor: "pointer",

      "& .video_overlay": {
        color: "#fff",
        position: "absolute",
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        fontSize: 60,
        backgroundColor: "rgba(0,0,0,0.3)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      },

      "& img": {
        transform: "scale(1.007)",
      },

      "&:hover": {
        "& img": {
          transform: "scale(1.025)",
        },
      },
    },

    dog: {
      bottom: 0,
      pointerEvents: "none",
      zIndex: 0,
      width: "100%",
      position: "absolute",
    },

    d: {
      width: "19vw",
      maxWidth: "350px",
      bottom: 0,
      position: "absolute",
      marginLeft: "50%",
      "& img": {
        bottom: 0,
        width: "100%",
      },
      [down("xs")]: {
        width: "75vw",
      },
    },
    bg: {
      position: "absolute",
      zIndex: 0,
      left: "50%",
      top: "50%",
      imageRendering: "pixelated",
      pointerEvents: "none",
      transform: "translate(-50%,-50%) translateZ(0)",
    },

    btn: {
      backgroundColor: "#D75C55",
      padding: "2em 2em",

      "&:after": {
        content: "none",
      },
      "&:hover": {
        backgroundColor: "#E9AAA5!important",
      },
    },
    winnersBox: {
      paddingTop: 40,
      paddingBottom: 40,
      margin: "0 auto",
      borderRadius: "0px 36px 36px 36px",
      width: "100%",
      maxWidth: "1268px",
      backgroundColor: "#FFF7EC",
    },

    winnersBoxSearch:{
      paddingLeft: "78px",
      paddingBottom: "20px",

      [down("sm")]: {
        paddingLeft: "48px",
      },
      [down("xs")]: {
        paddingLeft: "20px",
      },
    },
    winnersBoxTitle: {
      color: "#323E48",
      margin: "0 auto",
      width: "100%",
      maxWidth: "1268px",

      "& .winnerTitle": {
        display: "inline-block",
        paddingTop: "48px",
        paddingLeft: "78px",
        paddingRight: "78px",
        color: "#323E48",
        borderRadius: "36px 0 0% 0%",
        backgroundColor: "#FFF7EC",
        position: "relative",
        [down("sm")]: {
          paddingTop: "28px",
          paddingLeft: "48px",
          paddingRight: "48px",
        },
        [down("xs")]: {
          paddingTop: "25px",
          paddingLeft: "20px",
          paddingRight: "0px",
        },
        "&::after": {
          content: "''",
          position: "absolute",
          display: "block",
          width: "100px",
          height: "100%",
          right: -100,
          bottom: 0,
          background: `url(${getImage?.["win.png"] ?? ""}) bottom center repeat-x`,
          backgroundSize: "100% 100%",

        },
      },
    },
    gameIframe:{
      width:"100%",
      maxWidth: "1024px",
      height: "580px",
      [down("sm")]: {
        height:"450px"
      },
      [down("xs")]: {
        height:"650px"
      },
    }
  })
);
